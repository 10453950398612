import types from "../http_common";
import { apiRequest } from "../authConfig";

  // const contactsGetAll = uprn => {
  //   return types.json().get(`/Karma/Contacts/GetAll`);
  // };
  async function bearerToken(instance) {
    const account = instance.getActiveAccount()
    if (!account) {
      throw Error(
        'No active account! Verify a user has been signed in and setActiveAccount has been called.'
      )
    }
    const response = await instance.acquireTokenSilent({
      ...apiRequest,
      account: account,
    })
   // console.log(response);
    return response.accessToken
  }

  const getSettings = async (instance) =>{
    var api = types.json();
    const item = await bearerToken(instance);
    api.defaults.headers.common.Authorization = 'Bearer ' + item;
    return await api.get(`/Karma/getSettings`);
  }

  const contactsGetAll = async (page,instance) =>{
    var api = types.json();
    const item = await bearerToken(instance);
    api.defaults.headers.common.Authorization = 'Bearer ' + item;

    return await api.get(`/Karma/Contacts/GetAll`);
  }

  const contactGet = async (id,instance) =>{
    var api = types.json();
    const item = await bearerToken(instance);
    api.defaults.headers.common.Authorization = 'Bearer ' + item;

    return await api.get(`/Karma/Contact/Get?id=`+id);
  }

  const contactUpdate = async (id,data,instance) =>{
    var api = types.json();
    const item = await bearerToken(instance);
    api.defaults.headers.common.Authorization = 'Bearer ' + item;

    return await api.post(`/Karma/Contact/Update?id=`+id,data);
  }

  const contactsSearch = async (query,instance) =>{
    var api = types.json();
    const item = await bearerToken(instance);
    api.defaults.headers.common.Authorization = 'Bearer ' + item;

    return await api.get(`/Karma/Contacts/search?query=`+query);
  }

  const siteWideSearch = async (query,instance) =>{
    var api = types.json();
    const item = await bearerToken(instance);
    api.defaults.headers.common.Authorization = 'Bearer ' + item;

    return await api.get(`/Karma/siteWide/search?query=`+query);
  }

  const contactsFiltered = async (queries,page,limit,instance) =>{
    var api = types.json();
    const item = await bearerToken(instance);
    api.defaults.headers.common.Authorization = 'Bearer ' + item;

    return await api.post(`/Karma/Contacts/GetAll?page=`+page+`&limit=`+limit,queries);   ///Karma/Contacts/filter?info=`+query);
  }

  const contactsGetNotes = async (id,page,instance) =>{
    var api = types.json();
    const item = await bearerToken(instance);
    api.defaults.headers.common.Authorization = 'Bearer ' + item;

    return await api.get(`/Karma/Contact/GetNotes?id=`+id+'&page='+page);
  }

  const attachementGet = async (id,instance) =>{
    var api = types.json();
    const item = await bearerToken(instance);
    api.defaults.headers.common.Authorization = 'Bearer ' + item;
    api.defaults.responseType = 'blob'
    return await api.get(`/Karma/Contact/GetAttachement?id=`+id);
  }

  const attachementUpload = async (formdata,instance) =>{
    var api = types.json();
    const item = await bearerToken(instance);
    api.defaults.headers.common.Authorization = 'Bearer ' + item;
    api.defaults.headers["Content-Type"] = "multipart/form-data"
    return await api.post(`/Karma/Contact/UploadAttachement`,formdata);  //https://app.karmacrm.com/api/v2/attachments
  }


  const attachementUploadSync = async (formdata,instance,setUploadStarted,setProgress) =>{
    var api = types.json();
    const item = await bearerToken(instance);
    api.defaults.headers.common.Authorization = 'Bearer ' + item;
    api.defaults.headers["Content-Type"] = "multipart/form-data"
    return api.post('/Karma/Contact/UploadAttachement',
      formdata,{
      onUploadProgress: (progressEvent) => {
        setUploadStarted(true);
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgress(percentCompleted);
      },
    });
    
  }
  

  const contactNotesUpdate = async (id,obj,instance,contractor =false) =>{
    var api = types.json();
    const item = await bearerToken(instance);
    api.defaults.headers.common.Authorization = 'Bearer ' + item;
    return await api.post(`/Karma/Contact/UpdateNotes?contractor=`+contractor+`&id=`+id,obj);
  }

  const sendSMS = async (number,message,instance) =>{
    var api = types.json();
    const item = await bearerToken(instance);
    api.defaults.headers.common.Authorization = 'Bearer ' + item;
    return await api.get(`/comms/sendSMS?number=`+number +` &message=`+message);
  }

  //http://localhost:8080/api/karma/contacts/getNotes?id=11737511&page=1

  //'https://happyengine.azurewebsites.net/api/Karma/Contacts/search?query=${query}';

  const KarmaApiService = {
    contactsGetAll,
    siteWideSearch,
    contactsSearch,
    contactGet,
    contactUpdate,
    contactsGetAll,
    contactNotesUpdate,
    attachementGet,
    attachementUpload,
    getSettings,
    contactsFiltered,
    sendSMS,
    attachementUploadSync,
    contactsGetNotes
  };
  
  export default KarmaApiService;