import axios from "axios";
var baseURL = ""

//console.log("mode is " + process.env.NODE_ENV);

//https://happyengine.azurewebsites.net/api/
 // console.log('I am in debug'); //http://localhost:8080/api/epcCalc
  baseURL = "https://happyengine.azurewebsites.net/api/"

 // baseURL = "http://localhost:8081/api/"

const json = () => {
  return axios.create({
    baseURL:   baseURL,
    headers: {
      "Content-type": "application/json"
    }
  });
}

const xml = () => {
  return axios.create({
    baseURL:   baseURL,
    crossorigin:true,
    headers: {'Content-Type': 'text/xml'},
  // headers: {"X-API-Key":"8Np5ogHRQV49lih7XEGWd4BFUWx9CCqi9TuTXWmB"},
   //,'X-Requested-With': 'XMLHttpRequest'}
  }); 
}

const types ={
  json,
  xml
}

export default types;

// export default axios.create({
//   baseURL:   baseURL,
//   headers: {
//     "Content-type": "application/json"
//   }
// });



/// "https://happyengine.azurewebsites.net/api/",
// "http://localhost:8080/api/", //