import React, { useMemo, useRef, useState,useEffect } from 'react';
import { MDBBadge, MDBBtn, MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import KarmaApiService from '../services/karmaApi'
import { useMsal } from '@azure/msal-react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { FilePicker } from './fileUpload/file-picker'
//import  from "react-datepicker";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import { enGB } from 'date-fns/locale/en-GB';
import "react-datepicker/dist/react-datepicker.css";
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

registerLocale('en-GB', enGB)
export default function App() {
  const ref = useRef();
  const validation = useRef({});
  const [uploadComplete, setUploadComplete] = useState(false);
  const [files, setFiles] = useState([]);
  const [noteAdd,setNoteAdd] = useState('');
  const [toast, setToast] = useState({ 'show': false });
  const [modalShow, setModalShow] = useState(false);
  const [modalText, setModalText] = useState();
  const [nextStage, setNextStage] = useState('');
  const [ddBit,setDdBit] = useState([<></>])
  const { instance } = useMsal();
  const [preList, setPreList] = useState([]);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [startDate2, setStartDate2] = useState(new Date());
  const [contactNotes,setContactNotes] = useState('');
  const settings = useRef([]);
  const running = useRef(false);
  const selectedContact = useRef();
  const completed = useRef(false);
  var queries = '&filters%5Bcustom_field_243978%5D%5B%5D=243983&filters%5Bcustom_field_248749%5D=248750&filters%5Bcustom_field_242759%5D=248234'
  const installBooking = {};
  installBooking.no1 = 249739; installBooking.no2 = 249740; installBooking.no3 = 249741;
  const bookedDate = 261019, stageDate = 239836, assignedDate = 240283, measueTypeId = 243978, notesId = 239840, statusId = 239825, insulationm2 = 249434, 
 cancelationReason1 =  249831, cancelationReason2 = 249841  , installStartDate = 248647 , installEndDate = 239839, contractorId = 239837;  
  //&filters%5Bcustom_field_239837%5D=249582  //installer Absolute
  const activeAccount = instance.getActiveAccount();
  const happyStaff =  (activeAccount?.username.toLowerCase().includes('happyenergy.co.uk'));

  useEffect(() => {
    let active = true
    load()
    return () => { active = false }
    async function load() {
      const res = await getData()
      if (!active) { return }
      setPreList(res)
      setList(res)
      setLoading(false);
    }
  }, [running.current])

  async function getData(){
    if (!settings.current.data){
      settings.current = await KarmaApiService.getSettings(instance).catch(function (error) { catchError(error) });
      console.log(settings.current.data.settings.fields.results.filter(obj => {
        return obj.id == statusId
      }))
    }
    var groups = instance.getActiveAccount().idTokenClaims.groups;
    for(let x =0; x< groups.length;x++){
    switch (instance.getActiveAccount().idTokenClaims.groups[x]) {
      case 'eb3cf0ec-a851-4c79-83f0-2a015b4b67d8':
        queries += '&filters%5Bcustom_field_239837%5D=249582'
        break;
      default:
        break;
    }
    }
    var result = await KarmaApiService.contactsFiltered({ queries: queries }, 1, 50000, instance).catch(function (error) { catchError(error) });
    console.log(result);

    for (let i = 0; i < result.data.list.length; i++) {
      var appointmentNo =0;
      var final = convertToSence(statusId, result.data.list[i])
      if (result.data.list[i].field_values.findIndex(el => (el.field_id === installStartDate)) > -1 ) {appointmentNo =1;}
      if (result.data.list[i].field_values.findIndex(el => (el.field_id === installBooking.no1)) > -1 ) {appointmentNo =2;}
      if (result.data.list[i].field_values.findIndex(el => (el.field_id === installBooking.no2)) > -1 ) {appointmentNo=3;}
      if (result.data.list[i].field_values.findIndex(el => (el.field_id === installBooking.no3)) > -1 ) {appointmentNo =4;}
      result.data.list[i].installAppointmentNo = appointmentNo;
      result.data.list[i].statusName = final.name
    }
    console.log(result.data.list);
    return result.data.list;
  }

  async function getNotes(){
    var results = await KarmaApiService.contactsGetNotes(selectedContact.current.id,1,instance).catch(function (error) { catchError(error) });
    console.log(results);
    var buildHtml = '';
    for (let x= 0; x < results.data?.length; x++){
      if (results.data[x].created_by_id !=39238){  //not other than contractor
        continue
      }
      console.log(results.data[x])
      buildHtml += 'Date: ' + new Date(results.data[x].created_at).toLocaleDateString('en-GB') +'\n'+ results.data[x].body + '\n\n';
    }
    setContactNotes(buildHtml);
  }

  async function updateNotes(){
    var noteIn = []
    noteIn.push(
      {
        "body": noteAdd, "contact_type_id": 3,
        "date": new Date().toLocaleDateString('fr-CA'), "participants": [{ "label": "", "participater_type": "User", "participater_id": [] }], "user_ids": [], "parent_type": "Contact", "parent_id": selectedContact.current.id
      }
    )
    var results = await KarmaApiService.contactNotesUpdate(selectedContact.current.id,noteIn,instance,true)
    setContactNotes((prev)=>{
      return 'Date: ' + new Date().toLocaleDateString('en-GB') +'\n'+ noteAdd + '\n\n' + prev
    })
    setNoteAdd('')
  }

  async function updateContact() {
    var contactOut = {};
    var clone = structuredClone(selectedContact.current);
    contactOut.contact = {};
    var id = clone['id'];
    var id = id.toString();
    contactOut.contact.field_values = [];
    contactOut.contact.field_values = clone.field_values;
    for (let x = 0; x < contactOut.contact['field_values'].length; x++) {
      if (![2, 3].includes(contactOut.contact['field_values'][x]['field_type_id'])) {
        delete contactOut.contact['field_values'][x]['field_parent_id']
      }
      delete contactOut.contact['field_values'][x]['field_type_id']
      delete contactOut.contact['field_values'][x]['_field_id']
      contactOut.contact['field_values'][x]['value'] = contactOut.contact['field_values'][x]['value'].toString();
    }
    await KarmaApiService.contactUpdate(id, contactOut, instance) 
    var newContact = await KarmaApiService.contactGet(id, instance);
    console.log(newContact.data);
    var oldInstallAppointmentNo = structuredClone(selectedContact.current.installAppointmentNo) ;
    selectedContact.current.field_values = structuredClone(newContact.data.field_values);
    var final = convertToSence(statusId, newContact.data)
    selectedContact.current.statusName = final.name
    setList((old) => {
      var prev = [...old];
      prev[prev.findIndex(el => el.id === id)].field_values = structuredClone(newContact.data.field_values);
      prev[prev.findIndex(el => el.id === id)].statusName = final.name;
      prev[prev.findIndex(el => el.id === id)].installAppointmentNo = oldInstallAppointmentNo;
      return prev;
    });
    setPreList((old) => {
      var prev = [...old];
      prev[prev.findIndex(el => el.id === id)].field_values = structuredClone(newContact.data.field_values);
      prev[prev.findIndex(el => el.id === id)].statusName = final.name;
      prev[prev.findIndex(el => el.id === id)].installAppointmentNo = oldInstallAppointmentNo;
      return prev;
    });
  }

  const fOptions = [];
  const contractorOpts = [];
  const measureOpts = [];  //measueTypeId
  const statusOpts = [];
  if(happyStaff)contractorOpts.push(<option key={'c0'} value={-1}>{"All"}</option>);
  statusOpts.push(<option key={'s0'} value={-1}>{"All"}</option>)
  measureOpts.push(<option key={'m0'} value={-1}>{"All"}</option>)
  for(let x=0;x<preList?.length;x++){
    var anser = convertToSence(contractorId,preList[x]);
    if (!fOptions.includes(anser.name)){
      fOptions.push(anser.name);
      if(anser.parent_id == contractorId) contractorOpts.push(<option key={'c'+anser.id} value={anser.id}>{anser.name}</option>)
    }
    anser = convertToSence(statusId,preList[x]);
    if (!fOptions.includes(anser.name)){
      fOptions.push(anser.name);
      if(anser.parent_id == statusId) statusOpts.push(<option key={'s'+anser.id} value={anser.id}>{anser.name}</option>)
    }
    anser = convertToSence(measueTypeId,preList[x]);
    if (!fOptions.includes(anser.name)){
      fOptions.push(anser.name);
      if(anser.parent_id == measueTypeId) measureOpts.push(<option key={'m'+anser.id} value={anser.id}>{anser.name}</option>)
    }
  }

  function catchError(error) {
    if (error.response) {
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      console.log(error.request);
    } else {
      console.log('Error', error.message);
    }
    setLoading(false);
  }

  const makeDropdown = (cancelReasonId) =>{
    var setting = settings.current.data.settings.fields.results.find(obj => { return obj.id == cancelReasonId })
    var options = [<option value={"0"}>{"-"}</option>]
    for (let x =0; x < setting.options.length; x++){
      options.push(<option value={setting.options[x].id}>{setting.options[x].name}</option>)
    }
    return options;
  }

  const hideModal = () => {
    setDdBit([<></>])
    setModalShow(false);
  };

  function handleUpdate(i) {
    selectedContact.current = structuredClone(i);
    setNextStage(i.statusName); // as is
    setStartDate(null);
    setModalShow(true);
  }

  async function handleNotes(i) {
    selectedContact.current = structuredClone(i);
    setNextStage('Notes'); // as is
    await getNotes()
    setStartDate(null);
    setModalShow(true);
  }

  async function handleContactDetails(i){
    selectedContact.current = structuredClone(i);
    setNextStage('ContactDetails'); // as is
    setStartDate(null);
    setModalShow(true);
  }

  async function handleModalUpdate() {
    console.log(nextStage)
    switch (nextStage) {
      case 'Insulation PITI ready to book':
      case 'Insulation PITI booked':
        if (!selectedContact.current.field_values[selectedContact.current.field_values.findIndex(el => (el.field_id === bookedDate || el._field_id === bookedDate))].value) {
          setToast((old) => {
            var prev = { ...old };
            prev.show = true;
            prev.color = 'danger';
            prev.message = 'You must set a date';
            return prev;
          });
        } else {
          updateContact();
          hideModal();
        }
        break;
      case 'Purchase Order Required ':
        completed.current = false;
        console.log(validation.current?.insulationDepth + ' - ' + validation.current?.insulationm2)
        if (!(validation.current?.insulationDepth) || !(validation.current?.insulationm2)) {
          setToast((old) => {
            var prev = { ...old };
            prev.show = true;
            prev.color = 'danger';
            prev.message = 'You must complete all fields';
            return prev;
          });
          break;
        }
        console.log(await ref.current?.handleUpload(selectedContact.current, instance)); //,setProgress,progress));
        if (!completed.current) {
          setToast((old) => {
            var prev = { ...old };
            prev.show = true;
            prev.color = 'danger';
            prev.message = 'Error uploading files to complete PITI';
            return prev;
          });
        } else {
          updateOrCreate(statusId, 249782) //purchace order req
          updateOrCreate(stageDate, new Date().toLocaleDateString('fr-CA'));
          updateOrCreate(insulationm2, validation.current.insulationm2)
          // todo new field for insulation depth
          updateContact()
          hideModal();
        }
        break;
      case 'Ready for Install':
      case 'Installation Booked':
        if (!selectedContact.current.field_values[selectedContact.current.field_values.findIndex(el => (el.field_id === installStartDate))].value) {
          setToast((old) => {
            var prev = { ...old };
            prev.show = true;
            prev.color = 'danger';
            prev.message = 'You must set an appointment date';
            return prev;
          });
          break;
        } 
        if((selectedContact.current.installAppointmentNo == 1 && !selectedContact.current.newReason) ||
        (selectedContact.current.installAppointmentNo == 2 && !selectedContact.current.newReason) ){
          setToast((old) => {
            var prev = { ...old };
            prev.show = true;
            prev.color = 'danger';
            prev.message = 'Please select a reason';
            return prev;
          });
          break;
        }
          selectedContact.current.installAppointmentNo = selectedContact.current.installAppointmentNo+1;
          updateContact();
          hideModal();
        break;
      case 'Installation Completed':
        if (!selectedContact.current.field_values[selectedContact.current.field_values.findIndex(el => (el.field_id === installEndDate))].value) {
          setToast((old) => {
            var prev = { ...old };
            prev.show = true;
            prev.color = 'danger';
            prev.message = 'You must set a completion date';
            return prev;
          });
          break;
        } else{
          updateContact();
          hideModal();
        }
        break;
      case 'Invoiced':
          updateContact();
          hideModal();
        break;

      case 'Notes':
          hideModal();
        break;
      case 'ContactDetails':
        hideModal();
      break;
      default:
        break;
    }
  }

  function convertToSence(parentId, contact) {
    if (!contact) return '';
    var item = contact.field_values.find(obj => { return obj.field_parent_id == parentId })
    if (!item) item = contact.field_values.find(obj => { return obj.field_id == parentId })
    if (!item) return '';
    var obj1 = settings.current.data.settings.fields.results.find(obj => {
      return obj.id == parentId
    })
    if (obj1?.options) {
      return obj1.options?.find(obj => {
        return obj.id == item.field_id
      })
    } else {
      return item?.value
    }
  }

  function updateOrCreate(fieldId, input) {
    var setting = settings.current.data.settings.fields.results.find(obj => { return obj.id == fieldId })  //field_type_id
    if (selectedContact.current.field_values.findIndex(el => (el.field_id === fieldId || (el.field_parent_id === fieldId))) > -1   // dont think we need this....
      || selectedContact.current.field_values.findIndex(el => (el._field_id === fieldId)) > -1) {
      switch (setting.field_type_id) {
        case 2:
        case 3:
          selectedContact.current.field_values[selectedContact.current.field_values.findIndex(el => el.field_parent_id === fieldId)].value = input.toString();
          selectedContact.current.field_values[selectedContact.current.field_values.findIndex(el => el.field_parent_id === fieldId)].field_id = input;
          // selectedContact.current.field_values[selectedContact.current.field_values.findIndex(el => el.field_parent_id === fieldId)].value = input.toString();
          break;
        case 5:
          selectedContact.current.field_values[selectedContact.current.field_values.findIndex(el => el.field_id === fieldId || el._field_id === fieldId)].value = input.toString();
          break;
        case 7:
          selectedContact.current.field_values[selectedContact.current.field_values.findIndex(el => el.field_id === fieldId || el._field_id === fieldId)].value = input;
          break;
        default:
          break;
      }
    } else {
      var field_value = {};
      field_value.field_parent_id = null
      switch (setting.field_type_id) {
        case 2:
        case 3:
          field_value.value = input.toString();
          field_value.field_id = input;
          field_value.field_type_id = setting.field_type_id;
          field_value.field_parent_id = fieldId;
          break;
        case 5:
          field_value.value = input.toString();
          field_value.field_type_id = setting.field_type_id;
          field_value.field_id = fieldId
          break;
        case 7:
          field_value.value = input;
          field_value.field_type_id = setting.field_type_id;
          field_value.field_id = fieldId
          break;

        default:
          field_value.value = input.toString();
          field_value.field_type_id = setting.field_type_id;
          break;
      }
      selectedContact.current.field_values.push(field_value);
    }
  }

  const pitiBook = (dateIn) => {
    //setModalButtons({submit :[<Button variant="primary" onClick={() => handleModalUpdate()} disabled={(files.length==999)}>Submit</Button>]})
    return (<>
      <div className="row" style={{ marginTop: "15px" }}>
        <div className="col-md-4">
          <span className="input-label">PITI Date</span>
        </div>
        <div className="col-md-4">
          <DatePicker locale={'en-GB'}
            dateFormat={'dd/MM/yyyy'} selected={startDate ?? dateIn} onSelect={(date) => {
              setStartDate(date);   //todo create or update - yay!
              updateOrCreate(bookedDate, new Date(date).toLocaleDateString('fr-CA'));
              updateOrCreate(statusId, 239827) //PITI booked
              updateOrCreate(stageDate, new Date().toLocaleDateString('fr-CA'));
            }} />
        </div>
      </div>
      <div className="row float-center text-center" style={{ marginTop: "15px" }}>
        <div className="text-center">
          <button onClick={() => {
            validation.current = {};
            setFiles([]);
            setNextStage('Purchase Order Required ')
          }} className='badge rounded-pill bg-primary text-center'>Complete PITI and upload PITI paperwork</button>
        </div>
      </div>{
        (nextStage == 'Purchase Order Required ') ?
          <>
            <div className="row" style={{ marginTop: "15px" }}>
              <div className="col-md-4">
                <span className="input-label">Insulation m2</span>
              </div>
              <div className="col-md-4">
                <input type={'text'} onChange={(val) => validation.current.insulationm2 = val.target.value}></input>
              </div>
            </div>
            <div className="row" style={{ marginTop: "15px" }}>
              <div className="col-md-4">
                <span className="input-label">Insulation depth</span>
              </div>
              <div className="col-md-4">
                <input type={'text'} onChange={(val) => validation.current.insulationDepth = val.target.value}></input>
              </div>
            </div>
            <FilePicker ref={ref} setFiles={setFiles} files={files} setUploadComplete={setUploadComplete} completed={completed}></FilePicker>
          </>
          : <></>
      }
    </>
    )
  }
  const installBook = () => {

    var appointmentNo = 0;
    var bookDate = '';
    var dateIn ='';
    var newAppointmentNo;
    if (selectedContact.current.field_values.findIndex(el => (el.field_id === installStartDate)) > -1 ) {appointmentNo =1; dateIn = convertToSence(installStartDate,selectedContact.current);}
    if (selectedContact.current.field_values.findIndex(el => (el.field_id === installBooking.no1)) > -1 ) {appointmentNo =2;}
    if (selectedContact.current.field_values.findIndex(el => (el.field_id === installBooking.no2)) > -1 ) {appointmentNo =3;} 
    if (selectedContact.current.field_values.findIndex(el => (el.field_id === installBooking.no3)) > -1 ) {appointmentNo =4;}
    return (<>
      <div className="row" style={{ marginTop: "15px" }}>
        <div className="col-md-6">
          <span className="input-label">Install Appointment Date</span>
        </div>
        <div className="col-md-4">
          <DatePicker locale={'en-GB'}
            dateFormat={'dd/MM/yyyy'} selected={startDate ?? dateIn ?? null} onChange={(date) => {
              
              if (new Date(date).getTime() === new Date(dateIn).getTime())
              {console.log(true)
                selectedContact.current.newReason = 'N/A'
              }
              else{
              if(appointmentNo > 3){setToast((old) => {
                  var prev = { ...old };
                  prev.show = true;
                  prev.color = 'danger';
                  prev.message = 'You cannot re-arrange again';
                  return prev;
                });
              return false;
              }else{
                console.log('huh?')
                // need a change dropdown 
                if (appointmentNo > 0)
                  {
                    var options;
                    if (appointmentNo == 1) options = makeDropdown(cancelationReason1);
                    if (appointmentNo == 2) options = makeDropdown(cancelationReason2);
                    setDdBit([<div className="row" style={{ marginTop: "15px" }}>
                    <div className="col-md-4">
                      <span className="input-label">Change Reason</span>
                    </div>
                    <div className="col-md-4">
                      <select onChange={(e)=>{
                        selectedContact.current.newReason = e.target.value;
                        if (appointmentNo == 1) updateOrCreate(cancelationReason1,e.target.value)
                        if (appointmentNo == 2) updateOrCreate(cancelationReason2,e.target.value)
                        }}>
                        {options}
                      </select>
                    </div>
                  </div>])
                  }

                if (appointmentNo > 0) updateOrCreate(installBooking['no'+(appointmentNo)],new Date(dateIn).toLocaleDateString('fr-CA'))
                updateOrCreate(statusId, 239835) //install booked
                updateOrCreate(stageDate, new Date().toLocaleDateString('fr-CA'));
                updateOrCreate(installStartDate,new Date(date).toLocaleDateString('fr-CA')) 
              } 
              }
              setStartDate(date);
            }} />
        </div>
      </div>
      {ddBit}
      {(appointmentNo > 0)?<div className="row float-center text-center" style={{ marginTop: "15px" }}>
        <div className="text-center">
          <button onClick={() => setNextStage('Installation Completed')} className='badge rounded-pill bg-primary text-center'>Complete Installation</button>
        </div>
      </div>:<></>}
      {
        (nextStage == 'Installation Completed') ? <>
         <div className="row" style={{ marginTop: "15px" }}>
        <div className="col-md-6">
          <span className="input-label">Install Completion Date</span>
        </div>
        <div className="col-md-4">
          <DatePicker locale={'en-GB'}
            dateFormat={'dd/MM/yyyy'} selected={startDate2 ?? null} onChange={(date) => {
              updateOrCreate(statusId, 240195) //install completed
              updateOrCreate(stageDate, new Date().toLocaleDateString('fr-CA'));
              updateOrCreate(installEndDate,new Date(date).toLocaleDateString('fr-CA'))
              setStartDate2(date)
            } }/>
        </div>
        </div>
        </>
         
          : <></>
      }
    </>
    )
  }

  const remedialWorks = () => {
    //setModalButtons({submit :[<Button variant="primary" onClick={() => handleModalUpdate()} disabled={(files.length==999)}>Submit</Button>]})
    return (<>
      
      <div className="row float-center text-center" style={{ marginTop: "15px" }}>
        <div className="text-center">
          <button onClick={() => {
            setNextStage('Installation Completed')
          }} className='badge rounded-pill bg-primary text-center'>Complete Remedial works</button>
        </div>
      </div>{
        (nextStage == 'Installation Completed') ?
          <>
            <div className="row" style={{ marginTop: "15px" }}>
        <div className="col-md-4">
          <span className="input-label">Completion Date</span>
        </div>
        <div className="col-md-4">
          <DatePicker locale={'en-GB'}
            dateFormat={'dd/MM/yyyy'} selected={startDate ?? null} onSelect={(date) => {
              setStartDate(date);  
              updateOrCreate(installEndDate, new Date(date).toLocaleDateString('fr-CA'));
              updateOrCreate(statusId, 240195) //Install completed
              updateOrCreate(stageDate, new Date().toLocaleDateString('fr-CA'));
            }} />
        </div>
      </div>
      </>:<></>
      }
    </>
    )
  }

  const invoice = () => {
    return (<>
      <div className="row float-center text-center" style={{ marginTop: "15px" }}>
        <div className="text-center">
          <button onClick={() => {
            updateOrCreate(statusId, 240197) //Invoiced
            updateOrCreate(stageDate, new Date().toLocaleDateString('fr-CA'));
            setNextStage('Invoiced')
          }} className='badge rounded-pill bg-primary text-center'>Set as Invoiced</button>
        </div>
      </div>{
        (nextStage == 'Invoiced') ?
          <>
         {"click submit to mark as Invoiced"}
          </>
          : <></>
      }
    </>
    )
  }

  const notes = () => {
    return (<>
      <div className="row" style={{ marginTop: "15px" }}>
        <div>
        <div className="form-group" >
          <label for="comment">Previous Notes:</label>
          <textarea className="form-control" rows="5" id="notes" value = {contactNotes} style={{overflowY: "scroll",
        height: "300px", resize: "none"}}>
        </textarea>
        </div>
        <div>
        <div className="form-group">
          <label for="comment">Add Note:</label>
          <textarea className="form-control" rows="5" id="addNote" style={{overflowY: "scroll",
          height: "100px", resize: "none"}} value={noteAdd} onChange={(v) => {setNoteAdd(v.target.value)}}></textarea>
        </div>
        </div>
        <div className="text-center">
           <button onClick={(v) => {
            if (noteAdd.length>0){
           updateNotes()
            }
          }} className='badge rounded-pill bg-primary text-center'>Add Note</button>
        </div>
        </div>
      </div>
    </>
    )
  }

  const contactDetails = () => {
    console.log(selectedContact);
    return (<>
      <div className="row" style={{ marginTop: "15px" }}>
              <div className="col-md-4">
                <span className="input-label">Phone Number</span>
              </div>
              <div className="col-md-4">
                <input readOnly type={'text'} value ={(selectedContact.current.phone_numbers.length) ? selectedContact.current.phone_numbers[0].number:'n/a'} ></input>
              </div>
            </div>
            <div className="row" style={{ marginTop: "15px" }}>
              <div className="col-md-4">
                <span className="input-label">email</span>
              </div>
              <div className="col-md-4">
                <input readOnly type={'text'} value ={(selectedContact.current.emails.length) ? selectedContact.current.emails[0].email:'n/a'}></input>
              </div>
            </div>
    </>
    )
  }

  const ModalSubmitButton = () => {
    console.log("submit")
    switch (nextStage) {
      case 'Purchase Order Required ':
        return <Button variant="primary" onClick={() => handleModalUpdate()} disabled={(files.length == 0 || uploadComplete)}>Upload & Submit</Button>
      case 'Notes':
          return <></>
      case 'ContactDetails':
        return <></>
      default:
        return <Button variant="primary" onClick={() => handleModalUpdate()} disabled={(modalText?.length > 4)}>Submit</Button>
    }
  }

  const modalRD = () => {
    var dateIn;
    var insert;
    console.log(selectedContact?.current)
    switch (selectedContact?.current?.statusName) {
      case 'Insulation PITI ready to book':
      case 'Insulation PITI booked':
        dateIn = convertToSence(bookedDate, selectedContact.current) ? new Date(convertToSence(bookedDate, selectedContact.current)) : null
        insert = pitiBook(dateIn);
        break;
      case 'Purchase Order Required':
        // nothing?
        break;
      case 'Ready for Install':
      case 'Installation Booked':
        insert = installBook();
        break;
      case 'Installation Completed':
        //nothing?
        break;
      case 'Remedial Works Required':
        insert = remedialWorks();
        break;
      case 'Ready to Invoice':
        insert = invoice();
        break;
      case 'Notes':
        insert = notes();
        break;
      case 'ContactDetails':
        insert = notes();
        break;
      default:
        break;
    }
    if (nextStage == 'Notes'){insert = notes()}
    if (nextStage == 'ContactDetails'){insert = contactDetails()}

    return <>
      <Modal show={modalShow} onHide={hideModal} dialogClassName="modal-90w">
        <Modal.Header closeButton>
          <ToastContainer
            className="p-3"
            position={'top-center'}
            style={{ zIndex: 1 }}
          >
            <Toast onClose={() => setToast({ 'show': false })} show={toast.show} delay={3000} autohide bg={toast.color}>
              {/* <Toast.Header>
                                         <img
                                        src="holder.js/20x20?text=%20"
                                        className="rounded me-2"
                                        alt=""/>
                                        <strong className="me-auto">Bootstrap</strong>
                                        <small>11 mins ago</small>  
                                    </Toast.Header>*/}
              <Toast.Body>{toast.message}</Toast.Body>
            </Toast>
          </ToastContainer>
          <Modal.Title>{(nextStage=='ContactDetails')?'Contact Details':(nextStage == 'Notes')?"Notes":"Update Measure"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            {insert}
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideModal}>
            Close
          </Button>
          <ModalSubmitButton></ModalSubmitButton>
        </Modal.Footer>
      </Modal>
    </>
  }

  function Spinner() {
    return (
        <>
            <div className="d-flex justify-content-center">
                <div className="spinner-border text-warning" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        </>
    )
  }

  const handleContractorChange =(e)=>{
    var items = preList.filter(obj => obj.field_values.some(fv => fv.field_parent_id == contractorId && fv.field_id ==e.target.value) )
    if (e.target.value == -1){items = preList}
    setList(items);
  }

  const handleStatusChange =(e)=>{
    var items = preList.filter(obj => obj.field_values.some(fv => fv.field_parent_id == statusId && fv.field_id ==e.target.value) )
    if (e.target.value == -1){items = preList}
    setList(items);
  }

  const handleMeasureChange =(e)=>{
    var items = preList.filter(obj => obj.field_values.some(fv => fv.field_parent_id == measueTypeId && fv.field_id ==e.target.value) )
    if (e.target.value == -1){items = preList}
    setList(items);
  }

  const cards = list?.map((i) => {
    return (
      <>
        <tr>
          <td>
            <p className='fw-bold mb-1'>{i.first_name + ' ' + i.last_name}</p>
            <p className='text-muted mb-0'>{(i.addresses?.length > 0) ? i.addresses[0]?.street : ''}</p>
            <p className='text-muted mb-0'>{(i.addresses?.length > 0) ? i.addresses[0]?.city : ''}</p>
            <p className='text-muted mb-0'>{(i.addresses?.length > 0) ? i.addresses[0]?.postal_code : ''}</p>
          </td>
          <td>
              <p className='fw-bold mb-1'>{(convertToSence(assignedDate, i)) ? 'Assigned Date: ' + new Date(convertToSence(assignedDate, i)).toLocaleDateString('en-GB') : ''}</p>
              <p className='fw-bold mb-1'>{(convertToSence(bookedDate, i)) ? 'PITI Date: ' + new Date(convertToSence(bookedDate, i)).toLocaleDateString('en-GB') : ''}</p>
              <p className='fw-bold mb-1'>
              {(convertToSence(installEndDate, i))?'Install Completion Date: ' + new Date(convertToSence(installEndDate, i)).toLocaleDateString('en-GB')
              :
              (i.installAppointmentNo) ? 'Install Appointment ('+(i.installAppointmentNo??'')+'): ' + new Date(convertToSence(installStartDate, i)).toLocaleDateString('en-GB') : ''}</p>
          </td>
          <td>
            <p className='fw-normal mb-1'>{convertToSence(measueTypeId, i).name} Insulation</p>
          </td>
          <td>
            <p className='fw-normal mb-1'>{convertToSence(notesId, i)}</p>
          </td>
          <td>
            <div className='text-center'>
            <MDBBadge color='primary' pill style={{ width: "100px", whiteSpace: 'normal' }}>
              {i.statusName}
            </MDBBadge>
            </div>
          </td>
          <td>
            <div className='text-center'>
            <p style ={{margin:"10px"}}>
              <button className='badge rounded-pill bg-secondary' size='sm' onClick={() => handleUpdate(i)}>
              Update Status
            </button>
            </p>
            <p style ={{margin:"10px"}}>
            <button className='badge rounded-pill bg-info' size='sm' onClick={() => handleNotes(i)}>
              Contractor Notes
            </button>
            </p>
            <p style ={{margin:"10px"}}>
              <button className='badge rounded-pill bg-warning' size='sm' onClick={() => handleContactDetails(i)}>
              Contact Details
            </button>
            </p>
            </div>
          </td>
        </tr>
      </>
    )
  }
  )

  return (
    <div className='col-sm-12'>
    {modalRD()}
      <div className='container'>
      <div className="row">
        <div className="col">
          <button className="btn btn-primary float-end" type="button" disabled={(loading)} onClick={() => {
            running.current= !running.current;
            setLoading(true);
            }
            }>
          {(loading)?<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:<></>}
            {(loading)?' Refreshing':'Refresh'}
          </button>
        </div>
      </div>
      <br/>
        <div className="row">
          {(happyStaff)?
          <div className="col text-center">
            <div className='row'>
              <div className="col"> 
                <p className=''>Contractor</p> 
              </div>
            </div>
            <div className='row'>
              <div className="col text-center" >
                <select className='form-select text-center' style={{maxWidth:"300px",margin:"auto"}} onChange={(e) => handleContractorChange(e)}>
                  {contractorOpts}
                </select>
              </div>
            </div>
          </div>
          :<></>
          }

          <div className="col text-center">
            <div className='row'>
              <div className="col"> 
                <p className=''>Status</p> 
              </div>
            </div>
            <div className='row'>
              <div className="col text-center" >
                <select className='form-select text-center' style={{maxWidth:"300px",margin:"auto"}} onChange={(e) => handleStatusChange(e)}>
                  {statusOpts}
                </select>
              </div>
            </div>
          </div>

          <div className="col text-center">
            <div className='row'>
              <div className="col"> 
                <p className=''>Measure</p> 
              </div>
            </div>
            <div className='row'>
              <div className="col text-center" >
                <select className='form-select text-center' style={{maxWidth:"300px",margin:"auto"}} onChange={(e) => handleMeasureChange(e)}>
                {measureOpts}
                </select>
              </div>
            </div>
          </div>

          {(!happyStaff)?
          <div className="col text-center">
            <div className='row'>
              <div className="col"> 
                {/* <p className=''>Contractor</p>  */}
              </div>
            </div>
            <div className='row'>
              <div className="col text-center" >
                <></>
              </div>
            </div>
          </div>
          :<></>
          }
        </div>
      <br/>
      </div>
      <MDBTable align='middle'>
        <MDBTableHead>
          <tr>
            <th scope='col' className='w-15'>Address</th>
            <th scope='col' className='w-15'>Dates</th>
            <th scope='col' className='w-15'>Measure</th>
            <th scope='col' className='w-15'>Measure Notes</th>
            <th scope='col' className='w-15 text-center'>Status</th>
            <th scope='col' className='w-15 text-center'>Actions</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {(loading)?<tr><td colSpan={6}><Spinner></Spinner></td></tr>:cards}
        </MDBTableBody>
      </MDBTable>
    </div>
  )
}