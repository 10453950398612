import React,{ useState } from 'react';
import Admin from './EpcCalcAdmin'
import User from './EpcCalcUser'
import { useMsal } from '@azure/msal-react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';


const QueryPage = () => {
  const { instance } = useMsal();
  const [checked, setChecked] = useState(false);
  const [radioValue, setRadioValue] = useState('1');

  const radios = [
    { name: 'User', value: '1' },
    { name: 'Admin', value: '2' },
  ];




    // function handleAdmin() {
    //     setList((prev) => {
    //         prev.list = prev.list.filter(function (list2) {
    //             return list2.id !== "Roof*" || list2.id !== "Roof!"
    //         })
    //         return prev;
    //     })
    // }


    return (
        <div className="row mb-2 mt-5">
            <div className="col-md-10 offset-1">
                <div className="row">
                    <h1 className="col-md-12">
                        Happy Energy EPC Calculator
                    </h1>
                    <div className="col-md-6">
                    </div>
                    <div className="col-md-3">
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-md-2">
                    </div>
                    <div className="col-md-2">
                    </div>
                    <div className="col-md-8">
                    <ButtonGroup className='float-end'>
                        {radios.map((radio, idx) => (
                        <ToggleButton
                            key={idx}
                            id={`radio-${idx}`}
                            type="radio"
                            variant={idx % 2 ? 'outline-danger' : 'outline-success'}
                            name="radio"
                            value={radio.value}
                            checked={radioValue === radio.value}
                            onChange={(e) => setRadioValue(e.currentTarget.value)}
                        >
                            {radio.name}
                        </ToggleButton>
                        ))}
                    </ButtonGroup>
                    </div>
                </div>
                <br />

                {(radioValue == 1) ?<User/>:<Admin/>}
            </div>
        </div>
    );
};
export default QueryPage;