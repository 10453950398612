import {useMsal} from '@azure/msal-react';
import logo from '../img/karma.png';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import KarmaApiService from '../services/karmaApi'
import React, { useState, useLayoutEffect, useMemo, useRef} from "react";
import {AsyncTypeahead} from 'react-bootstrap-typeahead';
import xml2js from "xml2js";
import {PreEPCRow,convertXml} from "./PreEPC";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSms } from '@fortawesome/free-solid-svg-icons'

const Search = () => {
    const preEpcFields = [229149,233685,244045,245860,230184,229279,229174,229157,229160,244045,245792,245855,239141,239147,239152,239158,239166,239176,239185,239217,239196,239208,239224,239231,
        239244,239253,239267,239260,239272,239283,239277,239312,239324,239349,239384,239364,239368,239302,239306]
    const preEpcHelpers = [230416,248080,248081,229236,233672]
    const epcYesNo = 248418, epcParentId = 248417, epcRating = 248399, sapScore = 248416, yesValue = "248418", noValue = "248419"
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [contact, setContact] = useState([]);
    const [preContact, setPreContact] = useState([]);
    const [xmlObj, setXmlObj] = useState([]);
    const [contactHTML, setContactHTML] = useState([]);
    const [show, setShow] = useState(false);
    const [modelContent,setModelContent] = useState([]);
    const settings = useRef([]);
    const textData = useRef({});
    const [sending, setSending] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleSearch = async (query) => {
        var results = [];
        console.log(settings);
        setIsLoading(true);
        
        var items = await KarmaApiService.contactsFiltered({queries:'&filters[addresses][street]='+query},1,1000, instance)
        results = results.concat(items.data)
         items = await KarmaApiService.contactsFiltered({queries:'&filters[addresses][postal_code]='+query},1,1000, instance)
         results = results.concat(items.data)
         items = await KarmaApiService.siteWideSearch(query, instance)
         results = results.concat(items.data)
        console.log(results)
        var options = results.map((i) => ({
            id: i.id,
            desc: (i.first_name)? i.first_name + " " + i.last_name??'' : i.label??'n/a',
            add: (i.addresses)?i.addresses[0].street + ", " + i.addresses[0].postal_code:''
        }));
        setOptions(options);
        setIsLoading(false);
    };
    useLayoutEffect(() => {
        if (contact[0]) {
            buildHtml(contact[0])
        }
    }, [JSON.stringify(contact[0]?.field_values), JSON.stringify(contact[0]?.notes), JSON.stringify(contact[0]?.attachments)]);

    function row(label, value, id) {
        return <div key={value} className="row" style={{ marginTop: "15px" }}>
            <div className="col-md-4">
                <span className="input-label">{label}</span>
            </div>
            <div className="col-md-4">
                <input id={id} type="text" className="form-control" defaultValue={value} onChange={handleOnchange}></input>
            </div>
        </div>
    }

    const ref = useRef([])
    const handleAttachmentClick = (e, x) => {
        ref.current[x].click()
    }

    const xmlRef = useRef([])
    const handleXmlClick = (e, x) => {
        if (xmlRef.current.value) xmlRef.current.value = ''
        xmlRef.current.click()
    }

    function ddRow(label, options, id, selected) {
        return <div key={id} className="row" style={{ marginTop: "15px" }}>
            <div className="col-md-4">
                <span className="input-label">{label}</span>
            </div>
            <div className="col-md-4">
                <select id={id} className="form-select form-select-lg mb-3" onChange={handleOnchange} aria-label="Default select example">
                    {options.map((ops) => {
                        return <option selected={(ops === selected)} value={ops}>{ops}</option>
                    })}
                </select>
            </div>
        </div>
    }

    function handleOnchange(event) {
        var _ = require('lodash');
        if (event.target) {
            var arr = event.target?.id.split("*")
        }
        var key = arr[0];
        if (arr[1]) {  //asume arr[1] is only populated for field_values
            key = "field_values[" + arr[1] + "]['value']"
        }
        var old = [...contact]
        if (arr[1] == -1) {
            var item = {};
            item.field_id = convertFieldEnum(arr[0], event.target.value)
            item.value = convertValueEnum(event.target.value)
            if (arr[0] === "dd") item.field_parent_id = epcParentId
            old[0].field_values.push(item)
            setContact(old);
        } else {
            _.set(old[0], key, convertValueEnum(event.target.value))
            if (old[0].field_values[arr[1]]?.field_parent_id === epcParentId) _.set(old[0], "field_values[" + arr[1] + "]['field_id']", convertFieldEnum(arr[0], event.target.value))
            if(arr[2]) _.set(old[0], "field_values[" + arr[1] + "]['field_id']", parseInt(event.target.value))
            if (arr[0] == "dd" && event.target.value == "No") //yes / no if no then remove the 
            {
                for (let x = 0; x < old[0].field_values.length; x++) {
                    if (old[0].field_values[x].field_id === epcYesNo) continue
                    old[0].field_values[x].value = '-'
                }
            }
            setContact(old);
        }
        console.log(old);
    }

    function handlePreOnchange(event) {
        var _ = require('lodash');
        if (event.target) {
            var arr = event.target?.id.split("*")
        }
        var key = arr[0];
        if (arr[1]) {  //asume arr[1] is only populated for field_values
            key = "field_values[" + arr[1] + "]['value']"
        }
        var old = [...preContact]
        if (arr[1] == -1) {
            var item = {};
            item.field_id = convertFieldEnum(arr[0], event.target.value)
            item.value = convertValueEnum(event.target.value)
            old[0].field_values.push(item)
            setContact(old);
        } else {
            _.set(old[0], key, convertValueEnum(event.target.value))
            if(arr[2]) _.set(old[0], "field_values[" + arr[1] + "]['field_id']", parseInt(event.target.value))
            setPreContact(old);
        }
        console.log(old);
    }



    async function handleUpdate() {
        var contactOut = {};
        var clone = structuredClone(contact[0]);
        contactOut.contact = clone;
        var id = contactOut.contact['id'];
        var id = id.toString();
        delete contactOut.contact['id'];
        delete contactOut.contact['avatar_url'];
        delete contactOut.contact['organization_id'];
        delete contactOut.contact['created_at'];
        delete contactOut.contact['created_by_id'];
        delete contactOut.contact['updated_at'];
        delete contactOut.contact['relationships'];
        delete contactOut.contact['important_dates'];
        for (let x = 0; x < contactOut.contact['field_values'].length; x++) {
            delete contactOut.contact['field_values'][x]['field_type_id']
        }
        delete contactOut.contact.notes
        await KarmaApiService.contactUpdate(id, contactOut, instance)
        var notes = structuredClone(contact[0].notes);
        var newNotes = await KarmaApiService.contactNotesUpdate(id, notes, instance)
        var old = [...contact];
        for (let x = 0; x < old[0]?.attachments?.length; x++) {
            if (old[0].attachments[x].file) {
                const formData = new FormData();
                formData.append("attachment[record_type]", "Contact")
                formData.append("attachment[record_id]", id)
                formData.append("attachment[file]", old[0].attachments[x].file)
                var attachment = await KarmaApiService.attachementUpload(formData, instance);
                old[0].attachments[x] = attachment.data;
            }
        }
        for (let x = 0; x < old[0].notes.length; x++) {
            if (!old[0].notes[x].id) {
                old[0].notes[x] = newNotes.data[0];
            }
        }
        setContact(old);
    }

    function convertFieldEnum(value, yesNo) {
        switch (value) {
            case "dd":
                if (yesNo === "Yes") {
                    console.log("yes")
                    return parseInt(yesValue)
                }
                else {
                    console.log("no" + yesNo)
                    return parseInt(noValue)
                }
            case "dd1":
                return epcRating
            case "dd2":
                return sapScore
            default:
                break;
        }
    }

    function convertFieldTypeEnum(value) {
        switch (value) {
            case "dd":
                return 2
            default:
                return 5
        }
    }

    function convertValueEnum(value) {
        switch (value) {
            case "No":
                return noValue
            case "Yes":
                return yesValue
            default:
                return value
        }
    }

    function getKeyByValue(object, value) {
        return Object.keys(object).find(key => object[key] === value);
    }

    function range(start, end) {
        return Array(end - start + 1).fill().map((_, idx) => (start + idx).toString())
    }

    function convertCustomEnum(value) {
        switch (value) {
            case yesValue:
                return "Yes"
            case noValue:
                return "No"
            case "No":
                return noValue
            case "Yes":
                return yesValue
            default:
                return "No"
        }
    }

    function getSAPScore(value) {
        switch (value) {
            case 'A':
                return range(92, 120); // [9, 10, 11, 12, 13, 14, 15, 16, 17, 18]
            case 'B':
                return range(81, 91); // [9, 10, 11, 12, 13, 14, 15, 16, 17, 18]
            case 'C':
                return range(69, 80); // [9, 10, 11, 12, 13, 14, 15, 16, 17, 18]
            case 'D':
                return range(55, 68); // [9, 10, 11, 12, 13, 14, 15, 16, 17, 18]
            case 'E':
                return range(39, 54); // [9, 10, 11, 12, 13, 14, 15, 16, 17, 18]
            case 'F':
                return range(21, 38); // [9, 10, 11, 12, 13, 14, 15, 16, 17, 18]
            case 'G':
                return range(1, 20); // [9, 10, 11, 12, 13, 14, 15, 16, 17, 18]
            case '-':
                return ['-']; // [9, 10, 11, 12, 13, 14, 15, 16, 17, 18]
            default:
                break;
        }
    }

    function createNote() {
        var old = [...contact]
        old[0].notes.push(
            {
                "body": "", "contact_type_id": 3,
                "date": new Date().toLocaleString('en-US'), "participants": [{ "label": "", "participater_type": "User", "participater_id": 4 }], "user_ids": [4], "parent_type": "Contact", "parent_id": old[0].id
            }
        )
        setContact(old);
    }

    function createMessage() {
        textData.current.number=""
        textData.current.message=""
        setSending(false);
        var old = [...contact]
        setModelContent([
            <>
              <Modal.Body>
            <>
            <div className="row" style={{ marginTop: "15px" }}>
            <div className="col-md-4">
                <span className="input-label">Number</span>
            </div>
            <div className="col-md-4">
                <input type='text' onChange={(v) => textData.current.number = v.target.value }>
                </input>
            </div>
            </div>
            <div className="row" style={{ marginTop: "15px" }}>
            <div className="col-md-4">
                <span className="input-label">Message</span>
            </div>
            <div className="col-md-4">
                <textarea rows={5} onChange={(v) => textData.current.message = v.target.value }>
                </textarea>
            </div>
            </div>
            </>
        </Modal.Body>
        <Modal.Footer>
            {(sending)?
            <Spinner></Spinner>
            :
            <>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSendSMS}>
            Send SMS
          </Button>
          </>
            }
        </Modal.Footer>
        </>
        ]);
        handleShow();
    }

    function Spinner() {
        return (
            <>
                <div className="d-flex justify-content-center">
                    <div className="spinner-border text-warning" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </>
        )
      }

    async function handleSendSMS(){
        if (!textData.current.number) return;
        if (!textData.current.message) return;
        setSending(true);
        console.log(textData.current)
        await KarmaApiService.sendSMS(textData.current.number,textData.current.message,instance)

        var old = [...contact]
        old[0].notes.push(
            {
                "body": "SMS message : "+ textData.current.message + " sent to " + textData.current.number , "contact_type_id": 3,
                "date": new Date().toLocaleString('en-US'), "participants": [{ "label": "", "participater_type": "User", "participater_id": 4 }], "user_ids": [4], "parent_type": "Contact", "parent_id": old[0].id
            }
        )
        setSending(false);
        handleClose();
        setContact(old);
    }


    function deleteNote() {
        var old = [...contact]
        old[0].notes.pop();
        setContact(old);
    }

    function notesHtml(notes) {
        var notesOut = [];
        var count = 0;
        for (let x = 0; x < notes.length; x++) {
            if (notes[x].id) count++;
        }
        if (notes.length > -1) {
            notesOut.push(
                <div key={notes.id} className="row" style={{ marginTop: "15px" }}>
                    <div className="col-md-10">
                        <h2>Notes</h2>
                    </div>
                    <div className="col-md-2">
                        <div className="row float-end" style={{ marginRight: "10px", marginBottom: "10px" }}>
                            {(count === notes.length) ? 
                            <>
                            <button className="btn btn-primary btn-circle btn-lg" onClick={() => { createNote() }} style={{ color: "white", borderRadius: "20px" }}>
                                <i className="fa fa-plus-circle" />
                            </button>
                            <button className="btn btn-primary btn-circle btn-lg" onClick={() => { createMessage() }} style={{ color: "white", borderRadius: "20px" }}>
                            <FontAwesomeIcon icon={faSms} />
                        </button>
                        </>
                                :
                                <button className="btn btn-danger btn-circle btn-lg" onClick={() => { deleteNote() }} style={{ color: "white", borderRadius: "20px" }}>
                                    <i className="fa fa-minus-circle " style={{}} />
                                </button>

                            }
                        </div>
                    </div>
                </div>
            )
        }
        for (let i = notes.length - 1; i > -1; i--) {
            var textDate;
            if (notes[i].date) {
                textDate = Date.parse(notes[i].date);
            } else {
                textDate = Date.parse(notes[i].updated_at);
            }
            var date = new Date(textDate);
            date = date.toLocaleString('en-UK')
            notesOut.push(
                <div key={(notes[i].id)} className="row" style={{ marginTop: "15px" }}>
                    <div className="col-md-12">
                        <label className="col-sm-6 float-right" style={{ marginRight: "25px", marginBottom: '-15px' }}><span class="h6 small bg-white float-right">{date} </span></label>
                        <textarea disabled={(notes[i].id)} onChange={handleOnchange} id={"notes[" + i + "][body]"} class="form-control" rows="4">{notes[i].body}</textarea>
                    </div>
                </div>
            )
        }
        return notesOut;
    }

    function attachmentsHtml(attachments) {
        var attachmentsOut = [];
        var count = 0;
        attachmentsOut.push(
            <div key={"RA"} className="row" style={{ marginTop: "15px" }}>
                <div className="col-md-10">
                    <h2>Attachements</h2>
                </div>
                <div className="col-md-2">
                    <div className="row float-end" style={{ marginRight: "10px", marginBottom: "10px" }}>
                        <button className="btn btn-primary btn-circle btn-lg" onClick={() => { createAttachment() }} >
                            <i className="fa fa-plus-circle" />
                        </button>
                    </div>
                </div>
            </div>
        )
        for (let x = 0; x < attachments?.length; x++) {
            attachmentsOut.push(
                <>
                    {(attachments[x].asset_id > -1) ?
                        <div key={(attachments[x].asset_id)} className="row" style={{ marginTop: "15px" }}>
                            <div className="col-md-10">
                                {/* <label className="col-sm-6 float-right" style={{marginRight:"25px",marginBottom:'-15px'}}><span class="h6 small bg-white float-right">{date} </span></label> */}
                                <input disabled id={"attachments[" + x + "][asset_id]"} class="form-control" rows="4" defaultValue={attachments[x].data_file_name}></input>
                            </div>
                            <div className="col-md-2 ">
                                <button onClick={() => handleOpen(attachments[x].asset_id, attachments[x].data_file_name)} className="btn btn-primary float-end">
                                    Open
                                </button>
                            </div>
                        </div>
                        :
                        <div key={(attachments[x].asset_id)} className="row" style={{ marginTop: "15px" }}>
                            <div className="col-md-10">
                                {/* <label className="col-sm-6 float-right" style={{marginRight:"25px",marginBottom:'-15px'}}><span class="h6 small bg-white float-right">{date} </span></label> */}
                                <input id={"attachments[" + x + "][asset_id]"} type='file' ref={(element) => ref.current[x] = element} className='custom-file-input' onChange={(e) => onFilechange(e, x)} style={{ display: 'none' }}></input>
                                <input id={"attachments[" + x + "][asset_id]"} class="form-control" rows="4" defaultValue={attachments[x].data_file_name}></input>
                            </div>
                            <div className="col-md-2 ">
                                <button onClick={(e) => handleAttachmentClick(e, x)} className="btn btn-primary float-end">
                                    Upload
                                </button>
                            </div>
                        </div>
                    }</>
            )
        }
        return attachmentsOut;
    }


    function onFilechange(e, x) {
        if (e.target.files[0]) {
            console.log(e.target.files[0].name, x);
            var old = [...contact]
            old[0].attachments[x].data_file_name = e.target.files[0].name;
            old[0].attachments[x].file = e.target.files[0];
            setContact(old);
        }
    }

    function onFilechange(e, x) {
        if ( /\.(xml)$/i.test(e.target.files[0].name) === true ) { 
            xmlFile(e)
             }
        else if ( /\.(pdf)$/i.test(e.target.files[0].name) === true ) {
            pdfFile(e)
        }
        else{
            console.log('not xml or Pdf')
        }
    }

    function pdfFile(e){
       // const fs = require('fs');
        // const pdf = require('pdf-parse');
 
        // let file = e.target.files[0]; // Get the selected PDF file
        // if (file != undefined && file.type == "application/pdf") {
        //    // let fr = new FileReader(); // Create a new FileReader object
        // let dataBuffer = FileReader(e.target.files[0]);
 
        // pdf(dataBuffer).then(function(data) {
        //     // use data
        //     console.log(data.text); 
        // })
        // .catch(function(error){
        //    // handle exceptions
        // }) 
        
        // }else {
        //     alert("Select a valid PDF file");
       // }
    
        
    }

    function xmlFile(e){
        setXmlObj((prev) => {
            var old = [...prev];
            old = [];
            return old;
        });
        console.log("change")
        const files = e.target.files;
        if (e.target.files[0].name) setXmlObj([{ 'fileName': e.target.files[0].name, 'fields': [], 'data': [] }]);
        let parser = new xml2js.Parser();
        if (files.length) {
            const file = files[0];
            const reader = new FileReader();
            reader.onload = (event) => {
                parser.parseString(event.target.result, function (err, result) {
                    console.dir(result);
                    var con = structuredClone(contact);
                    for (let x = 0; x < con[0].field_values.length;x++)
                    {
                        if(preEpcFields.includes(con[0].field_values[x].field_parent_id)||preEpcFields.includes(con[0].field_values[x].field_id)){
                        con[0].field_values[x].value = ""
                        }
                    }
                    //con[0].field_values.map((v)=> v.value = "");
                    var contactOut = convertXml(result?.SurveyRec,con);
                    var fvs = [];

                    for (let x = 0; x < preEpcHelpers.length; x++){
                        let fv = contactOut[0].field_values.find(obj => obj.field_parent_id === preEpcHelpers[x] || obj.field_id === preEpcHelpers[x] )
                        let item = {};
                        let index = x;
                        if (fv){
                            item = fv;
                            fvs.push(<PreEPCRow data={item} index={index} handleOnchange={handlePreOnchange} disabled={true}/>)
                        }
                    }

                    for (let x = 0; x < preEpcFields.length; x++){
                        console.log(contactOut);
                        let fv = contactOut[0].field_values.find(obj => obj.field_parent_id === preEpcFields[x] || obj.field_id === preEpcFields[x] )
                        let item = {};
                        
                        let index = x;
                        console.log(fv);
                        if (fv){
                            item = fv;
                        }else{
                            item.field_id = preEpcFields[x];
                            item.value = "";
                            index = -1;
                        }
                        fvs.push(<PreEPCRow data={item} index={index} handleOnchange={handlePreOnchange}/>)
                        
                    }
                     console.log("did we get here?")
                    setPreContact(contactOut);
                    setModelContent([
                        <>
                        <Modal.Body>
                        <>
                        {fvs}
                        </>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={handleClose}>
                            Close
                          </Button>
                          <Button variant="primary" onClick={handleClose}>
                            Save Changes
                          </Button>
                        </Modal.Footer>
                        </>
                        ]);
                    handleShow();

                    setXmlObj((prev) => {
                        var old = [...prev];
                        old.count = ((old.count) ?? 0) + 1;
                        old[0].data = result;
                        old[0].outputHtml = [];
                        console.log(old);
                      //  setHtmlSel([])
                        return old;
                    });
                });
            }
            reader.readAsText(file);
            reader.onloadend = (event) => {
            }
        }
    }

    function createAttachment() {
        var old = [...contact]
        if (!old[0].attachments) old[0].attachments = [];
        old[0].attachments.push({ data_file_name: "", asset_id: -1 })
        setContact(old);
    }

    const handleOpen = async (asset, filename) => {
        var file = await KarmaApiService.attachementGet(asset, instance);
        const url = window.URL.createObjectURL(file.data);
        const link = document.createElement("a");
        link.href = url;
        link.download = filename;
        link.click();
    }

    async function typeAheadChange(input) {
        var result = await KarmaApiService.contactGet(input[0].id, instance).catch(function (error) {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        });

        var result1 = result.data.field_values.filter((v,i,a)=>a.findIndex(v2=>(v.field_parent_id === v2.field_parent_id))===i)
        
        var output = [...result1];
        output = output.filter(function( obj ) {
            return obj.field_type_id == 2;
        });
        result.data.field_values = result.data.field_values.filter(function( obj ) {
            return obj.field_type_id !== 2;
        });
        result.data.field_values = [...result.data.field_values,...output];
        result.data.field_values.sort((a,b) => a.id - b.id);
        console.log(result.data);
        setContact([result.data]);
    }

    function buildHtml(results) {
        var data = [];
        var index = 0;
        data.push(
           
            <div key="0" className="row" style={{ marginTop: "35px" ,marginBottom:"35px"}}>
        <div className="col-md-12 ">
        <div className="dropdown float-end">
         <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
             Action
         </button>
         <ul className="dropdown-menu">
             <li><button className="dropdown-item" onClick={(e) => handleXmlClick(e)} type="button">Upload EPC pre XML File</button></li>
             {/* <button className="dropdown-item" type="button">Another action</button> */}
             {/* <button className="dropdown-item" type="button">Something else here</button> */}
         </ul>
         </div>
         </div>
         </div>);


        data.push(row("Title", results.position, "position", results.id));
        data.push(row("First Name", results.first_name, "first_name", results.id));
        data.push(row("Last Name", results.last_name, "last_name", results.id));
        results.addresses.map((add, i) => {
            data.push(row("Street", add.street, "addresses[" + i + "][street]"))
            data.push(row("City", add.city, "addresses[" + i + "][city]"))
            data.push(row("Postcode", add.postal_code, "addresses[" + i + "][postal_code]"))
        })
        var yesNo = results.field_values.find(obj => {
            return obj.field_parent_id === epcParentId
        })
        index = results.field_values.map(e => e.field_parent_id).indexOf(epcParentId);
        console.log(convertCustomEnum(yesNo?.value));
        data.push(ddRow("Does the home have an existing EPC rating?", ['Yes', 'No'], 'dd*' + index, convertCustomEnum(yesNo?.value)))

        if (convertCustomEnum(yesNo?.value) === "Yes") {
            var rating = results.field_values.find(obj => {
                return obj.field_id === epcRating
            })
            index = results.field_values.map(e => e.field_id).indexOf(epcRating);
            var ratingV = '';
            if (rating) {
                ratingV = rating.value
            } else {
                ratingV = '-'
            }
            data.push(ddRow("EPC rating", ['A', 'B', 'C', 'D', 'E', 'F', 'G', '-'], 'dd1*' + index, ratingV))
            var score = results.field_values.find(obj => {
                return obj.field_id === sapScore
            })
            index = results.field_values.map(e => e.field_id).indexOf(sapScore);
            console.log(score);
            var scoreV
            if (index === -1) {
                scoreV = '-'
            } else {
                scoreV = score?.value
            }
            data.push(ddRow("SAP Score", [...getSAPScore(ratingV), '-'], 'dd2*' + index, scoreV))
        }

        var innerData =[]  //accordians

        innerData.push(<Accordion.Item eventKey="0">
        <Accordion.Header>Pre EPC New</Accordion.Header>
        <Accordion.Body>
            {
            results.field_values.map((item,index) =>{
                if (preEpcFields.includes(item.field_parent_id??item.field_id)) return <PreEPCRow data={item} index={index} handleOnchange={handleOnchange}/>
            })
        }
        </Accordion.Body>
      </Accordion.Item>
      )


        // add in the accordians
        data.push(
        <Accordion flush>
            {innerData}
            </Accordion>
        )
        data.push(notesHtml(results.notes));
        data.push(attachmentsHtml(results.attachments))
        data.push(
            <div key="1" className="row" style={{ marginTop: "35px" }}>
                
                    <div className="col-md-2 text-left">
                    <button onClick={handleUpdate} className="btn btn-primary">
                        Update
                    </button>
                </div>
                <div className="col-md-10 text-center">
                    
                </div>
                
            </div>
        )

        setContactHTML((prev) => [...[], ...data]);
        return data;
    }

    const filterBy = () => true;

    const data = useMemo(async () => {
        var page = 0;
        settings.current = await KarmaApiService.getSettings(instance).catch(function (error) {
            // setIsLoading("error");
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        });

        // var result = KarmaApiService.contactsGetAll(page, instance).catch(function (error) {
        //     // setIsLoading("error");
        //     if (error.response) {
        //         console.log(error.response.data);
        //         console.log(error.response.status);
        //         console.log(error.response.headers);
        //     } else if (error.request) {
        //         console.log(error.request);
        //     } else {
        //         console.log('Error', error.message);
        //     }
        // });
    }, [])

    return (
        <>
 
 <Modal show={show} onHide={handleClose} dialogClassName="modal-90w">
        <Modal.Header closeButton>
          <Modal.Title>Pop up</Modal.Title>
        </Modal.Header>
        {modelContent}
      </Modal>

            

                <input id='xmlFile' type='file' accept='.xml,.pdf' ref={xmlRef} className='custom-file-input' onChange={(e) => onFilechange(e)} style={{ display: 'none' }}></input>
                    
                    <div className="row" style={{ marginBottom: "35px" }}>
                            <div className="col-md-2">
                 
                         </div>

                    <div className="col-md-8">
                    
                    <AsyncTypeahead
                        filterBy={filterBy}
                        id="Search"
                        isLoading={isLoading}
                        labelKey="desc"
                        minLength={3}
                        onSearch={handleSearch}
                        options={options}
                        onChange={typeAheadChange}
                        placeholder="Search for a contact..."
                        renderMenuItemChildren={(option) => (
                            <>
                                <span>{option.desc}</span>
                                <br />
                                <span>{option.add}</span>
                            </>
                        )}
                    />
                    </div>
                    <div className="col-md-2">

                    </div>
                    </div>
                    
                    {contactHTML}
             
        </>
    );
};

export default Search;
