import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useMsal } from '@azure/msal-react';
import { utils, writeFile } from 'xlsx';
import propertyDbService from "../services/propertyDbService";
import epcQuery from "../services/epcQuery";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { components } from "react-select";
import Multiselect from 'multiselect-react-dropdown';
import { default as Select } from "react-select";
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { MultiSelectComponent, CheckBoxSelection, Inject } from '@syncfusion/ej2-react-dropdowns';
import { DataManager, Query, WebApiAdaptor, UrlAdaptor } from '@syncfusion/ej2-data';


const QueryPage = () => {
    const [spreadsheetRows, setSpreadsheetRows] = useState();
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const [spreadsheetRowsAppend, setSpreadsheetRowsAppend] = useState();
    const [progress, setProgress] = useState(100);
    const [html, setHtml] = useState([]);
    const [errorText, setErrorText] = useState([]);
    const [queryRows, setQueryRows] = useState([]);
    const [selectedMenu, setSelectedMenu] = useState();
    const [columnSelection, setColumnSelection] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [queryRowCount, setQueryRowCount] = useState(-1);
    const selectedColumn = useRef({});
    const queryOptions = useRef({});
    const [wbState, setWbState] = useState();
    const [method, setMethod] = useState();
    const [methodSub, setMethodSub] = useState("");
    const [fileName, setFileName] = useState();
    const [addOptions, setAddOptions] = useState({});
    const numberArr = ["id", "CURRENT_ENERGY_EFFICIENCY", "POTENTIAL_ENERGY_EFFICIENCY", "ENVIRONMENT_IMPACT_CURRENT", "ENVIRONMENT_IMPACT_POTENTIAL", "ENERGY_CONSUMPTION_CURRENT", "ENERGY_CONSUMPTION_POTENTIAL",
        "CO2_EMISSIONS_CURRENT", "CO2_EMISS_CURR_PER_FLOOR_AREA", "CO2_EMISSIONS_POTENTIAL", "LIGHTING_COST_CURRENT", "LIGHTING_COST_POTENTIAL", "HEATING_COST_CURRENT", "HEATING_COST_POTENTIAL", "HOT_WATER_COST_CURRENT",
        "HOT_WATER_COST_POTENTIAL", "TOTAL_FLOOR_AREA", "FLAT_STOREY_COUNT", "MULTI_GLAZE_PROPORTION", "EXTENSION_COUNT", "NUMBER_HABITABLE_ROOMS", "NUMBER_HEATED_ROOMS", "LOW_ENERGY_LIGHTING", "NUMBER_OPEN_FIREPLACES",
        "UNHEATED_CORRIDOR_LENGTH", "FLOOR_HEIGHT", "PHOTO_SUPPLY", "FIXED_LIGHTING_OUTLETS_COUNT", "LOW_ENERGY_FIXED_LIGHT_COUNT"]

    // const checkFields = { text: 'Name', value: 'Code' };
    let checkboxObj;
    let mulObj;

    const dataRemote = new DataManager({
        url: 'https://happyengine.azurewebsites.net/api/query/epcDistinct',
        adaptor: new UrlAdaptor, //new WebApiAdaptor,
        crossDomain: true
    });
    function query(value) { return new Query().addParams('field', value); };
    const remoteFields = { text: 'result', value: 'result' };

    const fields = ['LMK_KEY', 'ADDRESS1', 'ADDRESS2', 'ADDRESS3', 'POSTCODE', 'BUILDING_REFERENCE_NUMBER', 'CURRENT_ENERGY_RATING', 'POTENTIAL_ENERGY_RATING', 'CURRENT_ENERGY_EFFICIENCY'
        , 'POTENTIAL_ENERGY_EFFICIENCY', 'PROPERTY_TYPE', 'BUILT_FORM', 'INSPECTION_DATE', 'LOCAL_AUTHORITY', 'CONSTITUENCY', 'COUNTY', 'LODGEMENT_DATE', 'TRANSACTION_TYPE', 'ENVIRONMENT_IMPACT_CURRENT'
        , 'ENVIRONMENT_IMPACT_POTENTIAL', 'ENERGY_CONSUMPTION_CURRENT', 'ENERGY_CONSUMPTION_POTENTIAL'
        , 'CO2_EMISSIONS_CURRENT', 'CO2_EMISS_CURR_PER_FLOOR_AREA', 'CO2_EMISSIONS_POTENTIAL', 'LIGHTING_COST_CURRENT', 'LIGHTING_COST_POTENTIAL'
        , 'HEATING_COST_CURRENT', 'HEATING_COST_POTENTIAL', 'HOT_WATER_COST_CURRENT', 'HOT_WATER_COST_POTENTIAL', 'TOTAL_FLOOR_AREA', 'ENERGY_TARIFF', 'MAINS_GAS_FLAG'
        , 'FLOOR_LEVEL', 'FLAT_TOP_STOREY', 'FLAT_STOREY_COUNT', 'MAIN_HEATING_CONTROLS', 'MULTI_GLAZE_PROPORTION', 'GLAZED_TYPE'
        , 'GLAZED_AREA', 'EXTENSION_COUNT', 'NUMBER_HABITABLE_ROOMS', 'NUMBER_HEATED_ROOMS', 'LOW_ENERGY_LIGHTING', 'NUMBER_OPEN_FIREPLACES'
        , 'HOTWATER_DESCRIPTION', 'HOT_WATER_ENERGY_EFF', 'HOT_WATER_ENV_EFF', 'FLOOR_DESCRIPTION', 'FLOOR_ENERGY_EFF'
        , 'FLOOR_ENV_EFF', 'WINDOWS_DESCRIPTION', 'WINDOWS_ENERGY_EFF', 'WINDOWS_ENV_EFF', 'WALLS_DESCRIPTION', 'WALLS_ENERGY_EFF'
        , 'WALLS_ENV_EFF', 'SECONDHEAT_DESCRIPTION', 'SHEATING_ENERGY_EFF', 'SHEATING_ENV_EFF', 'ROOF_DESCRIPTION', 'ROOF_ENERGY_EFF'
        , 'ROOF_ENV_EFF', 'MAINHEAT_DESCRIPTION', 'MAINHEAT_ENERGY_EFF', 'MAINHEAT_ENV_EFF', 'MAINHEATCONT_DESCRIPTION', 'MAINHEATC_ENERGY_EFF'
        , 'MAINHEATC_ENV_EFF', 'LIGHTING_DESCRIPTION', 'LIGHTING_ENERGY_EFF', 'LIGHTING_ENV_EFF', 'MAIN_FUEL'
        , 'WIND_TURBINE_COUNT', 'HEAT_LOSS_CORRIDOR', 'UNHEATED_CORRIDOR_LENGTH', 'FLOOR_HEIGHT', 'PHOTO_SUPPLY'
        , 'SOLAR_WATER_HEATING_FLAG', 'MECHANICAL_VENTILATION', 'ADDRESS', 'LOCAL_AUTHORITY_LABEL', 'CONSTITUENCY_LABEL', 'POSTTOWN', 'CONSTRUCTION_AGE_BAND', 'LODGEMENT_DATETIME', 'TENURE', 'FIXED_LIGHTING_OUTLETS_COUNT',
        'LOW_ENERGY_FIXED_LIGHT_COUNT', 'UPRN', 'UPRN_SOURCE', 'DECILE', 'LOCAL_AUTHORITY_NAME', 'LOCAL_AUTHORITY_CODE', 'COUNTY_NAME']

    const contains = ['CURRENT_ENERGY_RATING', 'CURRENT_ENERGY_EFFICIENCY', 'PROPERTY_TYPE', 'TRANSACTION_TYPE', 'ENERGY_TARIFF'
        , 'FLOOR_LEVEL'
        , 'HOTWATER_DESCRIPTION'
        , 'FLOOR_DESCRIPTION'
        , 'WINDOWS_DESCRIPTION'
        , 'WALLS_DESCRIPTION'
        , 'SECONDHEAT_DESCRIPTION'
        , 'ROOF_DESCRIPTION'
        , 'MAINHEAT_DESCRIPTION'
        , 'MAINHEAT_ENERGY_EFF'
        , 'MAINHEATCONT_DESCRIPTION'
        , 'MAINHEATC_ENERGY_EFF'
        , 'MAIN_FUEL'
        , 'LOCAL_AUTHORITY_LABEL'
        , 'CONSTRUCTION_AGE_BAND'
        , 'TENURE', 'LOCAL_AUTHORITY_CODE'];

    const handleImport = ($event) => {

        const files = $event.target.files;
        if (files.length) {
            const file = files[0];
            setFileName($event.target.files[0].name);
            const reader = new FileReader();
            reader.onload = (event) => {
                selectedColumn.current = {};
                const worker = new Worker(new
                    URL('../worker/loader.js', import.meta.url), { type: "module" });
                worker.postMessage({ result: event.target.result, selectedColumn: null });
                worker.onmessage = function (e) {
                    if (e.data[0]) {
                        const wb = e.data[0].wb;
                        const sheets = wb.SheetNames;
                        setWbState(wb.Sheets[sheets[0]]);
                        setSpreadsheetRows(e.data[0].rows);
                        var selector = [];
                        for (let i = 0; i < e.data[0].columnNames.length; i++) {
                            selector.push(<option value={e.data[0].columnNames[i]}>{e.data[0].columnNames[i]}</option>)
                            console.log("loop")
                        }
                        setColumnSelection(selector);
                        setHtml(e.data[0].html);
                    }
                };
            }
            reader.readAsArrayBuffer(file);
        }
    }

    const handleConservation = async (event) => {
        setProgress(0);
        let tempMov = spreadsheetRows;
        let json = [];
        let out = [];

        if (method == "EPC" || method == "EPCALL") {
            for (let i = 0; i < tempMov.length; i++) {
                json[i] = {};
                json[i].id = i
                json[i].uprn = tempMov[i][selectedColumn.current.column1]
                json[i] = { ...{}, ...json[i], ...tempMov[i] }

            }
            const chunkSize = 100;
            for (let i = 0; i < json.length; i += chunkSize) {
                const chunk = json.slice(i, i + chunkSize);

                let result;
                if (method == "EPCALL") {
                    result = await propertyDbService.GetALLEPCByUPRN(chunk);
                } else {
                    result = await propertyDbService.GetEPCByUPRN(chunk);
                }
                out = out.concat(result.data);
                setProgress(((i + chunkSize) / json.length) * 100)
            }
            for (let i = 0; i < out.length; i++) { //convert the dates too
                if (out[i].INSPECTION_DATE?.length > 0) {
                    var d = new Date(out[i].INSPECTION_DATE);
                    //     console.log(d.toLocaleDateString('en-GB'));
                    out[i].INSPECTION_DATE = new Date(out[i].INSPECTION_DATE)
                    //     out[i].INSPECTION_DATE = d.toLocaleDateString('en-GB')
                }
                if (out[i].LODGEMENT_DATE?.length > 0) {
                    var d = new Date(out[i].LODGEMENT_DATE);
                    //   console.log(d.toLocaleDateString('en-GB'));
                    out[i].LODGEMENT_DATE = new Date(out[i].LODGEMENT_DATE)
                    //   out[i].LODGEMENT_DATE = d.toLocaleDateString('en-GB')
                }
                if (out[i].LODGEMENT_DATETIME?.length > 0) {
                    var d = new Date(out[i].LODGEMENT_DATETIME);
                    //   console.log(d.toLocaleString('en-GB'));
                    out[i].LODGEMENT_DATETIME = new Date(out[i].LODGEMENT_DATETIME)
                    //  out[i].LODGEMENT_DATETIME = d.toLocaleString('en-GB')
                }
                // if (numberArr.contains())
            }

            for (let i = 0; i < tempMov.length; i++) {
                console.log(out);
                var outy = out.find(obj => obj.id == i);//  Object.keys(out[i]).find(key => out[i]['id'] === i); ///////////ere
                
                if (outy){
                Object.entries(outy).map(([k, v]) => {
                    tempMov[i][k] = v;
                });
                }
            }

            for (let i = 0; i < out.length; i++) { //convert strings to numbers
                Object.entries(out[i]).map(([k, v]) => {

                    if (numberArr.includes(k.toString())) {
                        out[i][k] = parseInt(v) || null;
                    }
                });
            }

            setSpreadsheetRowsAppend(out);
        } else if (method == "Add") {
            let outAnother = [];
            for (let i = 0; i < tempMov.length; i++) {
                json[i] = { id: i, lookup: tempMov[i][selectedColumn.current.column1], ...addOptions, method: methodSub }
            }
            const chunkSize = 100;
            for (let i = 0; i < json.length; i += chunkSize) {
                const chunk = json.slice(i, i + chunkSize);
                let result = await propertyDbService.GetAddressesForPostcode(chunk);  //TODO
                // console.log(result);
                out = out.concat(result.data);
                setProgress(((i + chunkSize) / json.length) * 100)
            }
            for (let i = 0; i < out.length; i++) {
                Object.entries(out[i]).map(([k, v]) => {
                    tempMov[i][k] = v;
                });
                for (let x = 0; x < out[i]?.addresses?.length; x++) {
                    let obj = {};
                    Object.entries(out[i].addresses[x]).map(([k, v]) => {
                        obj[k] = v
                    });
                    outAnother.push(obj)
                }
            }

            //  out.forEach(function(v){ delete v.bad });
            setSpreadsheetRowsAppend(outAnother);
        }
        else if (method == "UPRN") {
            // var tempCount = 0;
            for (let i = 0; i < tempMov.length; i++) {  //tempMov.length  2 is now UPRN
                tempMov[i].LookupResult = tempMov[i][selectedColumn.current.column2]; //, add2: tempMov[i][selectedColumn.current.column2], add3: "", postcode: tempMov[i][selectedColumn.current.column3] }
            }
            // const chunkSize = 100;
            // for (let i = 0; i < json.length; i += chunkSize) {
            //     const chunk = json.slice(i, i + chunkSize);
            //     let result = await propertyDbService.PostRefiner(chunk, instance);  //TODO
            //     out = out.concat(result.data);
            //     setProgress(((i + chunkSize) / json.length) * 100)
            // }

            // for (let i = 0; i < out.length; i++) {
            //     if (out[i].ResultCode > -1 && out[i].Closeness > 70) {
            //         tempMov[i].LookupResult = out[i].UPRN;
            //     } else {
            //         tempCount++;
            //     }
            // }

            //  setProgress(100);
           // console.log(tempCount + ' of ' + tempMov.length + ' = ' + ((parseFloat(tempCount) / parseFloat(tempMov.length)) * 100) + '%');
            //  out.forEach(function(v){ delete v.bad });
            setSpreadsheetRowsAppend(tempMov);
        } else {
            for (let i = 0; i < tempMov.length; i++) {
                json[i] = { id: i, uprn: tempMov[i][selectedColumn.current.column1] }
            }
            const chunkSize = 100;
            for (let i = 0; i < json.length; i += chunkSize) {
                const chunk = json.slice(i, i + chunkSize);
                let result = await propertyDbService.findConservationAreaByUPRN(chunk);
                out = out.concat(result.data);
                setProgress(((i + chunkSize) / json.length) * 100)
            }
            for (let i = 0; i < tempMov.length; i++) {
                tempMov[i].LookupResult = out[i].LookupResult; // TODO
            }
            setSpreadsheetRowsAppend(tempMov);
        }
        setProgress(100)
        const worker = new Worker(new
            URL('../worker/loader.js', import.meta.url), { type: "module" });
        worker.postMessage({ rows: tempMov, selectedColumn: selectedColumn });
        worker.onmessage = function (e) {
            setSpreadsheetRows(e.data[0].rows);
            setHtml(e.data[0].html);
        };
    }

    const handleQueryExport = async () => {
        var obj = queryRows;
        //  console.log(obj);
        const payload = []
        for (let i = 0; i < obj.length; i++) {

            const payloadInner = []
            for (let x = 0; x < obj[i].rows.length; x++) {
                //  var name = obj[i].rows[x].dropdown;
                payloadInner.push({
                    query: obj[i].rows[x].query, field: obj[i].rows[x].dropdown,
                    comparitor: obj[i].rows[x].comparitor
                    , operator: (obj[i].rows[x].operator) ?? 'AND', id: obj[i].rows[x].id
                })
            }
            payload.push({ nestId: obj[i].nestId, rows: payloadInner })
        }
        //  console.log(payload);
        const wb = utils.book_new();
        const ws = utils.json_to_sheet([]);
        var returnedRowsData;
        setIsLoading(true);
        var results = await epcQuery.getEpcQueryData(JSON.stringify(payload));
        returnedRowsData = results.data;
        for (let x = 0; x < returnedRowsData.length; x++) {
            //  if((!returnedRowsData[x].CouncilTaxBand) && queryOptions.current?.CT){
            if (queryOptions.current?.CT) {
                let returnedData = await epcQuery.GetTaxCodeForEpc(returnedRowsData[x].POSTCODE, returnedRowsData[x].LMK_KEY);
                returnedRowsData[x].CouncilTaxBand = returnedData.data[0]?.taxBand;
            }

        }

        if (queryOptions.current?.UPRN) { // !returnedRowsData[x].UPRN_REFINED && 
            let out = [];
            let json = [];
            let tempCount = 0;
            for (let i = 0; i < returnedRowsData.length; i++) {  //tempMov.length
                json[i] = { add1: returnedRowsData[i]['ADDRESS'], add2: returnedRowsData[i]['ADDRESS2'], add3: "", postcode: returnedRowsData[i]['POSTCODE'], lmk: returnedRowsData[i]['LMK_KEY'] }
            }
            const chunkSize = 100;
            for (let i = 0; i < json.length; i += chunkSize) {
                const chunk = json.slice(i, i + chunkSize);
                let result = await propertyDbService.PostRefiner(chunk, instance);  //TODO
                //   console.log(result);
                out = out.concat(result.data);
            }

            for (let i = 0; i < out.length; i++) {
                if (out[i].ResultCode > -1 && out[i].Closeness > 70) {
                    returnedRowsData[i].UPRN_REFINED = out[i].UPRN;
                } else {
                    tempCount++;
                }
            }
            //  let returnedData = await propertyDbService.GetRefiner(returnedRowsData[x].ADDRESS1,returnedRowsData[x].ADDRESS2,"",returnedRowsData[x].POSTCODE,instance);
            //      console.log(returnedData.data)
            //     returnedRowsData[x].UPRN_REFINED = returnedData.data?.UPRN;
        }
        if (queryOptions.current?.CON) {
            let out = [];
            let json = [];
            let tempCount = 0;
            for (let i = 0; i < returnedRowsData.length; i++) {  //tempMov.length
                json[i] = { uprn: returnedRowsData[i]['UPRN_REFINED'] ?? returnedRowsData[i]['UPRN'], lmk: returnedRowsData[i]['LMK_KEY'] }
            }
            const chunkSize = 100;
            for (let i = 0; i < json.length; i += chunkSize) {
                const chunk = json.slice(i, i + chunkSize);
                let result = await propertyDbService.findConservationAreaByUPRN(chunk);
                out = out.concat(result.data);
                //  setProgress(((i + chunkSize) / json.length) * 100)
            }
            for (let i = 0; i < out.length; i++) {
                returnedRowsData[i].Conservation_Area = out[i].LookupResult;
            }


        }

        utils.sheet_add_json(ws, returnedRowsData, { origin: 'A1', skipHeader: false });
        utils.book_append_sheet(wb, ws, 'Report');
        writeFile(wb, 'Query Results.xlsx');
        setIsLoading(false);
        setQueryRowCount(-1);

    }

    const handleExport = async () => {
        let arr2 = [];
        //  console.log(spreadsheetRowsAppend);
        for (let i = 0; i < spreadsheetRows.length; i++) {
            var j = (spreadsheetRows[i].LookupResult)  //TODO
            arr2.push({ CA: j });
        }
        let wb1 = utils.book_new();
        let sheet = wbState;
        const [columns] = utils.sheet_to_json(sheet, { header: 1 });
        if (method == "EPC" || method == "EPCALL") {
            //   var arr ={};
            //   var index = 0
            var dom = [];
            var nonDom = [];
            var not = [];
            for (let x = 0; x < spreadsheetRowsAppend.length; x++) {
                if (spreadsheetRowsAppend[x].propType == "Dom") {
                    dom.push(spreadsheetRowsAppend[x]);
                } else if (spreadsheetRowsAppend[x].propType == "NonDom") {
                    nonDom.push(spreadsheetRowsAppend[x]);
                } else {
                    not.push(spreadsheetRowsAppend[x]);
                }
                if (spreadsheetRowsAppend[x].propType) {//TODO
                    //     index = x;
                }
                console.log("loop")
            }
            const wb = utils.book_new();
            const ws = utils.json_to_sheet([]);
            // console.log("got here")
            // utils.sheet_add_json(ws, spreadsheetRows, { origin: 'A1', skipHeader: false });

            if (dom.length > 0) {
                //   console.log("dom")
                utils.sheet_add_json(ws,
                    [["DOMESTIC"]], { origin: 'A1', skipHeader: true });
                utils.sheet_add_json(ws, dom, { origin: 'A2', skipHeader: false, dateNF: 'DD/MM/YYYY HH:mm' });
                //  if(!ws['!cols']) ws['!cols'] = [];
                //     ws['!cols'][0] = { wch: 17 };  //96 - 98
            }
            if (nonDom.length > 0) {
                //   console.log("nondom")
                utils.sheet_add_json(ws, [
                    ["NON DOMESTIC"]], { origin: { r: dom.length + 3, c: 0 }, skipHeader: true });
                utils.sheet_add_json(ws, nonDom, { origin: { r: dom.length + 4, c: 0 }, skipHeader: false });
            }
            if (not.length > 0) {
                utils.sheet_add_json(ws, [
                    ["NOT FOUND"]], { origin: { r: dom.length + nonDom.length + 6, c: 0 }, skipHeader: true });
                utils.sheet_add_json(ws, not, { origin: { r: dom.length + nonDom.length + 7, c: 0 }, skipHeader: false });
            }
            //  utils.sheet_add_json(sheet, [arr], { origin: { r: 0, c: columns.length }, skipHeader: true });
            //  utils.sheet_add_json(sheet, spreadsheetRowsAppend, { origin: { r: 1, c: columns.length } ,skipHeader: true});
            utils.book_append_sheet(wb, ws, 'Report');
            writeFile(wb, 'Query Results.xlsx');
        } else if (method == "Add" || method == "UPRN") {
            var sra = structuredClone(spreadsheetRowsAppend);
            if (addOptions?.CT) {  //CON
                var starter = 0;
                if (addOptions?.CON) starter = sra.length;
                if (addOptions?.ADD) starter += sra.length;
                for (let i = 0; i < sra.length; i++) {
                    let out = [];
                    let json = [];
                    let tempCount = 0;
                    let result = await propertyDbService.GetTaxCode(sra[i][selectedColumn.current.column3], sra[i][selectedColumn.current.column1]);
                    //out = out.concat(result.data);
                    console.log(result.data);
                    sra[i].Tax_Band = result.data[0].taxBand;
                    // sra[i].Conservation_Area = out[i].LookupResult;
                    setProgress(((i) / (sra.length + starter)) * 100)
                }
            }

            if (addOptions?.CON) {
                var starter = 0;
                if (addOptions?.CT) starter = sra.length;
                if (addOptions?.ADD) starter += sra.length;
                let out = [];
                let json = [];
                let tempCount = 0;
                for (let i = 0; i < sra.length; i++) {  //tempMov.length
                    json[i] = { uprn: sra[i].LookupResult }
                }
                const chunkSize = 100;
                for (let i = 0; i < json.length; i += chunkSize) {
                    const chunk = json.slice(i, i + chunkSize);
                    let result = await propertyDbService.findConservationAreaByUPRN(chunk);
                    out = out.concat(result.data);
                    setProgress(((starter + i + chunkSize) / (json.length + starter)) * 100)
                }
                for (let i = 0; i < out.length; i++) {
                    sra[i].Conservation_Area = out[i].LookupResult;
                }


            }

            if (addOptions?.ADD) {
                var starter = 0;
                if (addOptions?.CT) starter = sra.length;
                if (addOptions?.CON) starter += sra.length;
                let out = [];
                let json = [];
                let tempCount = 0;
                for (let i = 0; i < sra.length; i++) {  //tempMov.length
                    let result = await propertyDbService.GetAddressAditional(sra[i][selectedColumn.current.column3]);
                    console.log(result);
                    out = out.concat(result.data);
                    setProgress(((starter + i) / (json.length + starter)) * 100)
                }
                for (let i = 0; i < out.length; i++) {
                    Object.entries(out[i]).map(([k, v]) => {
                        sra[i][k] = v;
                    });

                }


            }

            const wb = utils.book_new();
            const ws = utils.json_to_sheet([]);
            utils.sheet_add_json(ws, sra, { origin: 'A1', skipHeader: false });
            utils.book_append_sheet(wb, ws, 'Report');
            writeFile(wb, 'Query Results.xlsx');
        }else if (method == "Conservation") {
            var sra = structuredClone(spreadsheetRowsAppend);
            if (addOptions?.LISTED) {  //CON
                var starter = 0;
                for (let i = 0; i < sra.length; i++) {
                  
                    let result = await propertyDbService.GetListed(sra[i][selectedColumn.current.column1]);
                    //out = out.concat(result.data);
                    console.log(result.data);
                    sra[i].listed_data = result.data;
                    // sra[i].Conservation_Area = out[i].LookupResult;
                    setProgress(((i) / (sra.length + starter)) * 100)
                }
            }
            const wb = utils.book_new();
            const ws = utils.json_to_sheet([]);
            utils.sheet_add_json(ws, sra, { origin: 'A1', skipHeader: false });
            utils.book_append_sheet(wb, ws, 'Report');
            writeFile(wb, 'Query Results.xlsx');
        }else {
            utils.sheet_add_json(sheet, [{ CA: 'Lookup Result' }], { origin: { r: 0, c: columns.length }, header: ["CA"], skipHeader: true });
            utils.sheet_add_json(sheet, arr2, { origin: { r: 1, c: columns.length }, skipHeader: true });
            utils.book_append_sheet(wb1, sheet, 'Report');
            writeFile(wb1, 'Report.xlsx');
        }

    }

    const handleQuery = async (event) => {
        var obj = queryRows;
        if (validate(obj).length > 0) return;
        const payload = []
        for (let i = 0; i < obj.length; i++) {
            const payloadInner = []
            for (let x = 0; x < obj[i].rows.length; x++) {
                payloadInner.push({
                    query: obj[i].rows[x].query, field: obj[i].rows[x].dropdown,
                    comparitor: obj[i].rows[x].comparitor
                    , operator: (obj[i].rows[x].operator) ?? 'AND', id: obj[i].rows[x].id
                })
            }
            payload.push({ nestId: obj[i].nestId, rows: payloadInner })
        }
        setIsLoading(true);
        epcQuery.getEpcQuery(JSON.stringify(payload))
            .then((result) => {
                setIsLoading(false);
                setQueryRowCount(result.data[0].count);

            }).catch(function (error) {
                setIsLoading(false);
            })


    }

    function validate(obj) {
        var errorTextH = [];
        for (let i = 0; i < obj.length; i++) {
            for (let x = 0; x < obj[i].rows.length; x++) {
                if (!(obj[i].rows[x].query) || obj[i].rows[x].query.length == 0)
                    errorTextH.push(<text>Please enter query text for all rows{'\n'}</text>)
                if (!(obj[i].rows[x].dropdown) || obj[i].rows[x].dropdown == 'Please select')
                    errorTextH.push(<text>Please select fields for all rows{'\n'}</text>)
                if (!(obj[i].rows[x].comparitor) || obj[i].rows[x].comparitor == '-')
                    errorTextH.push(<text>Please select comparitor for all rows{'\n'}</text>)
                if ((obj[i].rows[x].comparitor) && (obj[i].rows[x].comparitor == 'Contains' || obj[i].rows[x].comparitor == 'NotContains')
                    && !contains.includes(obj[i].rows[x].dropdown))
                    errorTextH.push(<text>'Contains' not available on {obj[i].rows[x].dropdown + '\n'}</text>)
            }
        }
        setErrorText(errorTextH);
        return errorTextH;

    }

    function handleOnchange(event) {
        var whatRow;
        var whatNest;
        var code;

        var multi = false;
        if (event.element) {
            whatRow = event.element?.id.substring(1);
            whatNest = event.element.ej2_instances[0].batchId
            code = event.element?.id.substring(0, 1);
            multi = true;
        } else {
            whatRow = event.target?.id.substring(1);
            whatNest = event.target?.getAttribute("batchid")
            code = event.target.id.substring(0, 1);
        }
        var control;
        if (code == "d") {
            control = 'dropdown'
        } else if (code == "i") {
            control = 'query'
        } else if (code == "o") {
            control = 'operator'
        } else if (code == "c") {
            control = 'comparitor'
        } else if (code == "n") {
            control = 'operator'
        } else if (code == "m") {
            control = 'comparitor'
        }

        if (multi) {
            setQueryRows((prev) => {
                var old = [...prev];
                old[whatNest].rows[whatRow]['query'] = ""
                for (let x = 0; x < event.value.length; x++) {
                    if (x > 0) old[whatNest].rows[whatRow]['query'] += ","
                    old[whatNest].rows[whatRow]['query'] += "'" + event.value[x].replaceAll("'", "''") + "'";
                }
                old[whatNest].rows[whatRow][control] = 'InDD';
                return old;
            });
        } else {
            setQueryRows((prev) => {
                var old = [...prev];
                old[whatNest].rows[whatRow][control] = event.target.value;
                return old;
            })
        }
        setQueryRowCount(-1);
    }

    const handleOnchangeLookup = (event) => {
        var selectedC = event.target.value;
        console.log(event.target.value)
        switch (event.target.id) {
            case "column1":
                selectedColumn.current.column1 = event.target.value
                break;
            case "column2":
                selectedColumn.current.column2 = event.target.value
                break;
            case "column3":
                selectedColumn.current.column3 = event.target.value
                break;

        }
        let tempMov = spreadsheetRows
        //   console.log(tempMov);
        const worker = new Worker(new
            URL('../worker/loader.js', import.meta.url), { type: "module" });
        worker.postMessage({ rows: tempMov, selectedColumn: selectedColumn });
        worker.onmessage = function (e) {
            setSpreadsheetRows(e.data[0].rows);
            setHtml(e.data[0].html);
        };
    }

    const handleOnchangeMethod = (event) => {
        setSpreadsheetRowsAppend([]);
        setProgress(101);
        // selectedColumn.current = {};
        setAddOptions({});
        queryOptions.current = {};
        setMethod(event.target.value);
        setMethodSub("")
    }

    const handleOnchangeMethodSub = (event) => {
        setMethodSub(event.target.value);
        console.log(event.target.value);
    }

    const checkChange = (e) => {
        const checked = e.target.checked;
        const checkedValue = e.target.value;
        setAddOptions(
            (prev) => {
                prev[checkedValue] = checked;
                return prev
            });
    }

    const checkChangeQuery = (e) => {
        const checked = e.target.checked;
        const checkedValue = e.target.value;
        queryOptions.current[checkedValue] = checked
    }

    const fieldDropdownOptions = (
        <>
            {fields.map((field) =>
                <option value={field}>{field}</option>
            )}
        </>
    )

    const operators = (
        <>
            <option value="AND">AND</option>
            <option value="OR">OR</option>
        </>
    )

    function addQuery(batchId) {
        var old = [...queryRows];
        var id = old[batchId].rows.length - 1
        old[batchId].rows.push({ id: id + 1 })
        setQueryRows(old);
        setQueryRowCount(-1);
    }

    function removeQuery(batchId, id) {
        var qr = [...queryRows];
        var batch = qr[batchId].rows.filter(el => !(el.id == id));
        if (batch.length == 0) {
            qr.splice(batchId, 1);
        } else {
            qr[batchId].rows = batch;
        }
        setQueryRows(qr);

        setQueryRowCount(-1);
    }

    function addNest() {
        var old = [...queryRows];
        old.push({ nestId: old.length, rows: [{ id: 0 }] })
        setQueryRows(old);

        setQueryRowCount(-1);
    }

    function rows() {
        console.log(queryRows)
        var map = [...queryRows]?.map((b, idx) => {
            return (
                
                <div className={"border border-dark rounded"} style={{ marginBottom: 35 }}>
                    {b.rows.map((r, no) => (
                        <>
                        {(idx > 0 && no == 0) &&
                    <div className="row"> 
                        {/* <div style={{ marginTop: "-85px", marginLeft: "-55px" }} className="row flex-nowrap"> */}
                        <div className="col"
                                                    style={{maxWidth:'150px',marginTop:"-42px",marginBottom:"-25px"}}
                                                    >
                        <select onChange={handleOnchange} className="form-select form-select-lg mb-3 ms-auto" 
                        // style={{ marginTop: "-85px", marginLeft: "-55px", width: "auto" }}
                                aria-label="Default select example" id={'n' + r.id} batchid={idx} >
                                {operators}
                            </select> 
                        </div>
                        
                    </div>
                    }
                            <div className="row align-items-center">
                                <>
                                <div className="col-sm-3">
                                        <div className="row no-wrap">
                                            
                                            {(no > 0 || idx > 0) &&
                                                <div className="" style={{width:'45px'}}
                                            >    
                                                <button className="btn-circle btn btn-danger btn-lg ms-auto" onClick={() => { removeQuery(idx, r.id) }} style={{  color: "white", borderRadius: "20px",margin:"15px" }}>  
                                                    {/* marginLeft: "30px", */}
                                                    <i className="fa fa-minus-circle" style={{}} />
                                                </button>
                                                {/* // width: "0px", */}
                                                </div>
                                            }
                                        
                                                {(r.id > 0)
                                                    &&
                                                    <div className="col"
                                                    style={{maxWidth:'150px'}}
                                                    >
                                                    <select onChange={handleOnchange} className="form-select form-select-lg mb-3 ms-auto"
                                                        aria-label="Default select example" id={'o' + r.id} batchid={idx} style={{ marginTop: "6px" }}
                                                        // style={{ marginTop: "6px", marginLeft: "30px", width: "auto" }}
                                                        >
                                                        {operators}
                                                    </select>
                                                    </div>
                                                    // <div style={{ width: "60px", marginLeft: "30px" }}></div>
                                                }
                                                

                                    </div>

                                     </div>

                                    <div className="col-sm-3">
                                    <select onChange={handleOnchange} className="form-select form-select-lg mb-3"
                                        aria-label="Default select example" id={'d' + r.id} batchid={idx} style={{ marginTop: "6px" }}>
                                        <option selected>Please select</option>
                                        {fieldDropdownOptions}
                                    </select>
                                    </div>
                                    
                                
                                <div className="col-sm-2">

                                    <select onChange={handleOnchange} className="form-select form-select-lg mb-3"
                                        aria-label="Default select example" id={'c' + r.id} batchid={idx} style={{ marginTop: "6px" }}>
                                        <option value="-">-</option>
                                        <option value="Equals">Equals</option>
                                        {(r.dropdown != null && contains.includes(r.dropdown)) ? <option value="Contains">Contains</option> : <></>}
                                        <option value="NotContains">Does not Contain</option>
                                        <option value="In">One of (values seperated by "," and encoled by '')</option>
                                        <option value="NotIn">Not one of (values seperated by ',')</option>
                                        <option value="Starts">Starts With</option>
                                        <option value="Greater">Is Greater Than</option>
                                        <option value="Less">Is Less Than</option>
                                        {(r.dropdown != null && contains.includes(r.dropdown)) ? <option value="InDD">One of items in Dropdown</option> : <></>}
                                    </select>

                                </div>
                                <div className="col-sm-3" style={{marginBottom:'10px'}}>
                                    {(r.dropdown != null && contains.includes(r.dropdown) && r.comparitor == "InDD") ?
                                        <div style={{ width: "300px" }}>
                                            <MultiSelectComponent id={'m' + r.id} batchId={idx} ref={(scope) => { mulObj = scope; }}
                                                dataSource={dataRemote}
                                                change={handleOnchange}
                                                showClearButton={false}
                                                showDropDownIcon={true}
                                                //     fields={checkFields} 
                                                fields={remoteFields}
                                                query={query(r.dropdown)}
                                                placeholder="Select Data"
                                                mode="CheckBox"
                                                showSelectAll={true}
                                                filterBarPlaceholder="Search Data"
                                                popupHeight="350px">
                                                <Inject services={[CheckBoxSelection]} />
                                            </MultiSelectComponent>
                                        </div>
                                        :
                                        <input type="text" onChange={handleOnchange} className="form-control "
                                            aria-describedby="field" placeholder="Enter text filter" id={'i' + r.id} batchid={idx} />
                                    }

                                </div>
                            </>
                            </div>
                        </>
                    )
                    )}
                    <div className="row align-items-center" style={{ marginLeft: "0px", marginBottom: "10px" }}>
                        <div className="row align-items-center" >
                            {/* <div className="col-sm-2"> */}
                            <button className="btn-circle btn-primary align-items-center btn" onClick={() => { addQuery(idx) }} style={{ color: "white", borderRadius: "20px", }}>
                                <i className="fa fa-plus-circle" />

                            </button>
                            {/* </div> */}
                        </div>
                    </div>
                </div>)
        })
        return (map)
    }

    function Spinner() {
        return (
            <>
                <div className="d-flex justify-content-center">
                    <div className="spinner-border text-warning" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </>
        )
    }

    const queryDiv = (
        <>
            <div className="row" style={{marginLeft:'2px',marginRight:'7px'}}>
                <div style={{ marginBottom: 15 }}>
                    {/* Contains queries enabled on {contains.map((m,idx) => (idx > 0) ? ', '+m:'' +m )} fields only */}
                </div>
                {rows()}
            </div>
            <div className="row" style={{marginLeft:'2px',marginRight:'7px'}}>
                <div className="border border-dark rounded" style={{ marginBottom: "20px" }}>
                    <div className="row " style={{ marginBottom: "20px", marginTop: "10px" }}>
                        <div className="col-md-6">
                            <div className="form-check form-check-inline ">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="UPRN" onChange={checkChangeQuery} />
                                <label className="form-check-label" htmlFor="inlineCheckbox1">Add or Refresh UPRN</label>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="CT" onChange={checkChangeQuery} />
                                <label className="form-check-label" htmlFor="inlineCheckbox1">Add or Refresh Council Tax Code - can take some time on large datasets</label>
                            </div>
                        </div>
                    </div>
                    <div className="row flex-nowrap" style={{ marginBottom: "20px" }}>
                        <div className="col-md-6">
                            <div className="form-check form-check-inline ">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="CON" onChange={checkChangeQuery} />
                                <label className="form-check-label" htmlFor="inlineCheckbox1">Add or Refresh Conservation Area</label>
                            </div>
                        </div>
                        <div className="col-md-6">

                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex">

                <div className="col flex-sm-shrink-0">
                    <button className="btn btn-primary" onClick={addNest} style={{ color: "white" }}>
                        + Nest
                    </button>
                </div>
                <div className="col flex-sm-shrink-0" style={{textAlign: "center"}}>
                    <button disabled={progress < 100} onClick={handleQuery} className="btn btn-primary ">
                        Query
                    </button>
                </div>
                <div className="col flex-sm-shrink-0">
                    <button disabled={(progress < 100 || queryRowCount < 1 || isLoading == true)} onClick={handleQueryExport} className="btn btn-primary float-end">
                        Export
                    </button>
                </div>
            </div>
            <div className="row" style={{ marginTop: 20 }}>
                <div className="col-md-12">
                    {errorText}
                    {
                        (isLoading == true) ? <Spinner /> :
                            <>
                                {(queryRowCount > -1) ? <>
                                    {queryRowCount} records returned {(queryRowCount > 0) ? <> press export to view </> : <></>}  </> : <></>}
                                {queryRowCount > 99999 ? <> {' (Max records reached)'}</> : <></>}
                            </>
                    }
                </div>


            </div>
        </>
    )

    const conservationDiv = (
        <>
            <div className="row" style={{marginBottom:"10px"}}>
                <div className="col-md-6">
                    <select onChange={handleOnchangeMethod} className="form-select form-select-lg mb-3" aria-label="Default select example"  >
                        <option selected>Select Lookup Method</option>
                        <option value="Conservation">Get Conservation Area using UPRN</option>
                        <option value="EPC">Get Latest EPC Data using UPRN</option>
                        <option value="EPCALL">Get ALL EPC Data using UPRN</option>
                        <option value="Add">Get Postal Addresses</option>
                        <option value="UPRN">Get Data for UPRN</option>
                        {/* <option value="Tenure">Tenure</option> */}
                    </select>
                </div>
                
                {
                    ((method == "Conservation" || method == "EPC" || method == "EPCALL") || (method == "Add") || (method == "UPRN")) ?
                        <div className="col-md-6">
                            <div className="input-group">
                                {/* <div className="custom-file"> */}
                                <input type="file" name="file" className="form-control" id="inputGroupFile" required onChange={handleImport}
                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                                {/* <label className="custom-file-label" htmlFor="inputGroupFile">{fileName}</label> */}
                                {/* </div> */}
                            </div>
                        </div>
                        : <div className="col-md-4"></div>
                }
            </div>

            {(columnSelection?.length > 0) ?
                <>
                    {(method == "Add") ? <>
                        <br />
                        <div className="row">
                            <div className="col-md-4">
                                <select onChange={handleOnchangeMethodSub} className="form-select form-select-lg mb-3" aria-label="Default select example"  >
                                    <option selected value="">Choose search field</option>
                                    <option value="Post">Use Postcode</option>
                                    <option value="County">Use County</option>
                                    <option value="LA">Use LA Area</option>
                                </select>
                            </div>
                        </div>
                        {/* <div className="row"> */}
                            <div className="row border border-dark rounded col-sm-12" style={{ marginBottom: "20px", marginLeft:"1px" }}>
                                <div className="col">
                                <div className="row flex-nowrap" >
                                    <div className="col text-center" style={{ marginTop: "10px" }}>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="IMD" onChange={checkChange} />
                                            <label className="form-check-label" htmlFor="inlineCheckbox1">Only IMD areas 1-3</label>
                                        </div>
                                    </div>
                                    <div className="col text-center" style={{ marginTop: "10px" }}>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="SU" onChange={checkChange} />
                                            <label className="form-check-label" htmlFor="inlineCheckbox1">Exclude addresses with a SU_Organisation_Flag of "Y"</label>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        {/* </div> */}
                    </> : <></>
                    }

                    <br />
                    <div className="row">
                        <div className="col-md-4">
                            {(method != "Add" || (method == "Add" && methodSub != "")) ?
                                <select onChange={handleOnchangeLookup} className="form-select form-select-lg mb-3" aria-label="Default select example" id="column1" >
                                    {(method == "UPRN") ? <option selected>Select Address (First Line) Column Name</option> : <option selected>Select Lookup Column name</option>}
                                    {columnSelection}
                                </select>
                                : <></>}
                        </div>
                        {(method == "UPRN") ? <>
                            <div className="col-md-4">
                                <select onChange={handleOnchangeLookup} className="form-select form-select-lg mb-3" aria-label="Default select example" id="column2" >
                                    <option selected>Select UPRN Column Name</option>
                                    {columnSelection}
                                </select>
                            </div>
                            <div className="col-md-4">
                                <select onChange={handleOnchangeLookup} className="form-select form-select-lg mb-3" aria-label="Default select example" id="column3" >
                                    <option selected>Select Postcode Column Name</option>
                                    {columnSelection}
                                </select>
                            </div>
                        </> : <></>}
                    </div>
                    {(method == "Conservation") ?
                        <>
                            <div className="border border-dark rounded" style={{ marginBottom: "20px" }}>
                                <div className="row flex-nowrap " style={{ marginBottom: "20px", marginTop: "20px" }}>
                                    <div className="col-md-6" style={{ marginLeft: "5px" }}>
                                        <div className="form-check form-check-inline ">
                                            <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="LISTED" onChange={checkChange} />
                                            <label className="form-check-label" htmlFor="inlineCheckbox1">Add Listed Building Area</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-check form-check-inline">
                                         
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </>
                        : <></>}
                    {(method == "UPRN") ?
                        <>
                            <div className="row border border-dark rounded" style={{ marginBottom: "20px" }}>
                                {/* <div className="col"> */}
                                {/* <div className="row " style={{ marginBottom: "20px", marginTop: "20px" }}> */}
                                    <div className="col-sm-6" style={{ marginLeft: "5px" }}>
                                        <div className="form-check form-check-inline ">
                                            <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="CON" onChange={checkChange} />
                                            <label className="form-check-label" htmlFor="inlineCheckbox1">Add Conservation Area</label>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="CT" onChange={checkChange} />
                                            <label className="form-check-label" htmlFor="inlineCheckbox1">Add Council Tax Code - can take some time on large datasets</label>
                                        </div>
                                    </div>
                                {/* </div> */}
                                {/* </div> */}
                                {/* <div className="row " style={{ marginBottom: "20px" }}> */}
                                    <div className="col-sm-6" style={{ marginLeft: "5px" }}>
                                        <div className="form-check form-check-inline ">
                                            <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="ADD" onChange={checkChange} />
                                            <label className="form-check-label" htmlFor="inlineCheckbox1">Add additional address data - Decile,LSOA etc.</label>
                                        </div>
                                    </div>
                                  
                                </div>
                               
                            
                        </>
                        : <></>}
                    <div className="row">
                        <div className="col-sm-6">
                            <button  disabled={!((progress == 100) ||
                                (method != "UPRN" && selectedColumn.current.column1) ||
                                (method == "UPRN" && selectedColumn.current.column1 && selectedColumn.current.column2 && selectedColumn.current.column3))} required onClick={handleConservation} className="btn btn-primary float-start">
                                Lookup Data <i className="fa"></i>
                            </button>
                        </div>
                        <div className="col-sm-6">
                            <button disabled={(progress != 100)} onClick={handleExport} className="btn btn-primary float-end">
                                Export
                            </button>
                        </div>
                    </div>
                </>
                : <></>}
        </>)

    function selection(event) {
        var selected = event.target.value;
        setSelectedMenu(selected);
        if (selected == "EPC") {
            //  var old = [];
            // var id = old[old.length-1].rows.length - 1
            //  old.push({rows:[{id:0,row:queryRow(0,0)}]});
            //  setQueryRows(old);
            setQueryRows((prev) => {
                var old = prev;
                if (old.length == 0)
                    old.push({ rows: [{ id: 0 }] });
                return old;
            })
            // var newValue = {nestId:0,rows:[{id:0,row:queryRow(0,0)}]}
            // setQueryRows([newValue])
            setQueryRowCount(-1);
        }
        setHtml([]);
        setColumnSelection([]);
    }

    return (
        <>
            <div className="row" style={{marginLeft:'2px',marginRight:'2px'}}>
                {/* <div className="container" style={{marginLeft:'5px',marginRight:'27px'}}> */}
                     {/* col-md-10 offset-1 */}
                    <div className="row">
                        <h1 className="col-md-12">
                            Happy Energy Data queries
                        </h1>
                        <div className="col-md-6">
                            {progress < 100 ? <ProgressBar animated now={progress} /> : null}
                        </div>
                        <div className="col-md-6">
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-md-5">
                            <select className="form-select form-select-lg mb-3" onChange={selection} aria-label="Default select example">
                                <option value="">Please select</option>
                                <option value="EPC">Data Query</option>
                                {/* <option value="EPCL">EPC Query Lookup</option> */}
                                <option value="Conservation">Data Lookup</option>
                            </select>
                        </div>
                        <div className="col-md-5">
                        </div>
                        <div className="col-md-2">
                        </div>
                    </div>
                    <br />
                    {(selectedMenu == "Conservation") ? conservationDiv : <></>}
                    {(selectedMenu == "EPC") ? queryDiv : <></>}


                    <div className="row" style={{ marginTop: "20px" }} >
                        <div className="col-md-12">
                            <div dangerouslySetInnerHTML={{ __html: html }} />
                        </div>
                    </div>
                {/* </div> */}

            </div>
        </>
    );
};

export default QueryPage;
