import React,{useState,useRef} from "react"; 
import { MsalProvider, AuthenticatedTemplate, useMsal, UnauthenticatedTemplate } from '@azure/msal-react'; 
//import ProgressBar from 'react-bootstrap/ProgressBar';
import logo from '../img/logo.png';
        
import { Toast, ToastContainer } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";



const Landing = () => {

    const navigate = useNavigate();
  
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const [showDD, setShowDD] = useState(false);
    const [number,setNumber] =useState();
    const [type,setType] =useState();
    const [toast, setToast] = useState({'show':false});
    
/* this worker is shared between drag-drop and file input element */
        
  /* when the worker sends back the HTML, add it to the DOM */
    console.log(activeAccount);
    

    function goToAudit(){
      console.log(type);
      console.log(number);
      if (!type || !number){
        setToast((old) =>{
          var prev = {...old};
          prev.show=true;
          prev.color = 'danger';
          prev.message = 'Please fill all fields';
    return prev;
    });
    }
    navigate({
      pathname: '/audit',
      search: '?im='+type.toLowerCase()+'&id='+number,
    });
  }

    return (
        <>
          <ToastContainer
            className="p-3"
            position={'top-center'}
            style={{ zIndex: 1 }}
            >
        
            <Toast onClose={() => setToast({'show':false}) } 
            show={toast.show} delay={3000} autohide bg={toast.color}>
            <Toast.Body>{toast.message}</Toast.Body>
            </Toast>
            </ToastContainer> 
          <div className="container">
          <center>
          <img src={logo} 
          style={{maxHeight:"170px", flex: "1", maxWidth: "100%", height: "auto", marginLeft:'auto', marginRight:'auto' }} alt=""></img> 
          <h2 style={{marginTop:50}}>Arrived here from a QR code? </h2>
          {(!showDD)?
          <button onClick={()=>{setShowDD(true)}} className="btn btn-primary float-center">
            Click Here
          </button>
          :<>
          <div className="row" style={{ marginTop: "15px",justifyContent: "center"}}>
              <div className="col-md-4" style={{textAlign:'start'}}>
                <span  style={{fontSize:20,textAlign:'start',alignSelf:'start'}}>Sticker Number</span>
              </div>
              <div className="col-md-4" style={{textAlign:'center'}}>
                <input type={'text'} 
                style={{textAlign:'center',borderRadius:5,borderColor:'lightgrey'}} 
                onChange={(v)=>setNumber(v.target.value)}></input>
              </div>
            </div>
            <div className="row" style={{ marginTop: "15px",justifyContent: "center"}}>
              <div className="col-md-4" style={{textAlign:'start'}}>
                <span className="input-label" style={{fontSize:20}}>Sticker Type</span>
              </div>
          <div className="col-md-4" style={{paddingLeft:22,paddingRight:22}}>
              <select className="form-select form-select-lg mb-3" 
              onChange={(v)=>setType(v.target.value)} value={type} aria-label="Default select example"
              >
                  <option value="">Please select</option>
                  <option value="SOL">UK SOL</option>
                  {/* <option value="EPCL">EPC Query Lookup</option> */}
                  <option value="ANGLO">Anglo</option>
                  <option value="TRIANCO">Trianco</option>
              </select>
          </div>
          </div>
          <div className="row" style={{ marginTop: "15px",justifyContent: "center",gap:20}}>
          <button onClick={()=>{setShowDD(false)}} className="btn btn-primary float-center col-md-2">
            Close
          </button>
          <button onClick={()=>{goToAudit()}} className="btn btn-primary float-center col-md-2">
            Go to proof
          </button>
          </div>
          </>
}
          </center>
          </div>
                    
         
        </>

    );
};

export default Landing;
