import React,{useRef,useState} from "react"; 
import { MsalProvider, AuthenticatedTemplate, useMsal, UnauthenticatedTemplate } from '@azure/msal-react'; 
import ExternalList from './ExternalList'
import logo from '../img/logo.png';
import {
    MDBCard,
    MDBCardImage,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBRow,
    MDBCol,
    MDBRipple
} from 'mdb-react-ui-kit';

const External = () => {
    const { instance } = useMsal();
    const [option,setOption] = useState();
    const activeAccount = instance.getActiveAccount();
    console.log(activeAccount);
    const landing =(
        <div className="row col-sm-12">
        <MDBRow className='row-cols-1 row-cols-md-3 g-4'>
<MDBCol>
<MDBCard className='h-100'>
<MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
  <MDBCardImage
    src={require('../img/OIG3.jpg')}
    height={350}
    alt='...'
    position='top'
  />
   <a>
  <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)' }}></div>
</a>
  </MDBRipple>
  <MDBCardBody>
    <MDBCardTitle>Active Measures</MDBCardTitle>
    <MDBCardText>
      View currently assiged active measures
    </MDBCardText>
    <button onClick={() => setOption(1)} className="my-link stretched-link">Go somewhere</button>
  </MDBCardBody>
  
</MDBCard>
</MDBCol>
<MDBCol>
<MDBCard className='h-100'>
  <MDBCardImage
    src={require('../img/asset.jpeg')}
    height={350}
    alt='...'
    position='top'

  />
  <MDBCardBody>
    <MDBCardTitle>History</MDBCardTitle>
    <MDBCardText>
      Check previous completed measures - Disabled
    </MDBCardText>
    <a href="#!" className="my-link stretched-link">Go somewhere</a>
  </MDBCardBody>
</MDBCard>
</MDBCol>
<MDBCol>
<MDBCard className='h-100'>
  <MDBCardImage
    src='https://mdbootstrap.com/img/new/standard/city/043.webp'
    height={350}
    alt='...'
    position='top'
  />
  <MDBCardBody>
    <MDBCardTitle>Something else</MDBCardTitle>
    <MDBCardText>
      
    </MDBCardText>
    <a href="#!" className="my-link stretched-link">Go somewhere</a>
  </MDBCardBody>
</MDBCard>
</MDBCol>
</MDBRow>
</div>
    )
    
    const output = (
        <>
        {(option == 1)?
            <ExternalList/>:
         landing
        }
        </>
    )
    
    return (
      <>
        <div className="container">
          
            <div className="col-sm-12">
            <center><h2>Welcome {activeAccount?.name}</h2></center> 
                <br/>    
            </div>
            
            {output}
        </div>
      </>
    );
};

export default External;
