import types from "../http_common";
import { loginRequest, protectedResources, apiRequest } from "../authConfig";


async function bearerToken(instance) {
  const account = instance.getActiveAccount()
  if (!account) {
    throw Error(
      'No active account! Verify a user has been signed in and setActiveAccount has been called.'
    )
  }

  const response = await instance.acquireTokenSilent({
    ...apiRequest,
    account: account,
  })
 // console.log(response);
  return response.accessToken
}


const getEpcQuery = payload => {
   // return http.get(`/property/conservation/UPRN?uprn=${uprn}`);
    return types.json().post(`query/epc`,payload);
  };
  
  const getEpcQueryData = payload => {
    // return http.get(`/property/conservation/UPRN?uprn=${uprn}`);
     return types.json().post(`query/epcData`,payload);
   };

   const getEpcDistinct = payload => {
    // return http.get(`/property/conservation/UPRN?uprn=${uprn}`);
     return types.json().post(`query/epcDistinct`,payload);
   };

  const get = () => {
    return types.json().get(`/`);
  };

  const getEpcXml = async (payload,instance) =>{
    var api = types.xml();
    const item = await bearerToken(instance);
    api.defaults.headers.common.Authorization = 'Bearer ' + item;
    return await api.post(`epcCalc`, payload);
  }

  const packInsert = async (payload,instance) =>{
    var api = types.json();
    const item = await bearerToken(instance);
    api.defaults.headers.common.Authorization = 'Bearer ' + item;
    return await api.post(`epcCalc/packInsert`, payload);
  }

  const GetTaxCodeForEpc = (postcode,lmk) =>{
    return types.json().get(`epc/taxband?postcode=`+postcode+`&lmk=`+lmk);
  }

  const packsGet = async (instance) =>{
    var api = types.json();
    const item = await bearerToken(instance);
    api.defaults.headers.common.Authorization = 'Bearer ' + item;
    return await api.get(`epcCalc/packsGet`);
  }

  const epcQuery = {
    packsGet,
    getEpcQuery,
    getEpcQueryData,
    get,
    getEpcDistinct,
    getEpcXml,
    GetTaxCodeForEpc,
    packInsert
  };
  
  export default epcQuery;