// /check.jsx 

import * as React from "react";
 
const SvgComponent = () => (
 <svg
   xmlns="http://www.w3.org/2000/svg"
   viewBox="0 0 24 24"
   fill="none"
   stroke="currentColor"
   strokeWidth={2}
   strokeLinecap="round"
   strokeLinejoin="round"
 >
   <path d="M20 6 9 17l-5-5" />
 </svg>
);
 
export default SvgComponent;