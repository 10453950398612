import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import xml2js from "xml2js";
import epcQuery from "../services/epcQuery";
import { useMsal } from '@azure/msal-react';
import axios from "axios";
import xmlDoc from "../xml/defs.xml";
import { DndProvider, useDrop, useDrag } from 'react-dnd';
import { pdf, BlobProvider } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import pdfFile from './pdfSAP'
import Multiselect from 'multiselect-react-dropdown';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import CreatableSelect from 'react-select/creatable';
import Toast from 'react-bootstrap/Toast';

import ToastContainer from 'react-bootstrap/ToastContainer';



const QueryPage = () => {
    const [toast, setToast] = useState({'show':false});
    const defObjState = useRef({});
    const fieldDropdownOptions2 = useRef({norm:[],create:[]})

    const [radioValue2, setRadioValue2] = useState('1');

    const radios = [
      { name: 'EPC Value', value: '1' },
      { name: 'EPC Field', value: '2' },
    ];

    const { instance } = useMsal();
    const [upgradeRows, setUpgradeRows] = useState([{ rows: [{ id: 0 }], upgradeRows: [{ id: 0 }] }])

    const defObj = useMemo(() => {
        //var xmlDef;
        axios.get(xmlDoc, {
            "Content-Type": "application/xml; charset=utf-8"
        })
            .then((response) => {
            //    console.log('Your xml file as string', response.data);
                var xmlDef = response.data;
                let parser = new xml2js.Parser();
                parser.parseString(xmlDef, function (err, result) {
                    defObjState.current = result;
                });
                var obj = fieldOptions();
                fieldDropdownOptions2.current.norm = obj.norm;
                fieldDropdownOptions2.current.create = obj.create;

            });

        epcQuery.packsGet(instance)
        .then((response) =>{
            var obj = structuredClone(response.data);
         //   console.log(obj);
            if (response.data.length >0){
                for(var x=0; x < obj.length; x++)
                {
                    for(var y=0; y < obj[x].rows.length;y++)
                    {
                        let ops = getOptions(obj[x].rows[y].dropdown);
                        for (let o=0;o<ops.length;o++){
                            ops[o].id = y;
                            ops[o].groupId = x;
                        }
                        obj[x].rows[y].options = ops;
                        if (ops.length > 0) 
                        {
                            let arr = obj[x].rows[y].ids.split(',');
                            obj[x].rows[y].selectedList =[]
                            for(let a=0;a<arr.length;a++){
                                var result = ops.find(obj => {
                                    return obj.value == arr[a];
                                })
                                result.id = y;
                                result.groupId = x;
                                
                                obj[x].rows[y].selectedList.push(result)
                            }
                        }
                    }
                    for(let y=0; y< obj[x].upgradeRows.length;y++)
                    {
                        let ops = getOptions(obj[x].upgradeRows[y].dropdown);
                        for (let o=0;o<ops.length;o++){
                            ops[o].id = y;
                            ops[o].groupId = x;
                        }
                        obj[x].upgradeRows[y].options = ops;
                        if (obj[x].upgradeRows[y].comparitor == "Creatable") 
                        {
                            let arr = obj[x].upgradeRows[y].query.split(' ');
                            obj[x].upgradeRows[y].selectedList =[]
                            for(let a=0;a<arr.length;a++){
                                // var result = ops.find(obj => {
                                //     return obj.value == arr[a];
                                // })
                                obj[x].upgradeRows[y].selectedList.push({value:arr[a],label:arr[a]})
                            }
                            
                        }else{
                            if (ops.length > 0) 
                        {
                            let arr = obj[x].upgradeRows[y].ids.split(',');
                            obj[x].upgradeRows[y].selectedList =[]
                            for(let a=0;a<arr.length;a++){
                                var result = ops.find(obj => {
                                    return obj.value == arr[a];
                                })
                                result.id = y;
                                result.groupId = x;
                                obj[x].upgradeRows[y].selectedList.push(result)
                            }
                        }
                        }


                    }
                }
            }
   //         console.log(obj);
            setUpgradeRows(obj);
        })
    }, [])

    const options = ['Property-Type', 'Built-Form', 'Multiple-Glazed-Proportion', 'Multiple-Glazing-Type', 'PVC-Window-Frames', 'Glazing-Gap', 'Door-Count', 'Insulated-Door-Count',
        'Percent-Draughtproofed', 'Fixed-Lighting-Outlets-Count', 'Low-Energy-Fixed-Lighting-Outlets-Count', 'Open-Fireplaces-Count', 'Conservatory-Type', 'Construction-Age-Band', 'Floor-Construction',
        'Floor-Insulation', 'Floor-Insulation-Thickness', 'Floor-Heat-Loss', 'Roof-Construction', 'Roof-Insulation-Location', 'Roof-Insulation-Thickness', 'Wall-Construction', 'Wall-Insulation-Type',
        'Wall-Dry-Lined', 'Wall-Insulation-Thickness', 'Party-Wall-Construction', 'Main-Heating-Number', 'Main-Heating-Category', 'Main-Fuel-Type', 'Main-Heating-Control', 'Main-Heating-Data-Source', 'SAP-Main-Heating-Code',
        'Heat-Emitter-Type', 'Main-Heating-Fraction', 'Water-Heating-Code', 'Water-Heating-Fuel', 'Immersion-Heating-Type', 'Cylinder-Size', 'Cylinder-Insulation-Type', 'Cylinder-Insulation-Thickness',
        'Meter-Type', 'Mains-Gas', 'Peak-Power', 'Orientation', 'Pitch', 'Overshading', 'PV-Connection'];

    function getOptions(field, value) {
        field = field?.replaceAll('-', '');
        var options = [];
        if (!field) return false;
       // console.log(field);
        var obj = defObjState.current['xs:schema']['xs:simpleType'];
        if (field == "MechanicalVentilation") field = "Ventilation";
        if (field == 'MultipleGlazingType') field = 'GlazingType'
        if (field == 'FloorHeatLoss') field = 'HeatLossFloor'
        if (field == 'ConstructionAgeBand') field = 'ConstructionDate'
        if (field == 'RoofConstruction') field = 'RoofType'
        if (field == 'WindTurbinesTerrainType') field = 'TerrainTypeCode'
        if (field == 'MainFuelType' || field == 'WaterHeatingFuel') field = 'HeatingFuelType'
        if (field == 'WallConstruction') field = 'MainWallConstruction'
        if (field == 'WallInsulationType') field = 'WallInsulationCode'
        if (field == 'ImprovementCategory') field = 'RecommendationCategoryCode'
        if (field == 'ImprovementType') field = 'ImprovementMeasureCode'
        if (field == 'SolarWaterHeating' || field == 'WallThicknessMeasured' ||
            field == 'WallDryLined' || field == 'HasFGHRS') field = 'Flag'

        for (let x = 0; x < obj.length; x++) {
            if (obj[x]['$']['name'].includes(field)) {
                if (obj[x]['xs:restriction'][0]['xs:enumeration']) {
                    for (let z = 0; z < obj[x]['xs:restriction'][0]['xs:enumeration'].length; z++) {
                        if (value == obj[x]['xs:restriction'][0]['xs:enumeration'][z]['$']['value']) {
                        } else {
                            options.push(
                                { name: obj[x]['xs:restriction'][0]['xs:enumeration'][z]['xs:annotation'][0]['xs:documentation'][0], value: obj[x]['xs:restriction'][0]['xs:enumeration'][z]['$']['value'] }
                            )
                        }
                    }
                }
            }
        }
        return options;
    }

    function removeQuery(groupId,id,type) {
        var typeIn = 'rows'
        if(type == 'upgrade'){ typeIn = 'upgradeRows' }
        var qr = [...upgradeRows];
        var batch = qr[groupId][typeIn].filter( el => !(el.id == id));
        if (batch.length==0) {
      //  qr.splice(groupId, 1);
        }else{
            qr[groupId][typeIn] = batch;
        }
        setUpgradeRows(qr);
    }

    function removeGroup(groupId) {
        var qr = [...upgradeRows];
        qr.splice(groupId, 1);
        setUpgradeRows(qr);
    }


    function addQuery(groupId,type) {
        var typeIn = 'rows'
        if(type == 'upgrade'){ typeIn = 'upgradeRows' }
            var old = [...upgradeRows];
            var id = old[groupId][typeIn].length - 1
            old[groupId][typeIn].push({ id: id + 1,operator:'AND' })
            setUpgradeRows(old);
    }

    async function packInsert(){
        var payload = [...upgradeRows]

        var err = '';
         for(let x =0; x<payload.length; x++){
            for(let y =0; y < payload[x].rows.length;y++){
                if(!payload[x].rows[y].dropdown || payload[x].rows[y].dropdown == 'Please Select') {err+=' Please ensure all dropdowns are populated'; break;}
                if(!payload[x].rows[y].query) {err+=' Please ensure all fields are populated' ; break;}
            }
            for(let y =0; y < payload[x].upgradeRows.length;y++){
                if(!payload[x].upgradeRows[y].dropdown || payload[x].upgradeRows[y].dropdown == 'Please Select') {err+=' Please ensure all dropdowns are populated'; break;}
                if(!payload[x].upgradeRows[y].query) {err+=' Please ensure all fields are populated' ; break;}
                if(payload[x].upgradeRows[y].comparitor=='Creatable'){
                    if(!payload[x].upgradeRows[y].query || payload[x].upgradeRows[y].query.split(',').length == 2|| 
                     payload[x].upgradeRows[y].query.split(',').length == 4 || payload[x].upgradeRows[y].query.split(',').length == 6){
                        {err+=' The ' + payload[x].upgradeRows[y].dropdown + ' value must either be a single value or a sum or two or three existing fields' ; break;}
                     }
                }
            }
        if (err) break;
         } //creatable
         if (err){
            setToast((old) =>{
                var prev = {...old};
                prev.show=true;
                prev.color = 'danger';
                prev.message =err;
            return prev;
            });
            return false;
         }

        await epcQuery.packInsert(payload,instance)
        setToast((old) =>{
            var prev = {...old};
            prev.show=true;
            prev.color = 'success';
            prev.message ='Saved!';
        return prev;
        });
    }

    function handleOnchange(event,type) {

       // console.log(event);

        var whatRow;
        var whatGroup;
        var code;

        var multi = false;
        if (event.element) {
            whatRow = event.element?.id.substring(1);
            whatGroup = event.element.ej2_instances[0].groupId
            code = event.element?.id.substring(0, 1);
            multi = true;
        } else {
            whatRow = event.target?.id.substring(1);
            whatGroup = event.target?.getAttribute("groupId")
            code = event.target.id.substring(0, 1);
        }
        var control;
        if (code == "d") {
            control = 'dropdown'
        } else if (code == "i") {
            control = 'query'
        } else if (code == "o") {
            control = 'operator'
        } else if (code == "c") {
            control = 'comparitor'
        } else if (code == "n") {
            control = 'operator'
        } else if (code == "m") {
            control = 'comparitor'
        }  else if (code == "z") {
            control = 'query'
        } 

        var typeIn = 'rows'
        if(type == 'upgrade') {typeIn = 'upgradeRows'}

        setUpgradeRows((prev) => {
            var old = [...prev];
            if (code=="x"){
                old[whatGroup]['name'] = event.target.value; 
            }else{
            old[whatGroup][typeIn][whatRow][control] = event.target.value;
            if(code=="d"){
                var options = getOptions(event.target.value);
                var comparitor = 'Equals'
                for (let x = 0; x < options.length; x++) {
                    comparitor = 'InDD'
                    options[x].groupId = whatGroup;
                    options[x].id = whatRow;
                }
                old[whatGroup][typeIn][whatRow]['comparitor'] = comparitor;
                old[whatGroup][typeIn][whatRow]['options'] = options;
                old[whatGroup][typeIn][whatRow]['selectedList'] = [];
                old[whatGroup][typeIn][whatRow]['query'] = ""
            }
            }
            
            return old;
        })
    }

    const fieldDropdownOptions = (
        <>
            {options.map((field) =>
                <option value={field}>{field}</option>
            )}
        </>
    )

    function fieldOptions(){
        var items = {norm:[],create:[]};
        for(let x =0; x < options.length; x++)
        {
            if (getOptions(options[x]).length > 0){}else{
            items.create.push({ value: options[x], label: options[x] })
            items.norm.push(<option value={options[x]}>{options[x]}</option>)
            }
        }
        return items;
    }

    
    const operators = (
        <>
            <option value="AND">AND</option>
            <option value="OR">OR</option>
        </>
    )

    function msChange(selectedList, selectedItem,type) {
       // console.log(selectedItem)
        var whatGroup = selectedItem.groupId;
        var whatRow = selectedItem.id;
        var control = 'comparitor'
        var typeIn = 'rows'
        if (type == 'upgrade') {typeIn='upgradeRows'}
        setUpgradeRows((prev) => {
            var old = [...prev];
            old[whatGroup][typeIn][whatRow]['query'] = ""
            old[whatGroup][typeIn][whatRow]['ids'] = ""
            for (let x = 0; x < selectedList.length; x++) {
                if (x > 0) old[whatGroup][typeIn][whatRow]['query'] += ","
                if (x > 0) old[whatGroup][typeIn][whatRow]['ids'] += ","
                old[whatGroup][typeIn][whatRow]['query'] += selectedList[x].name.replaceAll("'", "''") ;
                old[whatGroup][typeIn][whatRow]['ids'] +=  selectedList[x].value.replaceAll("'", "''") ;
            }
         //   old[whatGroup][typeIn][whatRow][control] = 'InDD';
            old[whatGroup][typeIn][whatRow]['selectedList'] = selectedList;
            return old;
        });
    }

    function addGroup() {
        var old = [...upgradeRows];
        old.push({groupId:old.length,rows:[{id:0}],upgradeRows:[{id:0}]})
        setUpgradeRows(old);
    } 

    const formatCreateLabel = (inputValue) => `add... ${inputValue}`;

    function creatableChange(row,group,z){
       // console.log(z);

        var whatGroup = group;
        var whatRow = row;
        var control = 'comparitor'
        var typeIn = 'upgradeRows'
       // if (type == 'upgrade') {typeIn='upgradeRows'}
        setUpgradeRows((prev) => {
            var old = [...prev];
            old[whatGroup][typeIn][whatRow]['query'] = ""
            old[whatGroup][typeIn][whatRow]['ids'] = ""
            for (let x = 0; x < z.length; x++) {
                if (x > 0) old[whatGroup][typeIn][whatRow]['query'] += " "
                old[whatGroup][typeIn][whatRow]['query'] +=  z[x].value.replaceAll("'", "''") ;
            }
            old[whatGroup][typeIn][whatRow][control] = 'Creatable';
            old[whatGroup][typeIn][whatRow]['selectedList'] = z;
            return old;
        });
    }

    const components = {
        DropdownIndicator: null,
      };

    function rows() {
     //   console.log(upgradeRows)
        var map =
            <>
                <div className="row">
                                    <ToastContainer
                                    className="p-3"
                                    position={'top-center'}
                                    style={{ zIndex: 1 }}
                                    >
                             
                                    <Toast onClose={() => setToast({'show':false}) } show={toast.show} delay={3000} autohide bg={toast.color}>
                                    {/* <Toast.Header>
                                         <img
                                        src="holder.js/20x20?text=%20"
                                        className="rounded me-2"
                                        alt=""/>
                                        <strong className="me-auto">Bootstrap</strong>
                                        <small>11 mins ago</small>  
                                    </Toast.Header>*/}
                                    <Toast.Body>{toast.message}</Toast.Body>
                                    </Toast>
                                    </ToastContainer>  
                </div>
                {[...upgradeRows]?.map((b, idx) => {
                    return (<div className={"border border-dark rounded"} style={{ marginBottom: 35, width: '100%' }}>
                        <div>
                        <div className="row flex-nowrap" style={{marginTop:10,marginBottom:15}}>
                            <div className="col-md-3"style={{marginTop:5}}>
                               <h5> Upgrade Pack Name:</h5>
                            </div>
                            <div className="col-md-3" >
                                <input  type="text" onChange={handleOnchange} className="form-control"
                                aria-describedby="field" placeholder="Enter pack name" id={'x' + b.id} groupId={idx} value={(b.name)?b.name:""}/>
                            </div>
                            <div className="col-md-6 float-end" style={{}}>
                                    <button className="btn align-items-center btn float-end" onClick={() => { removeGroup(idx) }} style={{ color: "white", borderRadius: "20px", }}>
                                    <i className="fa fa-trash fa-2x" style={{color:"red"}} aria-hidden="true"></i>
                                    </button>
                            </div>
                                {/* <button type="button" className="btn btn-default btn-sm"> 
                                    <FontAwesomeIcon icon="icon-trash"></FontAwesomeIcon>
                                 </button>  */}
                        </div>

                        
                            Criteria
                        {b.rows.map((r, no) => (
                            <>                   
                                  <div className="row align-items-center flex-nowrap">
                                    {(no > 0 ) ?
                                        (no==0)?
                                        <button className="btn-circle btn btn-danger btn-lg" onClick={() => { removeQuery(idx, r.id) }} style={{ marginRight: "35px", marginLeft: "30px", color: "white", borderRadius: "20px", marginTop: "-8px" }}>
                                            <i className="fa fa-minus-circle" style={{}} />
                                        </button>
                                        :
                                        <button className="btn-circle btn btn-danger btn-lg" onClick={() => { removeQuery(idx, r.id) }} style={{ marginLeft: "30px", color: "white", borderRadius: "20px", marginTop: "-8px" }}>
                                            <i className="fa fa-minus-circle" style={{}} />
                                        </button>
                                        :
                                        <div style={{ width: "0px", marginLeft: "72px" }}></div>
                                    }
                                    {/* {(idx > 0 && no == 0) ? <>  <div style={{ marginTop: -85, marginLeft: "-55px" }} className="row flex-nowrap">
                                        <select onChange={handleOnchange} className="form-select form-select-lg mb-3" style={{ width: "auto" }}
                                            aria-label="Default select example" id={'n' + r.id} groupId={idx} >
                                            {operators}
                                        </select>
                                    </div>
                                    </> : null
                                    } */}
                                    <div className="col-sm-auto">
                                        {(r.id > 0)
                                            ?
                                            <select onChange={handleOnchange} className="form-select form-select-lg mb-3"
                                                aria-label="Default select example" id={'o' + r.id} groupId={idx} style={{ marginTop: "6px", marginLeft: "30px", width: "auto" }}
                                                value={r.operator}>
                                            {operators}
                                            </select>
                                            : <div style={{ width: "60px", marginLeft: "30px" }}></div>
                                        }
                                    </div>
                                    <div className="col-sm-auto" >
                                        <select onChange={handleOnchange} className="form-select form-select-lg mb-3"
                                            aria-label="Default select example" id={'d' + r.id} groupId={idx} style={{ marginTop: "6px" }}
                                            value = {r.dropdown}>
                                            <option>Please select</option>
                                            {fieldDropdownOptions}
                                        </select>
                                    </div>
                                    <div className="col-sm-auto">
                                        <select value={r.comparitor} onChange={handleOnchange} className="form-select form-select-lg mb-3"
                                            aria-label="Default select example" id={'c' + r.id} groupId={idx} style={{ marginTop: "6px" }}>
                                            {(r.options?.length==0) ? 
                                            <> 
                                            <option value="Equals">=</option> 
                                            <option value="Greater">{">"}</option>
                                            <option value="Less">{"<"}</option>
                                            <option value="EqualsField">{"= Field"}</option> 
                                            <option value="GreaterField">{"> Field"}</option>
                                            <option value="LessField">{"< Field"}</option>
                                            </>
                                            :
                                            <>
                                            <option value="InDD">One of</option>
                                            <option value="NotInDD">Not One of</option>
                                            </>
                                            }
                                        </select>
                                    </div>
                                    <div className="col-sm-auto">

                                        <div style={{ width: "300px" }}>
                                            {(r.dropdown != null && (r.options?.length>0) ) ?
                                                <Multiselect
                                                    id={'m' + r.id} groupId={idx}
                                                    options={r.options} // Options to display in the dropdown
                                                    selectedValues={r?.selectedList} // Preselected value to persist in dropdown
                                                    onSelect={msChange} // Function will trigger on select event
                                                    onRemove={msChange} // Function will trigger on remove event
                                                    displayValue="name" // Property name to display in the dropdown options
                                                />
                                                :(!(r.comparitor?.includes('Field')))?
                                                <input value={r.query} type="text" onChange={handleOnchange} className="form-control "
                                                    aria-describedby="field" placeholder="Enter value" id={'i' + r.id} groupId={idx} />:
                                                
                                                    <select value={r.query} onChange={handleOnchange} className="form-select form-select-lg mb-3"
                                            aria-label="Default select example" id={'z' + r.id} groupId={idx} style={{ marginTop: "6px" }}>
                                            <option >Please select</option>
                                            {fieldDropdownOptions2.current.norm}
                                        </select>
                } 
                                        </div>
                                    </div>
                                    {/* <div className="col-sm-auto">
                                    <ButtonGroup className='float-end'>
                                        {radios.map((radio, idx) => (
                                        <ToggleButton
                                            key={idx}
                                            id={`radio2-${idx}`}
                                            type="radio"
                                            variant={idx % 2 ? 'outline-primary' : 'outline-success'}
                                            name="radio2"
                                            value={radio.value}
                                            checked={radioValue2 === radio.value}
                                            onChange={(e) => setRadioValue2(e.currentTarget.value)}
                                        >
                                            {radio.name}
                                        </ToggleButton>
                                        ))}
                                    </ButtonGroup>
                                    </div> */}
                                </div>
                            </>
                        )
                        )}
                        </div>
                         <div className="row align-items-center" style={{ marginLeft: "20px", marginBottom: "10px" }}>
                            <div className="row align-items-center" >
                                <button className="btn-circle btn-primary align-items-center btn" onClick={() => { addQuery(idx) }} style={{ color: "white", borderRadius: "20px", }}>
                                    <i className="fa fa-plus-circle" />
                                </button>
                            </div>
                        </div>
                        <hr/>
                        <div className="bg-light text-dark">
                        Upgrages
                        {b.upgradeRows.map((r, no) => (
                            <>
                                <div className="row align-items-center flex-nowrap">
                                    {(no > 0) ?
                                        (no==0)?
                                        <button className="btn-circle btn btn-danger btn-lg" onClick={() => { removeQuery(idx, r.id,'upgrade') }} style={{ marginRight: "35px", marginLeft: "30px", color: "white", borderRadius: "20px", marginTop: "-8px" }}>
                                            <i className="fa fa-minus-circle" style={{}} />
                                        </button>
                                        :
                                        <button className="btn-circle btn btn-danger btn-lg" onClick={() => { removeQuery(idx, r.id,'upgrade') }} style={{ marginLeft: "30px", color: "white", borderRadius: "20px", marginTop: "-8px" }}>
                                            <i className="fa fa-minus-circle" style={{}} />
                                        </button>
                                        :
                                        <div style={{ width: "0px", marginLeft: "72px" }}></div>
                                    }
                                    {/* {(idx > 0 && no == 0) ? <>  <div style={{ marginTop: -85, marginLeft: "-55px" }} className="row flex-nowrap">
                                        <select onChange={handleOnchange} className="form-select form-select-lg mb-3" style={{ width: "auto" }}
                                            aria-label="Default select example" id={'n' + r.id} groupId={idx} >
                                            {operators}
                                        </select>
                                    </div>
                                    </> : null
                                    } */}
                                    <div className="col-sm-auto">
                                        {(r.id > 0)
                                            ?
                                            <select onChange={e => handleOnchange(e,'upgrade')} className="form-select form-select-lg mb-3"
                                                aria-label="Default select example" id={'o' + r.id} groupId={idx} style={{ marginTop: "6px", marginLeft: "30px", width: "auto" }}>
                                                <option value="AND">AND</option>
                                            </select>
                                            : <div style={{ width: "60px", marginLeft: "30px" }}></div>
                                        }
                                    </div>
                                    <div className="col-sm-auto" >
                                        <select onChange={e => handleOnchange(e,'upgrade')} className="form-select form-select-lg mb-3"
                                            aria-label="Default select example" id={'d' + r.id} groupId={idx} style={{ marginTop: "6px" }} value={r.dropdown}>
                                            <option >Please select</option>
                                            {fieldDropdownOptions}
                                        </select>
                                    </div>
                                    <div className="col-sm-auto">
                                        <select onChange={e => handleOnchange(e,'upgrade') } className="form-select form-select-lg mb-3"
                                            aria-label="Default select example" id={'c' + r.id} groupId={idx} style={{ marginTop: "6px" }}>
                                            <option value="Equals">=</option>
                                        
                                        </select>
                                    </div>
                                    <div className="col-sm-auto">
                                        <div style={{ width: "300px" }}>
                                            {(r.options?.length>0) ?
                                                <Multiselect
                                                    id={'m' + r.id} groupId={idx}
                                                    options={r.options} // Options to display in the dropdown
                                                    selectedValues={r?.selectedList} // Preselected value to persist in dropdown
                                                    onSelect={(a,b) => msChange(a,b,'upgrade')} // Function will trigger on select event
                                                    onRemove={(a,b) => msChange(a,b,'upgrade')} // Function will trigger on remove event
                                                    displayValue="name" // Property name to display in the dropdown options
                                                    selectionLimit={1}
                                                />
                                                :
                                                <CreatableSelect 
                                                isMulti
                                                components={components}
                                                options={fieldDropdownOptions2.current.create}
                                                formatCreateLabel={formatCreateLabel}
                                                onChange={(val) => creatableChange(r.id,idx,val)}
                                                value={r.selectedList}
                                                />
                                                // <input type="text" onChange={(e) => handleOnchange(e,'upgrade')} className="form-control "
                                                //     aria-describedby="field" placeholder="Enter value" id={'i' + r.id} groupId={idx} />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                        ))}
                        <div className="row align-items-center" style={{ marginLeft: "20px", marginBottom: "10px" }}>
                            <div className="row align-items-center" >
                                <button className="btn-circle btn-primary align-items-center btn" onClick={() => { addQuery(idx,'upgrade') }} style={{ color: "white", borderRadius: "20px", }}>
                                    <i className="fa fa-plus-circle" />
                                </button>
                            </div>
                        </div>
                        </div>
                    </div>)
                })}
                </>
        return (map)
    }

    return (

        <div >
            <div className="row">
                <h2 className="col-md-6">
                    Upgrade Packs
                </h2>
                <div className="col-md-6">
                    <button className="btn btn-primary float-end" onClick={packInsert} style={{ color: "white" }}>
                        Save
                    </button>
                
                </div>
            </div>
            <br />
            <div className="row">
                <div className="col-md-5">
                </div>
                <div className="col-md-5">
                </div>
                <div className="col-md-2">
                </div>
            </div>

            <br />
            <div className="row">
                {rows()}
            </div>
            <div className="row flex-nowrap">
               
                <div className="col-md-4">
                    <button className="btn btn-primary" onClick={addGroup} style={{ color: "white" }}>
                        + Pack
                    </button>
                </div>
                <div className="col-md-2">
                    
                </div>
                <div className="col-md-2">
                 
                </div>
                <div className="col-md-2">
                    
                </div>
            </div> 



        </div>

    );
};
export default QueryPage;