import { AuthenticatedTemplate } from "@azure/msal-react";
import { NavigationBar,NavigationBarExt } from "./NavigationBar";
//import logo from '../img/logo.png';

export const PageLayout = (props) => {
    return (
        <>
            <NavigationBar/>
            <br />
            <h5>
            <br/>
                
            </h5>
            {/* <center><img src={logo} alt=""></img></center>  */}
            <br />
            {props.children}
            <br />
            <AuthenticatedTemplate>
                <footer>
                    <center>
                       
                    </center>
                </footer>
            </AuthenticatedTemplate>
        </>
    );
}

export const PageLayoutExt = (props) => {
    return (
        <>
            <NavigationBarExt/>
            <br />
            <h5>
            <br/>
                
            </h5>
            {/* <center><img src={logo} alt=""></img></center>  */}
            <br />
            {props.children}
            <br />
            <AuthenticatedTemplate>
                <footer>
                    <center>
                       
                    </center>
                </footer>
            </AuthenticatedTemplate>
        </>
    );
}