import { LogLevel } from "@azure/msal-browser";

/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
export const msalConfig = {
    auth: {
        clientId: '86b7370e-f3d8-4407-a5bb-4e4235156300',  //'def3d4ca-f2b3-436f-88ae-b9e9f77e4cf8', // This is the ONLY mandatory field that you need to supply.
        authority: 'https://login.microsoftonline.com/183c62a2-b0a2-4466-b883-e8c463760227', // Defaults to "https://login.microsoftonline.com/common"
        redirectUri: '/', // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
        postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
        clientCapabilities: ["CP1"],
    },
    cache: {
        cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {

        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
    },
};
/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const protectedResources = {
    apiEpc: {
        endpoint: "https://happyengine.azurewebsites.net/api/epcCalc",
        scopes: {
            read: [ "api://21220c5c-1741-45aa-a52a-56730bf8ac50/epc.Read" ],
            write: [ "api://21220c5c-1741-45aa-a52a-56730bf8ac50/epc.ReadWrite" ]
        }
    }
}

export const apiRequest = {
    url: 'https://happyengine.azurewebsites.net/api/epcCalc',
    scopes: ['api://21220c5c-1741-45aa-a52a-56730bf8ac50/epc.ReadWrite'],
  }

  export const usrRequest = {
        scopes: ["user.Read"]
  }



/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    scopes: [...protectedResources.apiEpc.scopes.read, ...protectedResources.apiEpc.scopes.write]
};

/**
 * An optional silentRequest object can be used to achieve silent SSO
 * between applications by providing a "login_hint" property.
 */
export const silentRequest = {
    scopes: ["openid", "profile"],
    loginHint: "example@domain.net"
};