import { Document, Page, Text, View, StyleSheet, Image, Link } from "@react-pdf/renderer";
import { Buffer } from "buffer";
import logo from '../img/logo.png';
import logo2 from '../img/logo2.png';
import logo3 from '../img/logo3.png';
// Create style
const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    color: "black",
  },
  section: {
    margin: 10,
    padding: 10,
  },
  header: {
    fontSize: 30,
    fontWeight: 900,
  },
  paragraph: {
    fontsize: '10',
  },
  tableRow: {
    marginLeft: "10px",
    marginTop: "10px",
    flexDirection: "row",
    float: "left",
    height: 100,
  },
  viewer: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    height: window.innerHeight,
  },
  image: {
    width: 150,
    height: 65,
    marginLeft: 20,
    marginRight: 20,
  },
  imageList: {
    width: 300,
    marginTop: 20,
    marginLeft: "auto",
    marginRight: "auto",
  },
});

// Create Document Component
function BasicDocument(props) {

  console.log("fast car")

  return new Promise((resolve) => {

    var json = props;

    console.log(json);

    var items = json?.eachItem?.map((item,i) => {
    
      return (
        <>
        <Text style={{ marginTop: '20px', fontSize: '15' }}>Single Improvement: {item.text}</Text>
        <Text style={{ marginTop: '5px', fontSize: '15' }}>SAP Rating: {item.SAP}</Text>
        <Text style={{ marginTop: '5px', fontSize: '15' }}>CO2 Emissions: {item.Co2Em}</Text>
        <Text style={{ marginTop: '5px', fontSize: '15' }}>Heat Demand per M2: {item.HeatDem}</Text>
        </>
      )
    })

    var doc = (<>
      <Document>
        {/*render a single page*/}
        <Page size="A4" style={styles.page}>
          <View style={styles.tableRow}>
          
            <Image
              style={styles.image}
              src={logo}
              key="2"
            />
           
          </View>
          <View style={styles.section}>
            <Text style={styles.header}>Property SAP upgrade report</Text>
          
            <Text style={{ marginTop: '20px', fontSize: '15' }}>Property UPRN: {json?.propertyUPRN}</Text>
            <Text style={{ marginTop: '5px', fontSize: '15' }}>Property Address: {json?.propertyAddress}</Text>
            <Text style={{ marginTop: '20px', fontSize: '15' }}>Starting SAP Rating: {json?.startData.sap}</Text>
            <Text style={{ marginTop: '5px', fontSize: '15' }}>Starting CO2 Emissions: {json?.startData.Co2Em}</Text>
            <Text style={{ marginTop: '5px', fontSize: '15' }}>Starting Heat Demand per M2: {json?.startData.HeatDem}</Text>
            <Text style={{ marginTop: '20px', fontSize: '15' }}>Ending SAP Rating: {json?.endData.sap}</Text>
            <Text style={{ marginTop: '5px', fontSize: '15' }}>Ending CO2 Emissions: {json?.endData.Co2Em}</Text>
            <Text style={{ marginTop: '5px', fontSize: '15' }}>Ending Heat Demand per M2: {json?.endData.HeatDem}</Text>
            
            
          
           
          </View>
          {(json?.eachItem[0])?
            <>
          <View style={styles.section} key="1">
            {items}
          </View>
          </>: <></>}
          <View>
         
          </View>
        </Page>
      </Document>
    </>)
    setTimeout(() => {
      resolve(
        doc
      )
    }, 500)
  })
}

export default BasicDocument;