import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import xml2js from "xml-js";
import xml2js2 from "xml2js";
import epcQuery from "../services/epcQuery";
import { useMsal } from '@azure/msal-react';
import xmlDoc from "../xml/defs.xml";
import axios from "axios";
import { DndProvider, useDrop, useDrag } from 'react-dnd';
import { pdf, BlobProvider } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import pdfFile from './pdfSAP'

const QueryPage = () => {
    const { instance } = useMsal();
    const [xmlObj, setXmlObj] = useState([]);
    const [htmlSel, setHtmlSel] = useState("Input");
    const [isLoading, setIsLoading] = useState("false");
    const [defObjState, setDefObjState] = useState();
    const [SAP, setSAP] = useState({ loading: "", current: { sap: "N/A" }, calculated: { sap: "N/A" }, required: 0 });
    const [refreshKey, setRefreshKey] = useState(0);
    const [pdfData, setPdfData] = useState({});
    const [list, setList] = useState({
        board: [], list: []
    });
    const [{ isOver }, drop] = useDrop(() => ({
        accept: "row",
        drop: (item) => addRow(item, 'IN'),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    }
    ))
    const [{ isOver2 }, drop2] = useDrop(() => ({
        accept: "row1",
        drop: (item) => addRow(item, 'OUT'),
        collect: (monitor) => ({
            isOver2: !!monitor.isOver(),
        }),
    }
    ))

    var robsString = '';

    function Row({ text, id }) {
        const [{ isDragging }, drag] = useDrag(() => ({
            type: "row",
            item: { id: id },
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
            }),
        }))
        return (
            <div ref={drag} width={"150px"} style={{ opacity: isDragging ? "0%" : "100%", backgroundColor: "#ABBAEA" }}>{text}</div>
        )
    };

    function Row1({ text, id, backgroundColorIn }) {
        const [{ isDragging }, drag] = useDrag(() => ({
            type: "row1",
            item: { id: id },
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
            }),
        }))
        return (
            <div ref={drag} width={"150px"} style={{ opacity: isDragging ? "0%" : "100%", backgroundColor: backgroundColorIn }}>
                {text}
            </div>
        )
    };

    function addRow(item, dir) {
        if (dir === "OUT") {
            setList((list1) => {
                const droppedRows = list1.board.filter(row => item.id == row.id);
                var prev = [];
                prev.list = [...list1.list, droppedRows[0]]
                prev.board = list1.board.filter(function (board2) {
                    return board2.id !== item.id
                })
                return prev;
            }
            )
        } else {
            setList((list1) => {
                const droppedRows = list1.list.filter(row => item.id == row.id);
                var prev = [];
                prev.board = [...list1.board, droppedRows[0]]
                prev.list = list1.list.filter(function (list2) {
                    return list2.id !== item.id
                })
                return prev;
            }
            )
        }
    }

    const rows = list?.list?.map(row => <Row text={row.text} id={row.id} />)
    const boardRows = list?.board?.map(row => <Row1 text={row.text} id={row.id} backgroundColorIn={row.backgroundColorIn} />)

    var xmlDef;
    const defObj = useMemo(() => {
        axios.get(xmlDoc, {
            "Content-Type": "application/xml; charset=utf-8"
        })
            .then((response) => {
              //  console.log('Your xml file as string', response.data);
                xmlDef = response.data;
                var result2 = xml2js.xml2js(xmlDef, {compact: true});
              //  let parser = new xml2js.Parser();
                //parser.parseString(xmlDef, function (err, result) {
                    setDefObjState(result2);
                //    console.log(result2)
                //});

            });
    }, [xmlObj])

    function getOptions(field, value) {
        var options = [];
        var obj = defObjState['xs:schema']['xs:simpleType'];
        if (field == "MechanicalVentilation") field = "Ventilation";
        if (field == 'MultipleGlazingType') field = 'GlazingType'
        if (field == 'FloorHeatLoss') field = 'HeatLossFloor'
        if (field == 'ConstructionAgeBand') field = 'ConstructionDate'
        if (field == 'RoofConstruction') field = 'RoofType'
        if (field == 'WindTurbinesTerrainType') field = 'TerrainTypeCode'
        if (field == 'MainFuelType' || field == 'WaterHeatingFuel') field = 'HeatingFuelType'
        if (field == 'WallConstruction') field = 'MainWallConstruction'
        if (field == 'WallInsulationType') field = 'WallInsulationCode'
        if (field == 'ImprovementCategory') field = 'RecommendationCategoryCode'
        if (field == 'ImprovementType') field = 'ImprovementMeasureCode'
        if (field == 'SolarWaterHeating' || field == 'WallThicknessMeasured' ||
            field == 'WallDryLined' || field == 'HasFGHRS') field = 'Flag'

        checkUpgrades(field, value);

        for (let x = 0; x < obj.length; x++) {
            if (obj[x]['_attributes']['name'].includes(field)) {
                if (obj[x]['xs:restriction']['xs:enumeration']) {
                    for (let z = 0; z < obj[x]['xs:restriction']['xs:enumeration'].length; z++) {
                        if (value == obj[x]['xs:restriction']['xs:enumeration'][z]['_attributes']['value']) {
                            options.push(
                                <option selected value={obj[x]['xs:restriction']['xs:enumeration'][z]['_attributes']['value']}>{obj[x]['xs:restriction']['xs:enumeration'][z]['xs:annotation']['xs:documentation']['_text']}</option>
                            )
                        } else {
                            options.push(
                                <option value={obj[x]['xs:restriction']['xs:enumeration'][z]['_attributes']['value']}>{obj[x]['xs:restriction']['xs:enumeration'][z]['xs:annotation']['xs:documentation']['_text']}</option>
                            )
                        }
                    }
                }
            }
        }
        return options;
    }

    function clearPartOptions() {
        setList((prev) => {
            prev.list = prev.list.filter(function (list2) {
                return list2.id !== "Roof*" || list2.id !== "Roof!"
            })
            return prev;
        })
    }
    
    function checkUpgrades(field, values,comparitor,operator,query) {

        var temp = structuredClone(xmlObj[0]?.data);
        var valuesArr = values.toString().split(',');
        var result = false;
        var fieldArr;
       // console.log(comparitor)
       if(comparitor?.includes('Field')){
            fieldArr = findInObjectReturn(temp,query)
            if(comparitor?.includes('Less')){
                if (parseInt(findInObjectReturn(temp,field)[0]?._text) < parseInt(fieldArr[0]?._text)){
                    return true;
                }
            }

            if(comparitor?.includes('Greater')){
                if (parseInt(findInObjectReturn(temp,field)[0]?._text) > parseInt(fieldArr[0]?._text)){
                    return true;
                }
            }

            if(comparitor?.includes('Equal')){
                if (parseInt(findInObjectReturn(temp,field)[0]?._text) == parseInt(fieldArr[0]?._text)){
                    return true;
                }
            }
       }

       if(comparitor == 'Equals'){
            if(parseInt(findInObjectReturn(temp,field)[0]?._text) == parseInt(query)) return true;
       }

       if(comparitor == 'Greater'){
            if(parseInt(findInObjectReturn(temp,field)[0]?._text) > parseInt(query)) return true;
       }

       if(comparitor == 'Less'){
            if(parseInt(findInObjectReturn(temp,field)[0]?._text) < parseInt(query)) return true;
       }



        if(comparitor == 'InDD'){
        for (let x =0;x<valuesArr.length;x++){
        //    console.log(valuesArr[x])
            result = findInObject(field,valuesArr[x],temp,false)
            if(result == true) break;
        }
     //   console.log(result)
        return result;
        }

        if(comparitor == 'NotInDD'){
            result = true;
        for (let x =0;x<valuesArr.length;x++){
            result = findInObject(field,valuesArr[x],temp,false)
            if (result) {
                result = false;
                break;
            }
        }
        return result;
        }

        return result;
       
    }

    const visibleTodos =  useMemo( async () => {
        if (xmlObj[0]?.data['RdSAP-Report']) {
            setXmlObj((prev) => {
                prev[0].fields = [];
                if (!prev[0].outputHtml) {
           //         console.log("oh no")
                    prev[0].outputHtml = [];
                }
                return prev;
            })
         //   console.log(xmlObj[0])
            var readings = xmlObj[0].data['RdSAP-Report']['SAP-Data']['SAP-Property-Details'];
            getHTML(readings, "", "IN");
        }
        var packs = await epcQuery.packsGet(instance).catch(function (error) {
            // setIsLoading("error");
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
            
        });;
      //  console.log(packs);
        setList((list1)=>{
                list1.list = [];
                list1.board = [];
            for (let p=0;p <packs.data.length;p++){   
                let applicable = true;
                for(let x=0;x<packs.data[p].rows.length;x++){
                    if(!applicable && packs.data[p].rows[x].operator == 'AND') continue
                    if(applicable && packs.data[p].rows[x].operator == 'OR') break;
                    applicable = checkUpgrades(packs.data[p].rows[x].dropdown,packs.data[p].rows[x].ids,packs.data[p].rows[x].comparitor,packs.data[p].rows[x].operator,packs.data[p].rows[x].query);
            //        console.log(applicable + ' - ' + packs.data[p].rows[x].dropdown + ' - '+ packs.data[p].rows[x].ids)
                }
                //console.log(applicable + ' - ' + packs.data[p].rows[x].dropdown + ' - '+ packs.data[p].rows[x].ids)
                if(applicable){
                    list1.list.push({
                        id: packs.data[p].name,
                        text: packs.data[p].name,
                        backgroundColorIn: "#ABBAEA",
                        data: packs.data[p]
                    })
                }
            }
            return list1;
        })
    }, [xmlObj]);


    const handleImport = ($event) => {
        setXmlObj((prev) => {
            var old = [...prev];
            old = [];
            return old;
        });
        setList((prev) => {
            var old = [];
            old = { board: [], list: [] };
            return old;
        });
        const files = $event.target.files;
        setXmlObj([{ 'fileName': $event.target.files[0].name, 'fields': [], 'data': [] }]);
        let parser = new xml2js2.Parser();
        if (files.length) {
            const file = files[0];
            const reader = new FileReader();
            
            reader.onload = (event) => {
             //   parser.parseString(event.target.result, function (err, result) {
            //var result = xml2js.xml2js(event.target.result,{compact:true})
                    var result = xml2js.xml2js(event.target.result,{compact:true})
                 //   console.dir(result);
             //       console.log('Done');
                    setXmlObj((prev) => {
                        var old = [...prev];
                        old[0].data = result;
                    //    old[0].data2 = result2;
                        old[0].outputHtml = [];
                //        console.log(old);
                        setHtmlSel([])
                        return old;
                    });
             //   });
            }
            reader.readAsText(file);
            reader.onloadend = (event) => {
            }
        }
    }

    function applyTempUpgrade(itteration, combine,tempIn) {
        let y = 0;
        let temp = structuredClone(tempIn);
        if (!combine) y = itteration
        for (let z = y; z < ((itteration) + 1); z++) {
            var value;           
            for(let u =0;u<list.board[z].data.upgradeRows.length;u++){
                let ur = list.board[z].data.upgradeRows[u];

                if (ur.comparitor == 'Creatable'){
                    value = ur.query.split(' ')
                    if (value.length > 1){ // calc 
                        var total = 0;
                        
                        if (value.length > 1){
                            total = eval (findInObjectReturn(temp,value[0])[0]._text +' '+ value[1] +' '+ findInObjectReturn(temp,value[2])[0]._text)
                        }
                        if (value.length > 3){
                            total = eval (findInObjectReturn(temp,value[0])[0]._text +' ' + value[1] + ' ' + findInObjectReturn(temp,value[2])[0]._text + ' ' +
                                value[3] + ' ' + findInObjectReturn(temp,value[4])[0]._text)
                        }
                        
                        value = total;
                    }else{
                        if(value[0].length > 3){
                            value = findInObjectReturn(temp,value[0])[0]._text;
                        }else{
                            value = value[0];
                        }
                    } 

                }else{
                    value = ur.ids;
                }
                
             //   console.log({'_text':value.toString()});
             //   console.log(ur.dropdown);
                temp = updateInObjectReturn(temp,ur.dropdown,value.toString());
           //     console.log(temp);
            }
            //     case "4KWPV":
            //         if (temp['RdSAP-Report']['SAP-Data'][0]['SAP-Property-Details'][0]["SAP-Energy-Source"][0]["Photovoltaic-Supply"][0]["PV-Arrays"])// 
            //         {
            //             temp['RdSAP-Report']['SAP-Data'][0]['SAP-Property-Details'][0]["SAP-Energy-Source"][0]["Photovoltaic-Supply"][0]["PV-Arrays"].push(
            //                 {
            //                     "PV-Array": [{
            //                         "Peak-Power": ["4"],
            //                         "Orientation": ["5"],
            //                         "Pitch": ["2"],
            //                         "Overshading": ["1"],
            //                         "PV-Connection": ["2"]
            //                     }]
            //                 })
            //         } else {
            //             temp['RdSAP-Report']['SAP-Data'][0]['SAP-Property-Details'][0]["SAP-Energy-Source"][0]["Photovoltaic-Supply"] = [];
            //             temp['RdSAP-Report']['SAP-Data'][0]['SAP-Property-Details'][0]["SAP-Energy-Source"][0]["Photovoltaic-Supply"].push(
            //                 {
            //                     "PV-Arrays": [{
            //                         "PV-Array": [{
            //                             "Peak-Power": ["4"],
            //                             "Orientation": ["5"],
            //                             "Pitch": ["2"],
            //                             "Overshading": ["1"],
            //                             "PV-Connection": ["2"]
            //                         }]
            //                     }]
            //                 }
            //             )
            //         }
            //         break;
            //     default:
            // }
            setList((prev) => {
                var ll = list;
                ll.board[z].backgroundColorIn = "green";
                return ll;
            })
            setIsLoading("calc");
            setRefreshKey(currentKey => currentKey + 1);
        }
      //  console.log(temp);
        return temp;
    }

      function findInObject(keyName,value, object,yeyney) {     
        for (var key in object) {
          if (key === keyName) {
            if(object[key]._text = value.toString()){
                yeyney=true;
                return yeyney
            }
          } else if (typeof object[key] === "object" && object[key] !== null) {
            yeyney = findInObject(keyName, value, object[key],yeyney);
          } else {
          //  yeyney = object[key];
          }
        }
        return yeyney;
      }

      function findInObjectReturn(object,originalKey,matches =[]) {
        if(object != null) {
            if(Array.isArray(object)) {
                for(let arrayItem of object) {
                    findInObjectReturn(arrayItem, originalKey, matches);
                }
            } else if(typeof object == 'object') {
                for(let key of Object.keys(object)) {
                    if(key == originalKey) {
                        matches.push(object[key]);
                    } else {
                        findInObjectReturn(object[key], originalKey, matches);
                    }
                }
            }
        }
        return matches;
      }

      function updateInObjectReturn(object,originalKey,newVal,matches =[]) {
        if(object != null) {
            if(Array.isArray(object)) {
                for(let arrayItem of object) {
                    updateInObjectReturn(arrayItem, originalKey, newVal,matches);
                }
            } else if(typeof object == 'object') {
                for(let key of Object.keys(object)) {
                    if(key == originalKey) {
                       // matches.push(object[key]);
                        object[key]._text = newVal;
                    } else {
                        updateInObjectReturn(object[key], originalKey,newVal, matches);
                    }
                }
            }
        }
        return object;
      }

    async function handleCalculate() {
        setPdfData((prev) => {
            prev = {};
            return prev;
        });
        for (let d = 0; d < list.board.length; d++) {
            setList((prev) => {
                prev.board[d].backgroundColorIn = "#ABBAEA";
                return prev;
            })
        }
        setRefreshKey(currentKey => currentKey + 1);
        var builder = new xml2js2.Builder();
        let parser = new xml2js2.Parser();
        var result1;
        var result;
        var temp = structuredClone(xmlObj[0].data);
        if (list.board.length > 0) {
            
            var run = false;
            for (let x = 0; x < list.board.length; x++) {
               if (!run && SAP.current.sap == "N/A") {
                   x = -1;
                   run = true;
                    setIsLoading("current");
                 } else {
                    temp = structuredClone(applyTempUpgrade(x, true,temp));
               //    console.log(xml2js.js2xml(temp,{compact: true}));
               }
              //  temp =[];
                result1 = await epcQuery.getEpcXml(xml2js.js2xml(temp,{compact: true}), instance).catch(function (error) {
                    setIsLoading("error");
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                });
              //  console.log(result1);
                result = await new Promise((resolve, reject) => parser.parseString(result1.data, (err, result) => {
                    if (err) reject(err);
                    else resolve(result);
                }));
                if (x == -1) {
                    setSAP((prev) => {
                        prev.current.sap = result["RdSAP-Report"]["Energy-Assessment"][0]["Energy-Use"][0]["Energy-Rating-Current"][0]
                        prev.current.Co2Em = result["RdSAP-Report"]["Energy-Assessment"][0]["Energy-Use"][0]["CO2-Emissions-Current"][0]
                        prev.current.HeatDem = (parseFloat(result["RdSAP-Report"]["Energy-Assessment"][0]["Renewable-Heat-Incentive"][0]["Space-Heating-Existing-Dwelling"][0]) /
                            parseFloat(result["RdSAP-Report"]["Energy-Assessment"][0]["Property-Summary"][0]["Total-Floor-Area"][0]))
                        return prev;
                    })
                    setIsLoading("true");
                } else {
                    setSAP((prev) => {
                        prev.calculated.sap = result["RdSAP-Report"]["Energy-Assessment"][0]["Energy-Use"][0]["Energy-Rating-Current"][0]
                        return prev;
                    })
                    setIsLoading("true");
                }
                if (x == 0) {
                    setList((prev) => {
                        prev.board[x].SAP = result["RdSAP-Report"]["Energy-Assessment"][0]["Energy-Use"][0]["Energy-Rating-Current"][0]
                        prev.board[x].Co2Em = result["RdSAP-Report"]["Energy-Assessment"][0]["Energy-Use"][0]["CO2-Emissions-Current"][0]
                        prev.board[x].HeatDem = (parseFloat(result["RdSAP-Report"]["Energy-Assessment"][0]["Renewable-Heat-Incentive"][0]["Space-Heating-Existing-Dwelling"][0]) /
                            parseFloat(result["RdSAP-Report"]["Energy-Assessment"][0]["Property-Summary"][0]["Total-Floor-Area"][0]))
                        return prev;
                    })
                }
                setRefreshKey(currentKey => currentKey + 1);
                if (x > -1 && parseInt(result["RdSAP-Report"]["Energy-Assessment"][0]["Energy-Use"][0]["Energy-Rating-Current"][0]) >= parseInt(SAP.required)) {
                    setSAP((prev) => {
                        prev.calculated.sap = result["RdSAP-Report"]["Energy-Assessment"][0]["Energy-Use"][0]["Energy-Rating-Current"][0]
                        prev.calculated.Co2Em = result["RdSAP-Report"]["Energy-Assessment"][0]["Energy-Use"][0]["CO2-Emissions-Current"][0]
                        prev.calculated.HeatDem = (parseFloat(result["RdSAP-Report"]["Energy-Assessment"][0]["Renewable-Heat-Incentive"][0]["Space-Heating-Existing-Dwelling"][0]) /
                            parseFloat(result["RdSAP-Report"]["Energy-Assessment"][0]["Property-Summary"][0]["Total-Floor-Area"][0]))
                        return prev;
                    })
                    await calcPDF(x);
                //    console.log(result["RdSAP-Report"]["Energy-Assessment"][0]["Energy-Use"][0]["Energy-Rating-Current"][0] + " > " + SAP.required);
                    break;
                } else {
                    if (x == (list.board.length) - 1) {
                        // fail
                    //    await calcPDF(x);
                    await calcPDF(x);
                    }
                }
            }
        } else {
            setIsLoading("calc");
            var xml = xml2js.js2xml(temp,{compact: true});
            result1 = await epcQuery.getEpcXml(xml, instance).catch(function (error) {
                setIsLoading("error");
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
            });
            if (result1) {
                result = await new Promise((resolve, reject) => parser.parseString(result1.data, (err, result) => {
                    if (err) reject(err);
                    else resolve(result);
                }));
                setSAP((prev) => {
                    prev.current.sap = result["RdSAP-Report"]["Energy-Assessment"][0]["Energy-Use"][0]["Energy-Rating-Current"][0]
                    prev.calculated.sap = result["RdSAP-Report"]["Energy-Assessment"][0]["Energy-Use"][0]["Energy-Rating-Current"][0]
                    return prev;
                })
                setXmlObj((prev) => {
                    var old = [...prev];
                    old[0].result = result;
                    old[0].outputHtml = [];
               //     console.log(old);
                    return old;
                });
            }
        }
       var readings = result['RdSAP-Report']['Energy-Assessment'];
       getHTML(readings, "", "OUT");
        setIsLoading("false");
    }

    async function calcPDF(to) {
        let parser = new xml2js2.Parser();
        for (let x = 1; x < ((to) + 1); x++) //first one has been calced
        {
            temp = structuredClone(xmlObj[0].data);
            var temp = applyTempUpgrade(x, false,temp);
          //  var builder = new xml2js.Builder();
            var result1 = await epcQuery.getEpcXml(xml2js.js2xml(temp,{compact: true}), instance).catch(function (error) {
                setIsLoading("error");
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
            });
            
            var result = await new Promise((resolve, reject) => parser.parseString(result1.data, (err, result) => {
                if (err) reject(err);
                else resolve(result);
            }));
            setList((prev) => {
                prev.board[x].SAP = result["RdSAP-Report"]["Energy-Assessment"][0]["Energy-Use"][0]["Energy-Rating-Current"][0]
                prev.board[x].Co2Em = result["RdSAP-Report"]["Energy-Assessment"][0]["Energy-Use"][0]["CO2-Emissions-Current"][0]
                prev.board[x].HeatDem = (parseFloat(result["RdSAP-Report"]["Energy-Assessment"][0]["Renewable-Heat-Incentive"][0]["Space-Heating-Existing-Dwelling"][0]) /
                    parseFloat(result["RdSAP-Report"]["Energy-Assessment"][0]["Property-Summary"][0]["Total-Floor-Area"][0]))
                return prev;
            })
        }
        var address = xmlObj[0].data["RdSAP-Report"]["Report-Header"].Property.Address["Address-Line-1"]._text + ", " +
            xmlObj[0].data["RdSAP-Report"]["Report-Header"].Property.Address["Address-Line-2"]._text + ", " +
            xmlObj[0].data["RdSAP-Report"]["Report-Header"].Property.Address["Post-Town"]._text + ", " +
            xmlObj[0].data["RdSAP-Report"]["Report-Header"].Property.Address["Postcode"]._text
        var uprn = xmlObj[0].data["RdSAP-Report"]["Report-Header"].Property.UPRN._text
        var into = [];
        for (let z = 0; z < ((to) + 1); z++) {
            into.push(list.board[z])
        }
        setPdfData({ startData: SAP.current, endData: SAP.calculated, eachItem: into, showButton: true, propertyUPRN: uprn, propertyAddress: address })
        return true;
    }

    function handleOnchange(event) {
        var _ = require('lodash');
        if (event.target) {
            var arr = event.target?.id.split("/")
            var string = "RdSAP-Report[SAP-Data][SAP-Property-Details]"
            for (let x =0; x< arr.length; x++){
                string += "[" + arr[x] + "]"
            }
            setXmlObj((prev) => {
                             _.set(prev[0].data, string, event.target.value)
                     //     console.log(prev);
                            return prev;
                        });
        } 
    }

    function getHTML(readings, id, type) {
        if (typeof readings === 'object') {
            Object.keys(readings).map((keyName, i) => {
                if (Array.isArray(readings[keyName]) && readings[keyName].length > 1) {
                    readings[keyName].map((arr, index) => {
                        if (type === "IN") {
                            setXmlObj((prev) => {
                                prev[0].fields.push(<>
                                    <div className="row" key={id} style={{ marginBottom: "30px" }}>
                                        <div className="col-md-12">
                                            <h1 >{(keyName + " " + (index + 1)).toString().replaceAll("/0/", "/")} : </h1>
                                        </div>
                                    </div>
                                </>)
                                return prev;
                            });
                        }
                        else {
                            setXmlObj((prev) => {
                                prev[0].outputHtml.push(<>
                                    <div className="row" key={id} style={{ marginBottom: "30px" }}>
                                        <div className="col-md-12">
                                            <h1 >{(keyName + " " + (index + 1)).toString().replaceAll("/0/", "/")} : </h1>
                                        </div>
                                    </div>
                                </>)
                                return prev;
                            });
                        }
                        getHTML(arr, id + "/" + keyName + "/" + index, type)//+ "/" + (index+1))
                    })
                } else {
                    if (typeof readings[keyName] === 'object') {  //Array.isArray([keyName])){
                        var temp = ""
                        if (id.length > 0) temp += "/"
                        if (Object.keys(readings[keyName]).length > 1) {
                            var elements = id.split('/').length
                            if (type === "IN") {
                                setXmlObj((prev) => {
                                    prev[0].fields.push(<>
                                        <div className="row" key={id} style={{ marginBottom: "30px" }}>
                                            <div className="col-md-12">
                                                <h3 >{id.split('/')[elements - 1]} : </h3>
                                            </div>
                                        </div>
                                    </>)
                                    return prev;
                                });
                            }
                            else {
                                setXmlObj((prev) => {
                                    prev[0].outputHtml.push(<>
                                        <div className="row" key={id} style={{ marginBottom: "30px" }}>
                                            <div className="col-md-12">
                                                <h3>{id.split('/')[elements - 1]} : </h3>
                                            </div>
                                        </div>
                                    </>)
                                    return prev;
                                });
                            }
                        }
                        temp += keyName;
                        getHTML(readings[keyName], id + temp, type)
                    }
                    else {
                        if (type === "IN") {
                            var elements = id.split('/').length
                            if (elements < 2) elements = 1;
                            var options = getOptions(id.split('/')[elements - 1].replaceAll('-', ''), readings[keyName]);
                            robsString += ((keyName === "0") ? id : id + "/" + keyName).toString().replaceAll("/0/", "/") ;
                            options.map(function (e) {robsString+=  ","+ e.props?.children;}).join(',');
                            robsString += '\n'
                         //   console.log(robsString)
                          //  console.log(options)
                            setXmlObj((prev) => {
                                prev[0].fields.push(<>
                                    <div className="row" key={id} style={{ marginBottom: "30px" }}>
                                        <div className="col-md-4">
                                            <span className="input-label">{((keyName === "0") ? id : id + "/" + keyName).toString().replaceAll("/0/", "/")} : </span>
                                        </div>
                                        <div className="col-md-4">
                                            {
                                                (options.length > 0) ?
                                                    <select id={id + "/" + keyName} className="form-select form-select-lg mb-3" onChange={handleOnchange} aria-label="Default select example">
                                                        {options}
                                                    </select>
                                                    :
                                                    <input id={id + "/" + keyName} type="text" className="form-control" defaultValue={readings[keyName]} onChange={handleOnchange}></input>
                                            }
                                        </div>
                                    </div>
                                </>)
                                return prev;
                            });
                        } else {
                            var elements = id.split('/').length
                            if (elements < 2) elements = 1;
                            if (keyName == 'language') {
                            } else {
                                var options = getOptions(id.split('/')[elements - 1].replaceAll('-', ''), readings[keyName]);
                                setXmlObj((prev) => {
                                    prev[0].outputHtml.push(<>
                                        <div className="row" key={id} style={{ marginBottom: "30px" }}>
                                            <div className="col-md-4">
                                                <span className="input-label">{((keyName === "0") ? id : id + "/" + keyName).toString().replaceAll("/0/", "/")} : </span>
                                            </div>
                                            <div className="col-md-4">
                                                {
                                                    (options.length > 0) ?
                                                        <select id={id + "/" + keyName} className="form-select form-select-lg mb-3" onChange={handleOnchange} aria-label="Default select example" disabled>
                                                            {options}
                                                        </select>
                                                        :
                                                        <input id={id + "/" + keyName} type="text" className="form-control" defaultValue={readings[keyName]} readOnly></input>
                                                }
                                            </div>
                                        </div>
                                    </>)
                                    return prev
                                });
                            }
                        }
                    }
                }
            })
            clearPartOptions();
        }
    }

    function selection(event) {
        var selected = event.target.value;
        if (selected == "Input") {
            setHtmlSel("Input")
        } else if (selected == "Results") {
            setHtmlSel("Results")
        } else {
            setHtmlSel([]);
        }
    }

    function sapChange(event) {
        setSAP((prev) => {
            prev.required = event.target.value;
            return prev;
        })
    }

    function Spinner() {
        return (
                <div className="d-flex justify-content-left">
                    <div className="spinner-border text-warning" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
        )
    }

    async function handlePDF() {
        var pdfDoc = await pdfFile(pdfData);
        const asPdf = pdf(); //new PDF
        asPdf.updateContainer(pdfDoc);
        var bb2 = await asPdf.toBlob()
        saveAs(bb2, `Appendix.pdf`)  //save in browser
    }

    return (
       <>
                <div className="row" style={{ marginBottom: "60px" }}>
                    <div className="col-md-6">
                            <div className="input-group mb-3">
                                <input type="file" name="file" className="form-control" id="inputGroupFile" required onChange={handleImport}
                                    label={xmlObj[0]?.fileName} accept=".xml" />
                        </div>
                    </div>
                    <div className="col-md-6">
                    </div>
                </div>
                {(xmlObj[0]?.fields?.length > 0) ?
                    <>
                        <div className="row" style={{ marginBottom: "30px" }}>
                        <div class="d-flex justify-content-center">
                         
                            <div className="col-md-4 " style={{ height: "50px"}}>
                            <div className="input-group w-auto rounded">
                                <h2> Current SAP:
                                </h2>
                                {(isLoading == "current") ? <Spinner /> : <input className="rounded"  style={{ height: "50px", width: "75px", display: "inline-block", textAlign: "center", marginLeft: "10px" }} value={SAP.current.sap}></input>
                                }</div>
                            </div>
                            <div className="col-md-4 " style={{ float: "center" }}>
                            <div className="input-group w-auto justify-content-center rounded">
                                <h2> Required SAP:
                                </h2>
                                <input className="rounded" onChange={sapChange} style={{ height: "50px", width: "75px", display: "inline-block", textAlign: "center", marginLeft: "10px" }}></input>
                            </div>
                            </div>
                            <div className="col-md-4 " style={{ }} >
                            <div className="input-group w-auto rounded">
                                <h2 style={{ marginLeft: "auto" }}>
                                    Calculated SAP:
                                </h2>
                                <input className="rounded" style={{  height: "50px", width: "75px", display: "inline-block", textAlign: "center", marginLeft: "10px" }} value={SAP.calculated.sap}></input>
                                {(isLoading == "calc") ? <Spinner /> : <></>}
                            </div>
                            </div>
                        </div>
                        </div>
                        
                        <div className="row" style={{ marginBottom: "30px" }}>
                            <div className='col-md-5'>
                                <h3>Improvements to be calculated</h3>
                                <div key={refreshKey} className='drop-board col-md-12 rounded' ref={drop} style={{ minHeight: "50px", border: isOver ? '3px solid red' : '1px solid black' }}>
                                    {boardRows}
                                </div>
                            </div>
                            <div className='col-md-2'></div>
                            <div className="col-md-5">
                                <h3>Available Improvements</h3>
                                <div className='drag-box col-md-12 rounded' ref={drop2} style={{ minHeight: "50px", border: isOver2 ? '3px solid red' : '1px solid black' }}>
                                    {rows}
                                </div>
                            </div>
                        </div>
                    </>
                    : <></>}
                {(isLoading != "error" && isLoading != "false") ? <Spinner /> : <>
                    {(isLoading == "error") ? <>
                        <div className="alert alert-danger fade show col-md-8" role="alert">
                            <strong>Error in data</strong> Unfortanately the engine cannot tell you which fields
                        </div></>
                        : <>
                            {(xmlObj[0]?.fields?.length > 0) ?
                                <>
                                    <div className="row" style={{ marginBottom: "30px" }}>
                                        <div className="col-md-8">
                                            <select className="form-select form-select-lg" onChange={selection} aria-label="Default select example">
                                                <option value="Hide">Hide Data</option>
                                                <option value="Input">Show Import Data</option>
                                                <option value="Results">Show Result Data</option>
                                            </select>
                                        </div>
                                        <div className="col-md-2">
                                            <button onClick={handleCalculate} className="btn btn-primary float-right">
                                                Calculate
                                            </button>
                                        </div>
                                        <div className="col-md-2">
                                            {(pdfData.showButton) ?
                                                <button onClick={handlePDF} className="btn btn-primary float-right">
                                                    Show PDF
                                                </button> : <></>}
                                        </div>
                                    </div>
                                </>
                                : <></>} </>} </>}
                <div className="row" >
                    <div className="col-md-12">
                        {(htmlSel === "Results") ? xmlObj[0]?.outputHtml :
                            (htmlSel === "Input") ? xmlObj[0]?.fields : <></>}
                    </div>
                </div>
                </>
    );
};
export default QueryPage;