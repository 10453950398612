import React from "react";
import "./App.css";
import QueryPage from "./components/QueryPage";
import EpcCalculator from "./components/EpcCalculator";
import Landing from "./components/Landing";
import AuditComponent from "./components/AuditCheck";
import PdfPage from "./components/pdfPage";
import KarmaPage from "./components/karma";
import ExternalPage from "./components/External";
import { MsalProvider, AuthenticatedTemplate, useMsal, UnauthenticatedTemplate } from '@azure/msal-react'; 
// import { EventType } from '@azure/msal-browser';
import { DndProvider} from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Routes, Route } from "react-router-dom";
import { PageLayout,PageLayoutExt } from './components/PageLayout';
import { apiRequest,usrRequest } from "./authConfig";
import { useSearchParams } from 'react-router-dom';
//import  ExcelImport  from './pages/ExcelImport';
//import { Home } from './pages/Home';

const globalState = {
  text: "foo",
};
const globalStateContext = React.createContext(globalState);
const Pages = () => {
  const [searchParams] = useSearchParams();


  /**
   * useMsal is hook that returns the PublicClientApplication instance,
   * an array of all accounts currently signed in and an inProgress value
   * that tells you what msal is currently doing. For more, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
   */
  const { instance } = useMsal();

  localStorage.clear()

  const activeAccount = instance.getActiveAccount();
/* this worker is shared between drag-drop and file input element */

  // instance.acquireTokenSilent({
  //   ...usrRequest,
  //   account: activeAccount,
  // }).then((res)=>{
  //   console.log(res);
  // });
  const WrapperComponent = (props) => {
    console.log(props);
    if (searchParams.get("id")){
      if (searchParams.get("im")==="trianco"){return <AuditComponent/>}
      else{return <AuditComponent/>}
    }else{
      return <Landing/>
    }
  }
  
      
/* when the worker sends back the HTML, add it to the DOM */
 console.log(activeAccount);

 const isExternal = (children) =>{
//if (activeAccount?.idTokenClaims?.groups?.length >0){
if (activeAccount?.username.toLowerCase().includes('happyenergy.co.uk') >0){
  return children;
}else{
  return <PageLayoutExt><ExternalPage/></PageLayoutExt>
}
 }

  return (
    <>
    <AuthenticatedTemplate>
    <Routes>
        <Route path="/:id" element={<PageLayout><AuditComponent /></PageLayout>} />
        <Route path="/" element={isExternal(<PageLayout>
          < WrapperComponent/>
            </PageLayout>)} />
        <Route path="/audit/:id" element={isExternal(<PageLayout><AuditComponent/></PageLayout>)} />
        <Route path="/pdf" element={<PageLayout><PdfPage/></PageLayout>} /> 
        <Route path="/Query" element={<PageLayout><QueryPage/></PageLayout>} />
        <Route path="/karma" element={<PageLayout><KarmaPage/></PageLayout>} />
        <Route path="/external" element={<PageLayout><ExternalPage/></PageLayout>} /> 
        <Route path="/EPCcalc" element={
        <DndProvider backend={HTML5Backend}>
        <PageLayout>< EpcCalculator/></PageLayout>
        </DndProvider>} />
        {/* <redirect to ="/audit" />  */}
    </Routes>
    </AuthenticatedTemplate>
    <UnauthenticatedTemplate>
    <Routes>
    <Route path="/:id" element={<PageLayout><AuditComponent /></PageLayout>} />
    <Route path="/" element={<PageLayout>
          < WrapperComponent/></PageLayout>} />
    {/* <Route path=":im" element={<PageLayout><AuditComponent/></PageLayout>} />
    <Route path=":id" element={<PageLayout><AuditComponent /></PageLayout>} /> */}
    <Route path="/audit/:id" element={<PageLayout><AuditComponent/></PageLayout>} />
    <Route path="/pdf" element={<PageLayout><PdfPage/></PageLayout>} /> 
    <Route path="/external" element={<PageLayoutExt><ExternalPage/></PageLayoutExt>} /> 
    </Routes>
    </UnauthenticatedTemplate>
     </>
  );


  

};




const App = ({ instance }) => {
  return (
     <main> 
      
          <MsalProvider instance={instance}>
          <globalStateContext.Provider value={globalState}>
            {/* <PageLayout> */}
                <Pages />
            {/* </PageLayout> */}
            </globalStateContext.Provider>
        </MsalProvider>
        
     </main>
  );
}

export default App;
