import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router';
import { Carousel, Button } from 'react-bootstrap';
import { pdf } from '@react-pdf/renderer';
import SharepointCall from "../services/sharepointCall";
import queryString from "query-string";
import PdfPage from "./pdfPage";
import logo from '../img/logo.png';
import logo2 from '../img/logo2.png';
import logoTrianco from '../img/trianco.png';
import logoActivair from '../img/activair.png';
import logoOctopus from '../img/octopus.png';
import logoRetrofit from '../img/retrofit.png';
import logoAnglo from '../img/anglo.png';

import logo3 from '../img/logo3.png';
import { right } from "@popperjs/core";
import PDFMerger from 'pdf-merger-js/browser';
import { saveAs } from 'file-saver';
import { useNavigate } from "react-router-dom";
import { async } from "q";

function ParentComponent() {
    const [json, setJson] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const [mobile, setMobile] = useState(window.innerWidth <= 500);
    const [pdfDownload, setPdfDownload] = useState();
    const [pdfBlob, setPdfBlob] = useState();
    const handleWindowSizeChange = () => {
        setMobile(window.innerWidth <= 500);
    }
    let navigate = useNavigate();
    var id = queryString.parse(location.search).id;
    var im = queryString.parse(location.search).im;
    
   if(!im && window.location.href.toLocaleLowerCase().includes('retrofit')) im = 'anglo';
    // console.log(window.location.href);
    // console.log(im);

    if (im) im = im.toLowerCase();

    if (!id) {
        id = location.pathname.replace("/", "");
    }
    if (isNaN(id)) {
        id = -1;
    }

    var decComps;

    useEffect(() => {
        if (id == -1) {
            navigate("/");
        }
        setPdfDownload(<div></div>);
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
            updateData();
        }
    }, [])

    function updateData() {
        setIsLoading(true);
        SharepointCall.getListData(id, im)
            .then((images) => {
                images.data.id = id;
                setJson({ jsondata: images.data });
                setIsLoading(false);
                LoadDoc({ jsondata: images.data });
                if (images.data.pdf) {
                    console.log(images.data)
                    GenerateCombinedPDFAttachments({ jsondata: images.data });
                }
            }).catch(function (error) {
                console.log(error);
                setIsLoading(false);
            })
    }

    const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    async function GenerateCombinedPDFAttachments(data) {
        var preCert;
        var blob = [];
        if (id == -1) return;
        for (let i = 0; i < data?.jsondata?.pdf?.length; i++) {
            console.log(data?.jsondata?.pdf[i]?.name);
            if (data?.jsondata?.pdf[i]?.name.toLowerCase().includes('certificate')){ // == "UKSOL%20Warranty%20Plus%20MCS%20Certificate%20.pdf") {
                preCert = data?.jsondata?.pdf[i]?.base64;
                console.log("found?")
                continue
            }
            const b64Data = data?.jsondata?.pdf[i]?.base64;
            blob.push(b64toBlob(b64Data, 'application/pdf'));  //application/pdf
        }
        var blobArray = [];
        //  console.log(preCert);
        // if (preCert){
        //     blobArray.push( b64toBlob(preCert, 'application/pdf'));
        // }else{
        for (let i = 0; i < blob.length; i++) {
            blobArray.push(blob[i]);
        }
        // }
        if (blobArray.length>0){
        let merger = new PDFMerger() //Merger
        await Promise.all(blobArray.map(async (file) => await merger.add(file, null)))
        const mergedPdf = await merger.saveAsBlob()
        const file = window.URL.createObjectURL(mergedPdf);
        const dd = (
            <>
                <div>
                    <iframe src={file} width="100%" height="500px"></iframe>
                </div>
            </>
        )
        setPdfBlob(dd);
       }else setPdfBlob();
    }


    function Spinner() {
        return (
            <>
                <div className="d-flex justify-content-center">
                    <div className="spinner-border text-warning" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </>
        )
    }
        decComps = (
            <>
                {isLoading ? <Spinner /> :
                    <>
                        {
                            (json?.jsondata?.pannelWatts || json?.jsondata?.ActivairModel) ?
                                (true) ?
                                    <>
                                        {pdfDownload}
                                    </>
                                    :
                                    <>
                                    </>
                                : <></>
                        }
                        <AuditComponent data={json} pdfDoc={pdfBlob} im={im} />
                    </>
                }
            </>
        )
    var pdfDoc;
    async function LoadDoc(data) {

        setPdfDownload(
            <>
                <div style={{ float: right }}>
                    
                    <Button
                        style={{marginTop:"50px"}}
                        onClick={
                            async () => {
                                var existingWarranty;
                                var blob = [];
                                //  console.log("1")
                                if (id == -1) return;
                                if (data.jsondata?.pdf) { } else { return }
                                pdfDoc = await PdfPage(data);
                                for (let i = 0; i < data?.jsondata?.pdf.length; i++) {
                                    if (data?.jsondata?.pdf[i]?.name.toLowerCase().includes('certificate')){ // == "UKSOL%20Warranty%20Plus%20MCS%20Certificate%20.pdf") {
                                        existingWarranty = data?.jsondata?.pdf[i]?.base64;
                                        continue
                                    }
                                    const b64Data = data?.jsondata?.pdf[i]?.base64;
                                    blob.push(b64toBlob(b64Data, 'application/pdf'));  //application/pdf   // removed 
                                }

                                var blobArray = [];
                                if (existingWarranty) {
                                    console.log(blobArray.length)
                                    blobArray.push(b64toBlob(existingWarranty, 'application/pdf'));
                                }
                                else {
                                    const asPdf = pdf(); //new PDF  
                                    asPdf.updateContainer(pdfDoc);
                                    blobArray.push(await asPdf.toBlob())
                                    for (let i = 0; i < blob.length; i++) {
                                        blobArray.push(structuredClone(blob[i]));
                                    }
                                }

                                let merger = new PDFMerger() //Merger
                                console.log(blobArray.length)
                                await Promise.all(blobArray.map(async (file) => await merger.add(file, null)))
                                const mergedPdf = await merger.saveAsBlob()
                                merger = null
                                blobArray = [];
                                saveAs(mergedPdf, `UK SOL Warranty.pdf`)  //save in browser
                            }}
                    >
                        Download Combined Warranty PDF
                    </Button>
                </div>
            </>
        )
        return true;
    }

    return (
        <>
            <div className="container">
                <div className="container" style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start"}}>
                    {/* <div className="row flex-nowrap" style={{ marginBottom: 40 }}> */}
                    {(im == "anglo")?
                    <>
                    <div className="col-sm">
                    <img src={logoAnglo} alt="Anglo" className="img-fluid center-block" style={{maxHeight:"170px", flex: "1", maxWidth: "100%", height: "auto",paddingLeft: "5%", paddingRight: "6%" }}/>
                    </div>
                    <div className="col-sm">
                        <img src={logoRetrofit} alt="Retrofit" className="img-fluid center-block" style={{maxHeight:"170px", flex: "1", maxWidth: "100%", height: "auto", paddingRight: "5px"}} />
                    </div>
                    <div className="col-sm">
                        <img src={logoOctopus} alt="Octopus" className="img-fluid center-block" style={{maxHeight:"170px", flex: "1", maxWidth: "100%", height: "auto", paddingRight: "5px"}} />
                    </div>
                    </>
                    :
                    <>
                    <div className="col-sm">
                        <img src={(im == "trianco") ? logoTrianco : logo2  } alt="UKSOL" className="img-fluid center-block" style={{maxHeight:"170px", flex: "1", maxWidth: "100%", height: "auto" }} />
                    </div>
                    {(im == "trianco") ?
                        <div className="col-sm">
                        <img src={logoActivair} alt="activair" className="img-fluid center-block" style={{maxHeight:"70px", flex: "1", maxWidth: "100%", height: "auto",paddingLeft: "5%", paddingRight: "6%" }}/>
                    </div>:<></>

                    }
                    <div className="col-sm">
                        <img src={logo} alt="Happy Energy" className="img-fluid center-block" style={{maxHeight:"70px", flex: "1", maxWidth: "100%", height: "auto",paddingLeft: "5%", paddingRight: "6%" }}/>
                    </div>
                    <div className="col-sm">
                        <img src={logo3} alt="EDF" className="img-fluid center-block" style={{maxHeight:"170px", flex: "1", maxWidth: "100%", height: "auto", paddingRight: "5px"}} />
                    </div>
                    </>
                    }
                </div>
                
                {decComps}

            </div>
        </>
    )
}

const AuditComponent = (props) => {
    useEffect(() => {
    }, [])
    var json = props.data;
    var noAddress = "NO ADDRESS CURRENTLY REGISTERED AGAINST THIS VERIFICATION STICKER"
    var pdfDoc = props.pdfDoc;
    var insertHtml;
    switch (props.im?.toUpperCase()) {
        case "TRIANCO":
            insertHtml = <><h1 className="jumbotron-heading">Eco Innovation Measure Verification{(props.im)? ' - ' + props.im.toUpperCase():''}</h1>
            <p className="">This website will confirm if the Trianco Activair ASHP, EDF Innovation Measure 028, has been installed at this property:</p>
            <p className="font-weight-bold">Trianco  Activair Verification Number: <span className="font-weight-normal">{(json.jsondata?.error) ? <> <span style={{ color: "red" }}> ISSUE FINDING VERIFICATION NUMBER </span></> : json.jsondata?.title}</span></p>
            <p className="font-weight-bold">Registered Address: <span className="font-weight-normal">{(json?.jsondata?.ActivairModel) ? json.jsondata?.address : <><span style={{ color: "red" }}> {noAddress} </span></>}</span></p>
            <p className="font-weight-bold">Activair Model: <span className="font-weight-normal">{json.jsondata?.ActivairModel}</span></p>
            <p className="font-weight-bold">Flow Temperature On Heat Pump: <span className="font-weight-normal">{(json.jsondata?.FlowTemperatureOnHeatPump != null) ? json.jsondata?.FlowTemperatureOnHeatPump  : ''}</span></p>
            <p className="font-weight-bold">Flow Temperature On MCS Certificate: <span className="font-weight-normal">{(json.jsondata?.FlowTemperatureOnMCSCertificate != null) ? json.jsondata?.FlowTemperatureOnMCSCertificate : ''}</span></p>
            <p className="font-weight-bold">Installer: <span className="font-weight-normal">{json?.jsondata?.installer}</span></p></>
            break;
    
        default:
            insertHtml = <><h1 className="jumbotron-heading">Eco Innovation Measure Verification{(props.im)? ' - ' + props.im.toUpperCase():''}</h1>
            <p className="">This website confirms whether the UKSOL Tigo optimised solar PV panels, EDF Innovation Measure 013, have been installed on this property:</p>
            <p className="font-weight-bold">UKSOL Verification Number: <span className="font-weight-normal">{(json.jsondata?.error) ? <> <span style={{ color: "red" }}> ISSUE FINDING VERIFICATION NUMBER </span></> : json.jsondata?.title}</span></p>
            <p className="font-weight-bold">Registered Address: <span className="font-weight-normal">{(json?.jsondata?.pannelWatts) ? json.jsondata?.address : <><span style={{ color: "red" }}> {noAddress} </span></>}</span></p>
            <p className="font-weight-bold">Panel: <span className="font-weight-normal">{json.jsondata?.panelModel}</span></p>
            <p className="font-weight-bold">Panel Number: <span className="font-weight-normal">{json.jsondata?.panelNumber}</span></p>
            <p className="font-weight-bold">Panel Wattage: <span className="font-weight-normal">{(json.jsondata?.pannelWatts != null) ? json.jsondata?.pannelWatts + 'w' : ''}</span></p>
            <p className="font-weight-bold">System Size: <span className="font-weight-normal">{(json.jsondata?.systemSize != null) ? json.jsondata?.systemSize + 'w' : ''}</span></p>
            <p className="font-weight-bold">Installer: <span className="font-weight-normal">{json?.jsondata?.installer}</span></p></>
            break;
    }
    return (
        <>
            <br />
            <br />
            <br />
            {insertHtml}

            {(json?.jsondata?.pdf && json?.jsondata?.pdf[0]?.base64 && pdfDoc) ?
                <p className="font-weight-bold">Attachment:</p>
                : <></>
            }
            {(json?.jsondata?.pdf && json?.jsondata?.pdf[0]?.base64) ?
                <>
                    {pdfDoc}
                </>
                : <></>}
            {(json?.jsondata?.photos && json?.jsondata?.photos[0]?.base64) ?
                <p className="font-weight-bold">Images:</p>
                : <></>
            }
            <Carousel>
                {json?.jsondata?.photos?.map((photo) => (
                    <Carousel.Item key={photo.name}>
                        <img
                            className="d-block w-100"
                            src={"data:image/jpeg;base64," + photo.base64}
                            alt="First slide"
                            key={photo.name} />
                        <Carousel.Caption>
                        </Carousel.Caption>
                    </Carousel.Item>
                ))}
            </Carousel>
            <br />
            <h2 className="e">Important Note For Trustmark Inspector:</h2>
            <ul className="">
                <li>Please check that the verification number on the sticker on the inverter matches the number above</li>
                <li>Please check that the address shown above matches the address where this verification sticker is located</li>
            </ul>
            <p className="mb-4">If any of the above verification checks fail, the panels may not be those approved by Ofgem as an Innovation Measure. If so, please email
                <a href="mailto:mail@happyenergy.co.uk">mail@happyenergy.co.uk</a> for further information and to verify if UKSOL Innovation Measure panels were installed here</p>
        </>
    )
};

export default ParentComponent;