import React, { useEffect, useState,useRef,useMemo } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import Pagination from "react-bootstrap/Pagination";
import KarmaApiService from '../services/karmaApi'
import {useMsal} from '@azure/msal-react';
import { utils, writeFile,read } from 'xlsx';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Multiselect from 'multiselect-react-dropdown';

export default function ImportExport() {
  const [state, setState] = useState({
    data: [],
    limit: 100,
    count: 0,
    activePage: 0,
    next: 1,
    backData:[],
    loading: false
  });
  const [importData, setImportData] = useState({});
  const [modalText,setModalText] = useState();
  const [recordCount,setRecordCount] = useState({applied:0,total:0});
  const searchFilters = useRef({});
  const { instance } = useMsal();
  const settings = useRef([]);
  const importRef = useRef([]);
  const [modalShow,setModalShow] = useState(false);
  const handleImportClick = (e) => {
    if (importRef.current.value) importRef.current.value = ''
    importRef.current.click()
  };
  const ms = useRef({options:[],selectedValues:[]});

  const data = useMemo(async () => {
    settings.current = await KarmaApiService.getSettings(instance).catch(function (error) {
        // setIsLoading("error");
        if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
        } else if (error.request) {
            console.log(error.request);
        } else {
            console.log('Error', error.message);
        }
        
    });
    console.log(settings.current);
    let list =[];
    settings.current.data.settings.fields.results.map((m)=>{
      if(m.field_type_id == 2){list.push({name:m.name,id:m.id,field_type_id:m.field_type_id})}
      if(m.field_type_id == 5){list.push({name:m.name,id:m.id,field_type_id:m.field_type_id})}
    })
    ms.current.options = list
    console.log(ms);
    
  }, [])

  useEffect(() => { // initial run
    // axios
    //   .get(
    //     `https://jsonplaceholder.typicode.com/posts?_page=1&_limit=${state.limit}`
    //   )
    //   .then((res) => {
    //     setState((prev) => ({
    //       ...prev,
    //       data: res.data
    //     }));
    //   })
    //   .catch((error) => console.log(error));
  }, [state.limit]);


  const handleImport = ($event) => {
    //console.log("here");
    const files = $event.target.files;
    var rows;
    var columnNames;
    if (files.length) {
        const file = files[0];
        setImportData((prev) => ({
          ...prev,
          name : $event.target.files[0].name
        }));
          
        
        const reader = new FileReader();
        reader.onload = (event) => {
          const ab = event.target.result;
          /* Parse file */
     //     console.log("here")
          var wb = read(ab, {dense: true});
          var sheets;
          sheets = wb.SheetNames;
       //   console.log(wb);
          if (sheets.length) {
              rows = utils.sheet_to_json(wb.Sheets[sheets[0]], {defval:"" });
              const [columns] = utils.sheet_to_json(wb.Sheets[sheets[0]], { header: 1, defval:"" });
              columnNames = columns;
              validateData(rows);
              setImportData((prev) => ({
                ...prev,
                rows: rows
              }));
         //     console.log(rows);
          }
        }
        reader.readAsArrayBuffer(file);
    }
}

function validateData(data){
  var stringy ="";
  var keyNames = Object.keys(data[0]);
  console.log(keyNames);
  for(let z=0; z< keyNames.length;z++)
  {
  let splited = keyNames[z].split('|');

  if (splited[0].toLocaleLowerCase()=="custom")
  {
    var result = settings.current.data.settings.fields.results.find(obj => {
      return obj.name.toLocaleLowerCase() == splited[1].toLocaleLowerCase()
    })
    console.log(result);
    if (!result) 
    {
      stringy+= "Column '" + splited[1] + "' is not a recgonised custom column\n\n"
      continue;
    }

    for(let x=0; x < data.length; x++){
      //console.log(splited[2]);
      if (data[x][keyNames[z]].length < 1) continue;
      if(result.field_type_id == "2"){  // select list
      
      var result2 = result.options.find(obj => {
        return obj.name.toLocaleLowerCase() == data[x][keyNames[z]].toLocaleLowerCase()
      })
    //  console.log(result2);
      if (!result2) 
    {
      stringy+= "Column " + keyNames[z] +", Row " + (x+1) + ", Value '"+data[x][keyNames[z]]+"' is not a Valid dropdown option\n\n"
      continue;
    }
      }else{
        //console.log(splited[0])
        var result = settings.current.data.settings.fields.results.find(obj => {
          return obj.name == splited[1]
        })
      }
    }
  //  backdata[]
  }else{

    var result = settings.current.data.settings.fields.results.find(obj => {
      return obj.name == splited[0]
    })
    console.log(result);

  }

  }
 // console.log(stringy);
  if (stringy.length == 0){stringy = "OK.."}
  setModalShow(true);
  setModalText(stringy);
}

  const PaginationComponent = (next) =>{
    let numbers=[];
    console.log(next);
    console.log("count: "+state.count +" limit: " + state.limit)
    for (let number = next; number <= (state.count/state.limit) && number <= next+8; number++){
      console.log(number);
      numbers.push(
        <Pagination.Item
          key={number}
          active={number == state.activePage}
          onClick={() => {handlePageChange(number)}}
        >
        {number}
        </Pagination.Item>
      )
    }
    return numbers
  }

  function sortOrder(contact){
    var result1 = contact.field_values.filter((v,i,a)=>a.findIndex(v2=>(v.field_parent_id === v2.field_parent_id))===i)
        
    var output = [...result1];
    output = output.filter(function( obj ) {
        return obj.field_type_id == 2;
    });
    contact.field_values = contact.field_values.filter(function( obj ) {
        return obj.field_type_id !== 2;
    });
    contact.field_values = [...contact.field_values,...output];
    contact.field_values.sort((a,b) => a.id - b.id);
    return contact;
  }

  const handlePageChange = async (pageNumber) => {
    var nextIn = state.next;
    if (pageNumber>=state.next + 8) nextIn = state.next +1
    if (pageNumber!==1 && pageNumber <= state.next) nextIn = state.next -1

    if (state.backData.length < pageNumber * state.limit){
      await loadData(pageNumber,pageNumber);
      setState((prev) => ({
        ...prev,
        activePage: pageNumber,
        next : nextIn
      }));
    } else{
      setState((prev) => ({
        ...prev,
        data: prev.backData.slice((pageNumber-1)*prev.limit,
                                  pageNumber*prev.limit),
        activePage: pageNumber,
        next : nextIn
      }));
    }
  };

  const filter =() => {
      setState({
        data: [],
        limit: 100,
        count: 0,
        activePage: 1,
        next: 1,
        backData:[],
        loading: true
      })
      loadData(1,1);
  }

  const loadData = async (page, active) =>{
    var queries = ``
    let i =0;
      for (var name in searchFilters.current)
      {
        if (searchFilters.current[name].length > 0) {
        queries += "&filters" +name + "="+ searchFilters.current[name]
        i++;
        }
      }


        KarmaApiService.contactsFiltered({queries:queries},page,50000,instance)
        .then((res) => {
       //   console.log(res.data.list);
          for (let x=0; x< res.data.list.length;x++){
            res.data.list[x] = sortOrder(res.data.list[x]);
          }

          let temp = [...state.backData,...res.data.list]
          console.log(temp)
            setState((prev) => ({ 
              ...prev,
              data: (res.data.list.length > prev.limit)? temp.slice((active-1)*prev.limit,active*prev.limit): res.data.list,
              backData: (res.data.list.length > prev.limit)?[...temp]:res.data.list,
              count: res.data.count,
              loading:false
            }));
          })
          .catch((error) => {
            setState((prev) => ({ 
              ...prev,
              loading:false
            }));
            console.log(error)
          }
          );
  }

  const exportList =() => {
          for(let z=0; z< state.backData.length;z++)
          {
          for (let x =0; x < state.backData[z]?.field_values.length; x++)
          {
            
            console.log(state.backData[z]);
            let item = state.backData[z].field_values[x];
            if(item.field_parent_id){
            var result = settings.current.data.settings.fields.results.find(obj => {
              return obj.id == item.field_parent_id??item.field_id
            })
            //console.log(result);
            var result2 = result.options.find(obj => {
              return obj.id == item.value
            })
            //console.log(result2);
            state.backData[z]["custom|"+result.name] = result2?.name;     //+"|"+result.id] = result2.name;
            }else{
              var result = settings.current.data.settings.fields.results.find(obj => {
                return obj.id == item.field_id
              })
              console.log(result);
              state.backData[z]["custom|"+result.name] = item.value; //   +"|"+result.id] = item.value;
            }
          //  backdata[]
          }
          if (state.backData[z].addresses[0]){
              state.backData[z]["address|street"] = state.backData[z].addresses[0].street
              state.backData[z]["address|city"] = state.backData[z].addresses[0].city
              state.backData[z]["address|postal_code"] = state.backData[z].addresses[0].postal_code
          }
          if (state.backData[z].emails[0]){
              state.backData[z]["email|email"] = state.backData[z].emails[0].email
          }
          }

          var obj = structuredClone(state.backData);
          for(let z=0; z< obj.length;z++)
          {
          delete obj[z].emails
          delete obj[z].addresses
          delete obj[z].field_values
          }
          for (let z = 0; z < ms.current.selectedValues.length; z++){

            if (!obj.some(o => o.hasOwnProperty("custom|"+ms.current.selectedValues[z].name))){//+"|"+ms.current.selectedValues[z].id))) {
              obj[0]["custom|"+ms.current.selectedValues[z].name] = "";//+"|"+ms.current.selectedValues[z].id] = "";
            }
          }

          const wb = utils.book_new();
          const ws = utils.json_to_sheet([]);
          utils.sheet_add_json(ws, obj, { origin: 'A1', skipHeader: false });
          utils.book_append_sheet(wb, ws, 'Report');
          writeFile(wb, 'Karma Export.xlsx'); 
 }
 function Spinner() {
  return (
      <>
          <div className="d-flex justify-content-center">
              <div className="spinner-border text-warning" role="status">
                  <span className="sr-only">Loading...</span>
              </div>
          </div>
      </>
  )
}

const showModal = () => {
  setModalShow(true);
};

const hideModal = () => {
  setModalShow(false);
};

const importKarma = async() =>{
  var data = structuredClone(importData.rows);
  var stringy ="";
  var keyNames = Object.keys(data[0]);
  console.log(keyNames);
  for(let z=0; z< keyNames.length;z++)
  {
  let splited = keyNames[z].split('|');

  if (splited[0].toLocaleLowerCase() == "custom") // custom column
  {
    var result = settings.current.data.settings.fields.results.find(obj => {
      return obj.name.toLocaleLowerCase() == splited[1].toLocaleLowerCase()
    })

    console.log(result);
    console.log(data);

    if (!result) continue;
    //console.log(result);
    for(let x=0; x < data.length; x++){ // this column each row
      //console.log(splited[2]);
      
      if(result.field_type_id == "2"){  // select list
      
      var result2 = result.options.find(obj => {
        return obj.name.toLocaleLowerCase() == data[x][keyNames[z]].toLocaleLowerCase()
      })
      
      if (result2) 
        {
          if (!data[x].field_values) data[x].field_values = [];
          let fv =  data[x].field_values?.find(obj => obj.field_parent_id === result.id);
          if (fv){
            fv.value = result2.id
          }else{
          data[x].field_values.push({field_parent_id:result.id,field_id:result2.id,value:result2.id.toString()});
          }
        }else{
          continue;
        }
      }else{
        //console.log(splited[0])
        var result3 = settings.current.data.settings.fields.results.find(obj => {
          return obj.name.toLocaleLowerCase() == splited[1].toLocaleLowerCase()
        })
     //   console.log(result);
        if ((!result3)||data[x][keyNames[z]].toString().length==0) continue;
        if (!data[x].field_values) data[x].field_values = [];
        let fv =  data[x].field_values?.find(obj => obj.field_id === result3.id);
        if (fv){
          fv.value = result2.id
        }else{
        data[x].field_values.push({field_id:result3.id,value:data[x][keyNames[z]].toString()});
        }
      }
    }
  }else if (splited[0].toLocaleLowerCase() == "address"){   /////////////////////////////todo normal fields  and addresses 
    for(let x=0; x < data.length; x++){ 
      if (data[x][keyNames[z]].toString().length < 1) continue;
      if(!data[x].addresses){
        data[x].addresses =[];
        data[x].addresses.push({street:'',city:'',postal_code:'',address_type_id:1})
      }
      switch (splited[1].toLocaleLowerCase()) {
        
        case 'street':
          data[x].addresses[0].street = data[x][keyNames[z]].toString()
          break;
          case 'city':
          data[x].addresses[0].city = data[x][keyNames[z]].toString()
          break;
          case 'postal_code':
          data[x].addresses[0].postal_code = data[x][keyNames[z]].toString()
          break;
        default:
          break;
      }
      }
    }else if(splited[0].toLocaleLowerCase() == "email"){
      for(let x=0; x < data.length; x++){ 
        if (data[x][keyNames[z]].toString().length < 1) continue;
        if(!data[x].emails){
          data[x].emails =[];
          data[x].emails.push({email:'',email_type_id:1})
        }
        if (splited[1].toLocaleLowerCase() == "email"){
          data[x].emails[0].email = data[x][keyNames[z]].toString()
        }
    }
    }
  
  }
      for(let y=0; y < data.length;y++){
        
        var res = await KarmaApiService.contactGet(data[y]['id'], instance).catch(function (error) {
          if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
          } else if (error.request) {
              console.log(error.request);
          } else {
              console.log('Error', error.message);
          }
        });    
        res.data = sortOrder(res.data);
        for (let r =0; r < data[y]?.field_values?.length; r++ ){ // this stops the same custom field being re added
            if (data[y].field_values[r].field_parent_id){
            let fv =  res.data.field_values?.find(obj => obj.field_parent_id === data[y].field_values[r].field_parent_id);
            if (fv) {data[y].field_values[r].id = fv.id}
            }else{
            let fv2 =  res.data.field_values?.find(obj => obj.field_id === data[y].field_values[r].field_id);
            if (fv2) {data[y].field_values[r].id = fv2.id}
            }
        }
        console.log(res.data);
        if (res.data.addresses && res.data.addresses.length > 0) data[y].addresses[0].id = res.data.addresses[0].id
        if (res.data.emails && res.data.emails.length > 0) data[y].emails[0].id = res.data.emails[0].id


        var clone = structuredClone(data[y]);
        var contactOut ={};
        contactOut.contact ={};
        contactOut.contact= clone;
        var id = data[y]['id'];
         id = id.toString();
        delete contactOut.contact['id'];
        delete contactOut.contact['avatar_url'];
        delete contactOut.contact['organization_id'];
        delete contactOut.contact['created_at'];
        delete contactOut.contact['created_by_id'];
        delete contactOut.contact['updated_at'];
        delete contactOut.contact['relationships'];
        delete contactOut.contact['important_dates'];
        delete contactOut.contact['industry_id'];
        delete contactOut.contact['company'];
        delete contactOut.contact['social_accounts'];
        delete contactOut.contact['websites'];
        delete contactOut.contact['phone_numbers'];
        delete contactOut.contact['permissions'];
        delete contactOut.contact['permission'];
        delete contactOut.contact['tag_list'];
        delete contactOut.contact['group_id'];
        delete contactOut.contact['lead_status_id'];
        delete contactOut.contact['lead_process_id'];
        delete contactOut.contact['note_last_added_at'];
        delete contactOut.contact['note_last_added_id'];
        delete contactOut.contact['referral_source_id'];

        var z = Object.keys(contactOut.contact).filter(function(k) {
          return k.indexOf('custom|') == 0;
        })

        for(let x = 0; x < z.length; x++)
        {
          delete contactOut.contact[z[x]]
        }

        var z = Object.keys(contactOut.contact).filter(function(k) {
          return k.indexOf('address|') == 0;
        })

        for(let x = 0; x < z.length; x++)
        {
          delete contactOut.contact[z[x]]
        }

        var z = Object.keys(contactOut.contact).filter(function(k) {
          return k.indexOf('email|') == 0;
        })
        for(let x = 0; x < z.length; x++)
        {
          delete contactOut.contact[z[x]]
        }
        for (let x = 0; x < contactOut?.contact['field_values']?.length; x++) {
            delete contactOut.contact['field_values'][x]['field_type_id']
        }

        delete contactOut.contact.notes
        
        await KarmaApiService.contactUpdate(id, contactOut, instance)
        setRecordCount({applied:y+1,total:data.length})
      }
      hideModal();
}


function msOnSelect(selectedList, selectedItem) {
  ms.current.selectedValues.push(selectedItem);
}

function msOnRemove(selectedList, removedItem) {
//  ms.current.selectedValues = selectedList;
ms.current.selectedValues = ms.current.selectedValues.filter(item => item.id !== removedItem.id);
}

  return (
    <>
    <Modal show={modalShow} onHide={hideModal} dialogClassName="modal-90w">
        <Modal.Header closeButton>
          <Modal.Title>Import Validation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <>
            <textarea
        className="form-control"
        id="exampleFormControlTextarea1"
        rows="5"
        readOnly
        value={modalText}
      />
            </>
        </Modal.Body>
        <Modal.Footer>
          {
          (recordCount.applied == recordCount.total)?<>
          <Button variant="secondary" onClick={hideModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={importKarma} disabled ={(modalText?.length>4)}>
            Import to Karma
          </Button>
          </>:<>
          <span>{'Imported '+ recordCount.applied + ' of '+ recordCount.total}</span>
          </>
          }
        </Modal.Footer>
      </Modal>
    <div className="App">
      <h2 className="mt-5 px-4">.</h2>
      <div className="row" style={{marginTop:"20px"}}>
      <div className="col-md-12 text-center">
        Incude Columns:
            <Multiselect
                  options={ms.current.options} // Options to display in the dropdown
                  selectedValues={ms.current.selectedValues} // Preselected value to persist in dropdown
                  onSelect={msOnSelect} // Function will trigger on select event
                  onRemove={msOnRemove} // Function will trigger on remove event
                  displayValue="name" // Property name to display in the dropdown options
            />
            </div>
      </div>
      <div className="row" style={{marginTop:"20px"}}>
      <div className="col-md-3 text-center">
                LastName: <input type="text" id="[last_name]" onChange={(v) => {searchFilters.current[v.target.id] = v.target.value}}/>
            </div>
            <div className="col-md-3 text-center">
                Address: <input type="text" id="[addresses][street]" onChange={(v) => {searchFilters.current[v.target.id] = v.target.value}}/>
            </div>
            <div className="col-md-3 text-center">
                Postcode: <input type="text" id="[addresses][postal_code]"  onChange={(v) => {searchFilters.current[v.target.id] = v.target.value}}/>
            </div>
            <div className="col-md-3 text-center">
            <button style={{marginTop:'15px'}} type="button" className="btn btn-primary float-end" onClick={filter}>Filter</button>
            
            </div>
      </div>
      <input id='importFile' type='file' accept='.xlsx,.csv' ref={importRef} className='custom-file-input' onChange={(e) => handleImport(e)} style={{ display: 'none' }}></input>
      
      <div className="row" style={{marginTop:"20px"}}>
      <div className="col-md-3">
            {(state.loading)? <Spinner></Spinner>: <span>{(state.count>0)?"Found "+state.count+" Records":<></>}</span>}
            </div>
            <div className="col-md-3 text-center">
            
            </div>
           
            <div className="col-md-6 text-center">
            
            <button type="button" className="btn btn-primary" onClick={exportList}>Export</button>
            <button onClick={(e) => handleImportClick(e)} className="btn btn-primary float-end">
                                    Import to Karma
                                </button>
            </div>
      </div>
      <ul className="list-group" style={{marginTop:"20px"}}>
        {state.data.map((item) => {
          return (
            <>
            <li key={item.id} className="list-group-item">
              <span className="font-weight-bold pr-2">{item.KarmaContactID}</span>{" "}
             {(item.addresses?.length > 0)?item.addresses[0].street+', ':''} {(item.addresses?.length)?item.addresses[0].postal_code:''}
            </li>
          </>
          );
        })}
      </ul>

      <Pagination>
        {PaginationComponent(state.next)}
      </Pagination>
    </div>
    </>
  );
}
