import { Nav, Navbar, Button, Form } from 'react-bootstrap';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
//import { InteractionStatus } from "@azure/msal-browser"; 
import { loginRequest,  } from '../authConfig'; //b2cPolicies
import logo from '../img/logo.png';

export const NavigationBar = () => {
    const { instance,  } = useMsal();  //inProgress
     let activeAccount;

     if (instance) {
         activeAccount = instance.getActiveAccount();
     }

    const handleLoginPopup = () => {
        instance
            .loginPopup({
                ...loginRequest,
                redirectUri: '/redirect.html',
            })
            .catch((error) => console.log(error));
    };

    // const handleLoginRedirect = () => {
    //     instance.loginRedirect(loginRequest).catch((error) => console.log(error));
    // };

    // const handleLogoutRedirect = () => {
    //     instance.logoutRedirect();
    // };

    const handleLogoutPopup = () => {
        instance.logoutPopup({
            mainWindowRedirectUri: '/', // redirects the top level app after logout
        });
    };
    
    return (
        <>
            <Navbar bg="light" variant="light" className="navbarStyle navbar-right" collapseOnSelect  expand="lg">
                <a className="navbarButton" href="/">
                <img src={logo} alt="" style={{height:50}}></img>
                </a>
                <Navbar.Toggle className="mr-auto" variant="dark"/>
                <Navbar.Collapse  className="ml-auto" variant="dark">
                    
                <AuthenticatedTemplate>
                <Nav>
                     <Nav.Link className="navbarButton" href="/">
                        Home
                    </Nav.Link>
                    <Nav.Link className="navbarButton" href="/query">
                        Data Queries
                    </Nav.Link>
                    <Nav.Link className="navbarButton" href="/EpcCalc">
                        EPC Calculator
                    </Nav.Link>
                    <Nav.Link className="navbarButton" href="/Karma">
                        Karma
                    </Nav.Link>
                    <Nav.Link className="navbarButton" href="/external">
                        Contractor
                    </Nav.Link>
                    </Nav>
                    <Nav className='ms-auto'>
                    
                         <Button
                            variant="warning"
                            onClick={handleLogoutPopup}
                            >
                            {'Log out ' + (activeAccount && activeAccount.username ? activeAccount.username : 'Unknown')}
                            </Button>
                    
                    </Nav>

                    
                       
                </AuthenticatedTemplate>
                {/* </Nav> */}
                <UnauthenticatedTemplate>
                    <Nav></Nav>
                
                    {/* <div className="collapse navbar-collapse justify-content-end"> */}
                    <Nav className='ms-auto'>
                    <Button
                            variant="secondary"
                            onClick={handleLoginPopup}
                            >Sign In</Button>
                    </Nav>
               

{/* 
                        <DropdownButton variant="secondary" className="ml-auto" drop="start" title="Sign In">
                            <Dropdown.Item as="button" onClick={handleLoginPopup}>
                                Sign in using Popup
                            </Dropdown.Item>
                            <Dropdown.Item as="button" onClick={handleLoginRedirect}>
                                Sign in using Redirect
                            </Dropdown.Item>
                        </DropdownButton> */}
                    {/* </div> */}
                </UnauthenticatedTemplate>
                </Navbar.Collapse>
            </Navbar>
        </>
    );
};

export const NavigationBarExt = () => {
    const { instance,  } = useMsal();  //inProgress
     let activeAccount;

     if (instance) {
         activeAccount = instance.getActiveAccount();
     }

    const handleLogoutPopup = () => {
        instance.logoutPopup({
            mainWindowRedirectUri: '/', // redirects the top level app after logout
        });
    };
    
    return (
        <>
            <Navbar bg="light" variant="dark" className="navbarStyle">
                <a className="navbarButton" href="/external">
                <img src={logo} alt="" style={{height:50}}></img>
                </a>
                <AuthenticatedTemplate>
                     <Nav.Link className="navbarButton" href="/external">
                        Home
                    </Nav.Link>
                 
                    <div className="collapse navbar-collapse justify-content-end">
                        {/* <Button variant="info" onClick={handleProfileEdit} className="profileButton">
                            Edit Profile
                        </Button> */}
                         <Button
                            variant="warning"
                            onClick={handleLogoutPopup}
                            >
                            {activeAccount && activeAccount.username ? activeAccount.username : 'Unknown'}
                            </Button>
                    </div>
                </AuthenticatedTemplate>
                {/* <UnauthenticatedTemplate>
                    <div className="collapse navbar-collapse justify-content-end">
                    <Button
                            variant="secondary"
                            onClick={handleLoginPopup}
                            >Sign In</Button>
                            
                    </div>
                </UnauthenticatedTemplate> */}
            </Navbar>
        </>
    );
};


