import { Document, Page, Text, View, StyleSheet, Image, Link } from "@react-pdf/renderer";
import { Buffer } from "buffer";
import logo from '../img/logo.png';
import logo2 from '../img/logo2.png';
import logo3 from '../img/logo3.png';
// Create style
const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    color: "black",
  },
  section: {
    margin: 10,
    padding: 10,
  },
  header: {
    fontSize: 30,
    fontWeight: 900,
  },
  paragraph: {
    fontsize: '10',
  },
  tableRow: {
    marginLeft: "10px",
    marginTop: "10px",
    flexDirection: "row",
    float: "left",
    height: 100,
  },
  viewer: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    height: window.innerHeight,
  },
  image: {
    width: 150,
    height: 65,
    marginLeft: 20,
    marginRight: 20,
  },
  imageList: {
    width: 300,
    marginTop: 20,
    marginLeft: "auto",
    marginRight: "auto",
  },
});

// Create Document Component
function BasicDocument(props) {

  return new Promise((resolve) => {

    var json = props;

    var photos = json?.jsondata?.photos?.map((photo) => {
      var buf = Buffer.from(photo.base64, 'base64');
      var ext = photo.name.split('.').pop();
      return (
        
          <Image
            key={photo.name}
            src={{ data: buf, format: ext }}
            style={styles.imageList} 
             >
          </Image>
        
      )
    })

    var doc = (<>
      <Document>
        {/*render a single page*/}
        <Page size="A4" style={styles.page}>
          <View style={styles.tableRow}>
            <Image
              style={styles.image}
              src={logo2}
              key="1"
            />
            <Image
              style={styles.image}
              src={logo}
              key="2"
            />
            <Image
              style={styles.image}
              src={logo3}
              key="3"
            />
          </View>
          <View style={styles.section}>
            <Text style={styles.header}>Eco Innovation Measure Verification</Text>
            <Text style={{ marginTop: '20px', fontSize: '15' }}>
              This website confirms whether the UKSOL Tigo optimised solar PV panels, EDF Innovation Measure 013, have been
              installed on this property:
            </Text>
            <Text style={{ marginTop: '20px', fontSize: '15' }}  >UKSOL Verification Number: {json?.jsondata?.title}</Text>
            <Text style={{ marginTop: '20px', fontSize: '15' }}>Registered Details: {json?.jsondata?.address}</Text>
            <Text style={{ marginTop: '20px', fontSize: '15' }}>Installer: {json?.jsondata?.installer}</Text>
            <Text style={{ marginTop: '20px', fontSize: '15' }}>Panels: {json?.jsondata?.panelModel} X {json?.jsondata?.panelNumber}</Text>
            <Text style={{ marginTop: '20px', fontSize: '15' }}>Panel Watts: {json?.jsondata?.pannelWatts}</Text>
            <Text style={{ marginTop: '20px', fontSize: '15' }}>System Size: {json?.jsondata?.systemSize}</Text>
            {
            (json?.jsondata?.Areyouclaiminga25_x0025_upliftor) && <Text style={{ marginTop: '20px', fontSize: '15' }}>Are you claiming a 25% uplift or 45% uplift: {json?.jsondata?.Areyouclaiminga25_x0025_upliftor}</Text>
  }
              {(json?.jsondata?.Areyouclaiminga25_x0025_upliftor && json?.jsondata?.Areyouclaiminga25_x0025_upliftor.substring(0,2) == 45)?
            <>
            <Text style={{ marginTop: '20px', fontSize: '15' }}>How did youconnect the Tigo system to the internet: {json?.jsondata?.HowdidyouconnecttheTigosystemtot}</Text>
            <Text style={{ marginTop: '20px', fontSize: '15' }}>Tigo Monitoring System ID Number: {json?.jsondata?.TigoMonitoringSystemIDNumber}</Text>
            </>
            :<></>}
            <Text style={{ fontSize: '15', marginTop: '20px' }}>
              Important Note For Trustmark Inspector:
            </Text>
            <Text style={{ fontSize: '15', marginTop: '5' }}>{'\u2022 '}
              Please check that the verification number on the sticker on the inverter matches the number above.

            </Text>
            <Text style={{ fontSize: '15', marginTop: '5' }}>{'\u2022 '}
              Please check that the address shown above matches the address where this verification sticker is located
            </Text>
            <Text style={{ fontSize: '15', marginTop: '10' }}>
              If any of the above verification checks fail, the panels may not be those approved by Ofgem as an Innovation Measure. If
              so, please email <Link src='mailto:mail@happyenergy.co.uk' >mail@happyenergy.co.uk</Link> for further information and to verify if UKSOL Innovation Measure panels were
              installed here
            </Text>
            {(json?.jsondata?.photos[0])?
            <>
             <Text style={{ fontSize: '15', marginTop: '10' }}>
              Images Below:
            </Text>
            </>: <></>}
          </View>
          {(json?.jsondata?.photos[0])?
            <>
          <View style={styles.section} key="1">
            {photos}
          </View>
          </>: <></>}
          <View>
          {(json?.jsondata?.pdf[0])?
          <Text style={{ fontSize: '15', marginTop: '10' }}>
              Attachment Below:
            </Text>
            :<></>}
          </View>
        </Page>
      </Document>
    </>)
    setTimeout(() => {
      resolve(
        doc
      )
    }, 500)
  })
}

export default BasicDocument;