import types from "../http_common";
import { apiRequest } from "../authConfig";


async function bearerToken(instance) {
  const account = instance.getActiveAccount()
  if (!account) {
    throw Error(
      'No active account! Verify a user has been signed in and setActiveAccount has been called.'
    )
  }
  const response = await instance.acquireTokenSilent({
    ...apiRequest,
    account: account,
  })
 // console.log(response);
  return response.accessToken
}

  const findConservationAreaByUPRN = uprn => {
    return types.json().post(`/property/conservation/uprn`,uprn);
  };

  const GetEPCByUPRN = uprn => {
     return types.json().post(`/property/epc/forUPRN`,uprn);
   };

   const GetALLEPCByUPRN = uprn => {
    return types.json().post(`/property/epc/AllForUPRN`,uprn);
  };
  
  const get = () => {
    return types.json().get(`/`);
  };

  const GetAddressesForPostcode = postcode =>{
    return types.json().post(`/property/addresses/forPostcode`,postcode);
  }

  const GetRefiner = async (add1,add2,add3,postcode,instance) =>{
    var api = types.json();
    const item = await bearerToken(instance);
    api.defaults.headers.common.Authorization = 'Bearer ' + item;
    return await api.get('prop/refiner/single?add1='+add1+'&add2='+add2+'&postcode='+postcode)
  }

  const PostRefiner = async (json,instance) =>{
    var api = types.json();
    const item = await bearerToken(instance);
    api.defaults.headers.common.Authorization = 'Bearer ' + item;
    return await api.post('prop/refiner/bulk',json)
  }

  const GetTaxCode = (postcode,firstLine) =>{
    return types.json().get(`/property/taxband?postcode=`+postcode+`&firstLine=`+firstLine);
  }

  const GetListed = (uprn) =>{
    return types.json().get(`/property/listed?uprn=`+uprn);
  }

  const GetAddressAditional = (postcode) =>{
    return types.json().get('property/address/additional?postcode='+postcode)
  }

  const GetAddressesAdf = (term) =>{
    
  }


  const PropertyDbService = {
    findConservationAreaByUPRN,
    GetAddressesForPostcode,
    GetEPCByUPRN,
    get,
    GetALLEPCByUPRN,
    GetRefiner,
    PostRefiner,
    GetTaxCode,
    GetAddressAditional,
    GetListed
  };
  
  export default PropertyDbService;