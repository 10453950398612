import {useMsal} from '@azure/msal-react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Search from './karmaSearch'
import ImportExport from './karmaImportExport'
import logo from '../img/karma.png';

function FillExample() {
  return (
    <div className="row mb-2 mt-5">
        <div className="col-sm-8 offset-2">
        <center style={{ marginBottom: "20px" }}><img src={logo} alt=""></img></center>
                   
                 
                         
    <Tabs
      defaultActiveKey="search"
      id="fill-tab-example"
      className="mb-3"
      justify
      
    >
      <Tab eventKey="search" title="Search">
        <Search/>
      </Tab>
      <Tab eventKey="importexport" title="Import/Export">
        <ImportExport/>
      </Tab>
      {/* <Tab eventKey="longer-tab" title="Loooonger Tab">
        Tab content for Loooonger Tab
      </Tab>
      <Tab eventKey="contact" title="Contact" disabled>
        Tab content for Contact
      </Tab> */}
    </Tabs>
    
    </div>
    </div>
  );
}

export default FillExample;
