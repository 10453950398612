import React from "react"; 
import { MsalProvider, AuthenticatedTemplate, useMsal, UnauthenticatedTemplate } from '@azure/msal-react'; 
//import ProgressBar from 'react-bootstrap/ProgressBar';
import logo from '../img/logo.png';


const Landing = () => {
  
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
/* this worker is shared between drag-drop and file input element */
        
  /* when the worker sends back the HTML, add it to the DOM */
    console.log(activeAccount);
    
    return (
        <>
          
          <div className="container">
          <center>
          <img src={logo} 
          style={{maxHeight:"170px", flex: "1", maxWidth: "100%", height: "auto", marginLeft:'auto', marginRight:'auto' }} alt=""></img> 
          </center>
          </div>
                    
         
        </>

    );
};

export default Landing;
