import types from "../http_common";
import { apiRequest } from "../authConfig";

  // const contactsGetAll = uprn => {
  //   return types.json().get(`/Karma/Contacts/GetAll`);
  // };
  async function bearerToken(instance) {
    const account = instance.getActiveAccount()
    if (!account) {
      throw Error(
        'No active account! Verify a user has been signed in and setActiveAccount has been called.'
      )
    }
    const response = await instance.acquireTokenSilent({
      ...apiRequest,
      account: account,
    })
   // console.log(response);
    return response.accessToken
  }

const getListData = (id,im) => {
   // return http.get(`/property/conservation/UPRN?uprn=${uprn}`);
    return types.json().get('sharepoint?id='+id+'&type='+im,{ timeout: 30000 });
  };
  
  const get = () => {
    return types.json().get(`/`);
  };

  

  const attachementUploadSync = async (formdata,instance,setUploadStarted,setProgress,prog) =>{
    var api = types.json();
    const item = await bearerToken(instance);
    api.defaults.headers.common.Authorization = 'Bearer ' + item;
    api.defaults.headers["Content-Type"] = "multipart/form-data"
    return api.post('/sharepoint/contractorUpload',
      formdata,{
      onUploadProgress: (progressEvent) => {
        setUploadStarted(true);
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgress(percentCompleted);
        prog = percentCompleted;
      },
    });
    
  }

  const SharepointCall = {
    getListData,
    get,
    attachementUploadSync
  };
  
  export default SharepointCall;