// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".drop-zone_wrapper__mCHRR {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n    width: 100%;\r\n  }\r\n  \r\n  .drop-zone_banner_text__YbtcB {\r\n    font-size: 1.5rem;\r\n    color: #ccc;\r\n    display: block;\r\n    margin: 0.5rem 0;\r\n  }\r\n  \r\n  .drop-zone_banner__2LOed {\r\n    background-color: #fafafa;\r\n    width: 100%;\r\n    border: 4px dashed #ccc;\r\n    height: 150px;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n    margin-bottom: 1rem;\r\n    cursor: pointer;\r\n  }\r\n  \r\n  .drop-zone_input__y6OtI {\r\n    width: 100%;\r\n    height: 100%;\r\n    display: none;\r\n  }\r\n  \r\n  ", "",{"version":3,"sources":["webpack://./src/components/fileUpload/drop-zone.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;EACb;;EAEA;IACE,iBAAiB;IACjB,WAAW;IACX,cAAc;IACd,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;IACzB,WAAW;IACX,uBAAuB;IACvB,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;EACjB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,aAAa;EACf","sourcesContent":[".wrapper {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n    width: 100%;\r\n  }\r\n  \r\n  .banner_text {\r\n    font-size: 1.5rem;\r\n    color: #ccc;\r\n    display: block;\r\n    margin: 0.5rem 0;\r\n  }\r\n  \r\n  .banner {\r\n    background-color: #fafafa;\r\n    width: 100%;\r\n    border: 4px dashed #ccc;\r\n    height: 150px;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n    margin-bottom: 1rem;\r\n    cursor: pointer;\r\n  }\r\n  \r\n  .input {\r\n    width: 100%;\r\n    height: 100%;\r\n    display: none;\r\n  }\r\n  \r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "drop-zone_wrapper__mCHRR",
	"banner_text": "drop-zone_banner_text__YbtcB",
	"banner": "drop-zone_banner__2LOed",
	"input": "drop-zone_input__y6OtI"
};
export default ___CSS_LOADER_EXPORT___;
